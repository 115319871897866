import {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";


import adminApi from "../../api/admin";
import FaqForm from "./FaqForm";


const FaqEdit = () => {
    const {id, lang} = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        adminApi.get('faq/get/'+id+'/'+lang).then(r => {
            const f = r.data[0];

            setData({
                id: id,
                lang: f.lang,
                question: f.question,
                category: f.category,
                keywords: f.key,
                answer: f.answer,
                visibleMale: (f.visible === "1" || f.visible === "3" || f.visible === "5" || f.visible === "0"),
                visibleFemale: (f.visible === "2" || f.visible === "3" || f.visible === "6" || f.visible === "0"),
                visibleIntro: (f.visible === "0" || f.visible === "4" || f.visible === "5" || f.visible === "6")
            });
        });
    }, []);

    const onEdit = (e) => {
        e.preventDefault();

        const postData = {
            intro: e.target.visibleIntro.checked ? 4 : 0,
            male: e.target.visibleMale.checked ? 1 : 0,
            female: e.target.visibleFemale.checked ? 2 : 0,
            question: e.target.question.value,
            answer: e.target.answer.value,
            category: e.target.category.value,
            lang: e.target.language.value,
            keywords: e.target.keywords.value
        };

        adminApi.post('faq/edit/'+id+'/'+lang, postData).then(() => {alert('Edited!');});
    }

    const onDelete = (e) => {
        e.preventDefault();

        if (window.confirm('Are you sure want to delete it?')) {
            adminApi.get('faq/delete/'+id).then(() => {
                alert('Deleted!');
                window.history.back();
            });
        }
    }

    return (
        <Fragment>
            <h1>Edit FAQ</h1>
            {data && <FaqForm
                onSubmit={onEdit}
                onDelete={onDelete}
                type={'edit'}
                lang={data.lang}
                question={data.question}
                category={data.category}
                keywords={data.keywords}
                answer={data.answer}
                visibleMale={data.visibleMale}
                visibleFemale={data.visibleMale}
                visibleIntro={data.visibleIntro}
            />}
        </Fragment>
    );
}

export default FaqEdit;
