const CAT_PAYMENT = 'Payment';
const CAT_PROFILE = 'Profile';
const CAT_SAFETY = 'Safety';

const FaqForm = ({
                     onSubmit, onDelete, type, lang = null,
                     question = '', category = '',keywords= '', answer = '',
                     visibleMale = false, visibleFemale = false, visibleIntro = false
}) => {
    return (
        <form onSubmit={onSubmit} className="faq-form">
            <div className="faq-form-group">
                <label htmlFor="language">Language</label>
                <input name="language" type="radio" value="en" defaultChecked={lang === 'en'}/> English
                <input name="language" type="radio" value="es" defaultChecked={lang === 'es'}/> Spanish
            </div>
            <div className="faq-form-group">
                <label htmlFor="question">Please write a question</label>
                <input name="question" defaultValue={question} className="faq-form-group-input" />
            </div>
            <div className="faq-form-group">
                <label htmlFor="category">Please select the category</label>
                <select name="category" className="faq-form-group-input" defaultValue={category}>
                    <option value={CAT_PAYMENT}>{CAT_PAYMENT}</option>
                    <option value={CAT_PROFILE}>{CAT_PROFILE}</option>
                    <option value={CAT_SAFETY}>{CAT_SAFETY}</option>
                </select>
            </div>
            <div className="faq-form-group">
                <label htmlFor="keywords">Please write keywords for this question (NOTE: Separate words with comma or semicolon)</label>
                <input name="keywords" defaultValue={keywords} className="faq-form-group-input" />
            </div>
            <div className="faq-form-group">
                <label htmlFor="answer">Please write an answer</label>
                <textarea name="answer" defaultValue={answer} cols="40" rows="8" className="faq-form-group-input" />
            </div>
            <div className="faq-form-group">
                <label htmlFor="visible">Show question to</label>
                Male <input type="checkbox" name="visibleMale" defaultChecked={visibleMale} value="1" />
                Female <input type="checkbox" name="visibleFemale" defaultChecked={visibleFemale} value="2" />
                Intro <input type="checkbox" name="visibleIntro" defaultChecked={visibleIntro} value="4" />
            </div>
            <button type={"submit"} className="green-button mt-10">{type === 'edit' ? 'Update' : 'Add FAQ'}</button>
            {type === 'edit' && <button className="green-button ml-5" onClick={onDelete}>Delete</button>}
        </form>
    );
}

export default FaqForm;
