import { useState } from 'react';
import Cropper from 'react-easy-crop';

import Classes from './Crop.module.scss'
import Modal from "../Modal/Modal";
import Button from "../Button/Button";

const Crop = ({photo, isOpened, onCropSave, onClose, isSquare}) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [cropped, setCropped] = useState({x: 0,y: 0,width: 0,height: 0, imageWidth:0});
    const [zoom, setZoom] = useState(1);

    if (!isOpened) {
        return null;
    }

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCropped({x: croppedAreaPixels.x,
            y: croppedAreaPixels.y,
            width: croppedAreaPixels.width,
            height: croppedAreaPixels.height,
            imageWidth: cropped.imageWidth}
        )
    };

    const onMediaLoaded = (mediaSize) => {
        setCropped({imageWidth: mediaSize.naturalWidth,})
    }

    const cropSaveHandler = () => {
        onCropSave(cropped);
        onClose();
    }

    return (
        <Modal isOpened={true} onClose={onClose}>
            <div className={Classes.Wrapper}>
                <div className={Classes.Modal}>
                    <div className={Classes.Container}>
                        <Cropper
                            image={photo}
                            crop={crop}
                            zoom={zoom}
                            showGrid={false}
                            aspect={isSquare ? (1 / 1) : (3 / 4)}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onMediaLoaded={onMediaLoaded}
                            onZoomChange={setZoom}
                        />
                    </div>

                    <div className={Classes.ButtonsContainer}>
                        <div className={Classes.ZoomRangeContainer}>
                        <input
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => setZoom(e.target.value)}
                            className={Classes.ZoomRange}
                        />
                        </div>
                        <Button onClick={cropSaveHandler}>Crop</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default Crop;
