import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

import Classes from "./Avatar.module.scss";
import OnlineIndicator from "../OnlineIndicator/OnlineIndicator";
import aggSquarePhoto from "../../helpers/photos";
import {useState} from "react";
import { aggProfileUrl } from '../../helpers/profile';

const Avatar = ({user}) => {
    return (
        <div className={Classes.Container}>
            <Photo user={user}/>
            <div className={Classes.ContainerInfo}>
                <div className={Classes.Name}>
                    <a href={aggProfileUrl(user.id)}>{user.name}</a> {user.isOnline &&
                    <div className={Classes.OnlineContainer}><OnlineIndicator isOnline/></div>}
                </div>

                <div className={Classes.Statuses}>
                    {user.isBlocked && <div className={Classes.BlockedContainer}>B</div>}
                    {user.isDeleted && <div className={Classes.DeletedContainer}>Deleted</div>}
                </div>
            </div>
        </div>
    );
}

const Photo = ({user}) => {
    const [isLoaded, setLoaded] = useState(false);
    const [showOnHover, setShowOnHover] = useState(false);

    const onHover = () => {
        if (!isLoaded) {
            return false;
        }

        setShowOnHover(true);
    }

    const classList = [Classes.ImageContainer];
    if (user.isBlocked) {
        classList.push(Classes.Blocked);
    }

    return (
        <div className={classList.join(' ')} onMouseEnter={onHover}>
            {showOnHover && (
                <img
                    className={Classes.ImageBig}
                    src={aggSquarePhoto(user.photo, 200, user)}
                    onMouseLeave={() => setShowOnHover(false)}
                    alt={'User big thumbnail'}
                />
            )}

            <div className={Classes.ImageBackground}>
                <LazyLoadImage
                    alt={'User thumbnail'}
                    className={Classes.Image}
                    src={aggSquarePhoto(user.photo, 100, user)}
                    effect={'opacity'}
                    afterLoad={() => setLoaded(true)}
                    //height={image.height}
                    //width={image.width} />
                />
            </div>
        </div>
    )
}

export default Avatar;
