import { useEffect, useState } from "react";

import apiAdmin from "../../../api/admin";
import { aggPhoto } from "../../../helpers";
import {aggProfileUrl} from "../../../helpers/profile";

const VerifiedNewestProfiles = () => {
    const [data, setData] = useState(null);

    const getVerifiedProfiles = () => {
        apiAdmin.get('verification/get_latest_verified').then((r) => {
            setData(r.data);
        })
    }

    useEffect(() => {
        getVerifiedProfiles();
    }, []);


    return (
        <>
        {data &&
        data.map((user, i) => {
            return (
                <div className="latest-verified" key={i}>
                    <a href={aggProfileUrl(user.id)}>
                        <img src={aggPhoto(user.photo, 'thumb')} alt="user"/>
                    </a>
                </div>
            )
        })
        }
        </>
    );
}

export default VerifiedNewestProfiles
