import {useEffect, useState} from "react";
import FsLightbox from 'fslightbox-react';

import adminApi from "../../api/admin";
import {aggPhoto} from "../../helpers";
import {aggOriginalPhoto} from "../../helpers/photos";

const PhotosDeleted = ({id}) => {
    const [lightboxController, setLightboxController] = useState({toggler: false, slide: 1});
    const [photos, setPhotos] = useState([]);
    const [hasMore, setMore] = useState(false);

    const openModal = (id) => setLightboxController({toggler: !lightboxController.toggler, slide: id+1});

    useEffect(() => {
        adminApi.get(`users/get_deleted_photos/${id}`)
            .then((r) => {
                setMore(r.data.hasMore);
                setPhotos([...photos, ...r.data.list]);
            });
    }, []);

    const onLoadMore = () => {
        adminApi.get(`users/get_deleted_photos/${id}?offset=${photos.length}`)
            .then((r) => {
                setMore(r.data.hasMore);
                setPhotos([...photos, ...r.data.list]);
            });
    }

    if (photos.length === 0) {
        return null;
    }

    return (
        <div style={{
            display: "flex",
            flexDirection: 'column',
            width: 'calc(100% + 20px)',
            margin: '0 0 0 -10px',
            backgroundColor: "rgba(241, 177, 177, 0.5)",
            borderRadius: '5px',
            padding: '10px',
            boxSizing: "border-box"
        }}>
            <h3>Deleted photos</h3>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {photos.map((p, i) => <Item key={i} photo={p} openPhoto={() => openModal(i)}/>)}
            </div>
            {hasMore && (
                <div style={{width: '100%', textAlign: 'center'}}>
                    <button className="grey-button" onClick={onLoadMore}>Load more</button>
                </div>
            )}

            <FsLightbox
                toggler={lightboxController.toggler}
                slide={lightboxController.slide}
                sources={photos.map(p => aggPhoto(p.url))}
                type="image"
            />
        </div>
    )
}

const Item = ({photo, openPhoto}) => {
    const style = {
        width: '82px',
        height: '108px',
        border: '1px solid grey',
        borderRadius: '4px',
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
        opacity: '0.5'
    }

    return (
        <div style={{margin: '0 7px 7px 0', position: 'relative'}}>
            {photo.photo.url && (
                <a
                    href={aggOriginalPhoto(photo.photo, null)}
                    target="_blank"
                    rel="noreferrer"
                    style={{position: "absolute", fontSize: '14px', padding: '5px', right: '0', top: '-5px'}}>
                    <i className="fa fa-eye" />
                </a>
            )}

            {photo.isPrivate && (
                <i
                    className="fa fa-lock"
                    style={{position: "absolute", fontSize: '10px', padding: '5px', right: '0', bottom: '0'}}
                />
            )}
            <img src={aggPhoto(photo.url, 'thumb')} onClick={openPhoto} style={style}/>
        </div>
    );
}

export default PhotosDeleted;
