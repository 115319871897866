import {useState} from "react";


import AddUnwanted from "./AddUnwanted";
import UnwantedSearch from "./UnwantedSearch";


const UnwantedUsers = ({userId}) => {
    const [initUpdate, setUpdate] = useState(null);

    console.log(userId);

    return (
        <div className="unwanted-users">
            <h1>Unwanted Users</h1>
            <AddUnwanted hardcodedId={userId} onAdd={() => setUpdate( Math.floor(Math.random() * 10) )} />
            <UnwantedSearch hardcodedId={userId} initUpdate={initUpdate} />
        </div>
    );
}

export default UnwantedUsers;
