import { useState } from "react";
import apiAdmin from "../../../../api/admin";

const VerificationItemActions = ({item: v, onReload, onVerify, onRemove, selfieSelected}) => {
    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState('');
    const [gesturesDecline, setGesturesDecline] = useState(false);

    const saveComment = () => {
        apiAdmin.post('verification/save_comment/' + v.user.id, {comment: comment}).then(() => {
            onReload();
        })
    }
    const verifyHandler = () => {
        if (selfieSelected) {
            onVerify(v.selfiePhoto, v.user.id, v.selfiePhotoId, true);
        } else {
            onVerify(v.photo, v.user.id, v.id);
        }
    }
    const declineHandler = () => {
        if (window.confirm("Are you sure, that you want to unverify?")) {
            apiAdmin.post('verification/remove_user_verification/' + v.user.id, {gesturesDecline: gesturesDecline}).then(() => {
                onRemove();
            })
        }
    }
    return (
        <>
            <td>
                <div>
                    <button onClick={verifyHandler} className="pending-verification-check">
                        <i className="fa fa-check" />
                    </button>
                </div>
                <button onClick={declineHandler} className="pending-verification-decline">
                    <i className="fa fa-times" />
                </button>
            </td>
            <td className="pending-verification-reason">
                <span className="green-text block-reason">{v.reason}</span><br/>
                {(v.user.block !== null) ?
                    <span>
                        <a href={`/member/${v.user.block.adminId}`} className="table-links">
                            {v.user.block.adminName}
                        </a> - {v.user.block.comment}
                    </span>
                : ''}
                <br/>
                <br/>
                {v.comments[0] &&
                    <div>
                        <a href={`/member/${v.comments[0].adminID}`} className="table-links">
                            {v.comments[0].name}
                        </a> - {v.comments[0].comment}
                    </div>
                }

                {showComment &&
                    <div>
                        <input
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                        />
                        <button onClick={saveComment}>Save</button>
                    </div>
                }
                <span className="table-links" onClick={() => {setShowComment(!showComment)}}>Add Comment</span>
                <label
                    style={{display: 'flex', height: '20px', alignItems: 'center', marginTop: '10px'}}
                    title={"On decline - send decline message about gestures"}>
                    <input
                        type="checkbox"
                        style={{margin: '0 5px 0 0'}}
                        checked={gesturesDecline}
                        onChange={() => setGesturesDecline(!gesturesDecline)}
                    /> Gestures decline
                </label>
            </td>
        </>
    );
}

export default VerificationItemActions;
