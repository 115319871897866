export const timeAgo = (timeStamp) => {
    const diffSeconds = Math.round((Date.now()-(timeStamp * 1000)) / 1000);

    if (diffSeconds >= 0 && diffSeconds < 60) {
        return 'Now';
    }
    else if (diffSeconds >= 60 && diffSeconds < 1800) {
        return 'less than 30 min ago';
    }
    else if (diffSeconds >= 1800 && diffSeconds < 3600) {
        return 'less than hour ago';
    }
    else if (diffSeconds >= 3600 && diffSeconds < 86400) {
        let hours = Math.round(diffSeconds/3600);
        return (hours > 1) ? hours + ' hours ago' : 'an hour ago';
    }    
    else if (diffSeconds >= 86400 && diffSeconds < 604800) {
        let days = Math.round(diffSeconds/86400);
        return (days > 1) ? days + ' days ago' : '1 day ago';
    }
    else if (diffSeconds >= 604800) {
        let weeks = Math.round(diffSeconds/604800);
        if (weeks > 2) return 'more than 2 weeks ago';
        return (weeks > 1) ? weeks + ' weeks ago' : '1 week ago';
    }
    
    return 'Now';
}

export const aggTripDate = (dateStr) => {
    let monthList = ['Jan','Feb', 'Mar','Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep','Oct','Nov','Dec'];
    let dt = dateStr.split('-');

    return monthList[parseInt(dt[1])-1] + ' ' + dt[2];
}

export const aggDateFromTimeStamp = (timeStamp) => {
    // initialize new Date object
    var date_ob = new Date(timeStamp);

    // year as 4 digits (YYYY)
    var year = date_ob.getFullYear();

    // month as 2 digits (MM)
    var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);

    // date as 2 digits (DD)
    var date = ("0" + date_ob.getDate()).slice(-2);

    return (year + "-" + month + "-" + date);
}
