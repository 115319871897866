import {useState, useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import classes from './MassMessage.module.scss';

import adminApi from "../../../api/admin";
import useQuery from "../../../helpers/useQuery";
import MassMessageConfirm from "./MassMessageConfirm";
import { languageList } from "../../../helpers/lang";


const MassMessageCreate = () => {
    const formData = useRef();
    const {countryList} = useSelector(s => s.auth.config);
    const [showAllInfo, setShowAllInfo] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [showConfirmWindow, setShowConfirmWindow] = useState(false);
    const [formInfo, setFormInfo] = useState([]);
    const hiddenClass = (showAllInfo) ? '' : classes.Hidden;
    const groupMessageId = useQuery().get('id');
    const [groupMessageData, setGroupMessageData] = useState(null); 
    const currentTime = new Date().toLocaleString('lt-LT');
    const [autoanswers, setAutoanswers] = useState([]);
    const [showAutoanswers, setShowAutoanswers] = useState(false);
    const ageList = [];
    for (let i = 18; i <= 99; i++) {
        ageList.push({label: i, value: i});
    }

    const languageOptions = [{label: 'Any', value: '-'}];
    Object.entries(languageList).forEach(([key, value]) => {
        languageOptions.push({label: value, value: parseInt(key)});
    });

    const showAutoanswerHandler = (event) => {
        if(event.target.value === 'Yes') {
            setShowAutoanswers(true);
        } else {
            setShowAutoanswers(false);
        }
    }

    const addAutoanswerHandler = () => {
        setAutoanswers(prevState => [...prevState, autoanswers.length+1]);
        const newItem = (
            <div className={`${classes.Line}`} key={autoanswers.length}>
            <div className={classes.Label}>Auto Answer:<br /><span className="table-links" onClick={() => deleteAutoanswerHandler(autoanswers.length)}>Delete</span></div>
            <div className={classes.Inputs}>
                <textarea name="auto_answer_content[]" className="auto_answer_content"></textarea>
                <div>
                    <select name="after_response[]">
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                    </select>
                    <span>Send after response</span>
                    <br/><br/>
                    <input type="text" name="hours[]" defaultValue="0" className="auto-answer-interval" /> H.
                    <input type="text" name="minutes[]" defaultValue="0" className="auto-answer-interval" /> min.
                    <input type="text" name="seconds[]" defaultValue="10" className="auto-answer-interval" /> sec.
                </div>
            </div>
            </div>
          );
          setAutoanswers([...autoanswers, newItem]);
    };

    const deleteAutoanswerHandler = (index) => {
        setAutoanswers(oldValues => {
            return oldValues.filter((val, i) => i !== index)
        })
    }

    const getList = () => {
        if (groupMessageId) {
            adminApi.post('group_messages/get_group_message_data/'+groupMessageId).then(r => {
                setGroupMessageData(r.data.data);
                setDataLoaded(true);
            });
        } else {
            setDataLoaded(true);
        }
    }
    useEffect( getList, [groupMessageId]);

    return (
        
        
        <>
        {!showConfirmWindow &&
        <div>
            <h2>Mass message create</h2>
            
            <div className={classes.SenderInfo}>
                Sender: <b>{groupMessageData && groupMessageData.name}</b> {groupMessageData ? '(Online)' : '(Offline)'}<br />
                Age: <b>{groupMessageData && groupMessageData.ageFrom}</b> to <b>{groupMessageData && groupMessageData.ageTo}</b> <br />
                Countries: <b>{groupMessageData && groupMessageData.countries.length}</b> of <b>250</b><br />
            </div>

            <form className={classes.SenderAllInfo} ref={formData} onSubmit={submitHandler}>
                {!showAllInfo && <div className="table-links" onClick={() => setShowAllInfo(true)}>Show group message info.</div>}
                
                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Member ID:</div>
                    <div className={classes.Inputs}><input type="text" name="mass_memberID" defaultValue={groupMessageData && groupMessageData.userId} /></div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Mass start date:</div>
                    <div className={classes.Inputs}><input type="text" name="mass_startDate" defaultValue={currentTime} /></div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Sex:</div>
                    <div className={classes.Inputs}>
                        <label htmlFor="sex-female">
                            <input type="checkbox" id="sex-female" name="sex_female" defaultChecked={false} />
                            Female
                        </label>
                        <label htmlFor="sex-male">
                            <input type="checkbox" id="sex-male" name="sex_male" defaultChecked={true} />
                            Male
                        </label>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>User status:</div>
                    <div className={classes.Inputs}>
                        <label htmlFor="status-registered">
                            <input type="checkbox" id="status-registered" name="status_registered" defaultChecked={true} />
                            Registered
                        </label>
                        <label htmlFor="status-free-premium">
                            <input type="checkbox" id="status-free-premium" name="status_free_premium" defaultChecked={false} />
                            Free Premium
                        </label>
                        <label htmlFor="status-paid-premium">
                            <input type="checkbox" id="status-paid-premium" name="status_paid_premium" defaultChecked={true} />
                            Paid Premium
                        </label>
                        <label htmlFor="status-purchase">
                            <input type="checkbox" id="status-purchase" name="status_purchase" defaultChecked={false} />
                            3 months no purchase
                        </label>
                        <label htmlFor="status-verified">
                            <input type="checkbox" id="status-verified" name="status_verified" defaultChecked={false} />
                            Verified
                        </label>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Priority:</div>
                    <div className={classes.Inputs}>
                        <select name="priority">
                            <option value="-">All</option>
                            <option value="low">Low priority</option>
                            <option value="normal">Normal</option>
                        </select>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Spoken language:</div>
                    <div className={classes.Inputs}>
                        <select name="language">
                            {languageOptions.map((item, i) => {
                                return (<option key={i} value={item.value}>{item.label}</option>);
                            })}
                        </select>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Photo status:</div>
                    <div className={classes.Inputs}>
                        <select name="photo_info">
                            <option value="All">All</option>
                            <option value="1">With photos</option>
                            <option value="0">Without photos</option>
                        </select>
                    </div>
                </div>

                {dataLoaded &&
                    <div className={`${hiddenClass} ${classes.Line}`}>
                        <div className={classes.Label}>Age between:</div>
                        <div className={classes.Inputs}>
                            <select name="age_from" defaultValue={groupMessageData ? groupMessageData.ageFrom : 18}>
                                <option value="-">From</option>
                                {ageList.map((item, i) => {
                                    return (<option key={i} value={item.value}>{item.label}</option>);
                                })}
                            </select>
                            <select name="age_to" defaultValue={groupMessageData ? groupMessageData.ageTo : 99}>
                                <option value="-">To</option>
                                {ageList.map((item, i) => {
                                    return (<option key={i} value={item.value}>{item.label}</option>);
                                })}
                            </select>
                        </div>
                    </div>
                }

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Premium days:</div>
                    <div className={classes.Inputs}>
                        <select name="premiumDayInt">
                            <option value="0">None</option>
                            <option value="1">1 day to end</option>
                            <option value="2">2 days to end</option>
                            <option value="3">3 days to end</option>
                            <option value="4">4 days to end</option>
                            <option value="5">5 days to end</option>
                            <option value="6">6 days to end</option>
                            <option value="7">7 days to end</option>
                            <option value="8">8 days to end</option>
                            <option value="9">9 days to end</option>
                            <option value="10">10 days to end</option>
                        </select>
                        <span>(for premium members only - ending premium do not send messages)</span>
                    </div>
                </div>

                {dataLoaded &&
                    <div className={`${hiddenClass} ${classes.Line}`}>
                        <div className={classes.Label}>Countries:</div>
                        
                        <div className={classes.Inputs}>
                            <select name="countries[]" multiple size="11" id="countryList" defaultValue={groupMessageData && groupMessageData.countries}>
                                {
                                    Object.entries(countryList).map(([code, countryName]) => (
                                        <option key={code} value={code}>{countryName}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                }

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Exclude members:</div>
                    <div className={classes.Inputs}>
                        <label htmlFor="exclude-ip">
                            <input type="checkbox" id="exclude-ip" name="exclude_blacklist" defaultChecked={false} />
                            From blacklist by IP
                        </label><br />
                        <label htmlFor="exclude-cookies">
                            <input type="checkbox" id="exclude-cookies" name="exclude_blacklist_cookies" defaultChecked={true} />
                            From blacklist by COOKIES
                        </label><br />
                        <label htmlFor="exclude-active">
                            <input type="checkbox" name="exclude_none_active_user" id="exclude-active" defaultChecked={true} />
                            None active (login is older then last greeter message) users
                        </label><br />
                    </div>
                </div>

                <div className={`${classes.Line}`}>
                    <div className={classes.Label}>Content:</div>
                    <div className={classes.Inputs}>
                        <textarea name="message" id="message" className={classes.ContentInput} defaultValue={groupMessageData && groupMessageData.content} />​
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Order by:</div>
                    <div className={classes.Inputs}>
                        <select name="order_by">
                            <option value="DESC">For online now first</option>
                            <option value="ASC">For offline now first</option>
                        </select>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Go offline:</div>
                    <div className={classes.Inputs}>
                        <select name="go_offline_select">
                            <option value="1">Go offline</option>
                            <option value="0">Do not go offline after</option>
                        </select>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Go offline after:</div>
                    <div className={classes.Inputs}>
                        <select name="timeInterval">
                            <option value="1">1 minute</option>
                            <option value="2">2 minutes</option>
                            <option value="3">3 minutes</option>
                            <option value="5">4 minutes</option>
                            <option value="5">5 minutes</option>
                        </select> 
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Auto Accept Pr photos:</div>
                    <div className={classes.Inputs}>
                        <label htmlFor="autoAcceptPrivatePhotos">
                            <input type="checkbox" id="autoAcceptPrivatePhotos" name="autoAcceptPrivatePhotos" defaultChecked={false} />    
                            Auto accept private photo requests
                        </label>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Auto Answer:</div>
                    <div className={classes.Inputs}>
                        <select name="auto_answer_select" onChange={showAutoanswerHandler}>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                        </select>
                    </div>
                </div>

                {showAutoanswers &&
                <>
                    <div className={`${hiddenClass} ${classes.Line}`}>
                        <div className={classes.Label}>Auto Answer:</div>
                        <div className={classes.Inputs}>
                            <textarea name="auto_answer_content[]" className="auto_answer_content"></textarea>
                            <div>
                                <select name="after_response[]">
                                    <option value="1">Yes</option>
                                </select>
                                <span>Send after response</span>
                                <br/><br/>
                                <input type="text" name="hours[]" defaultValue="0" className="auto-answer-interval" /> H.
                                <input type="text" name="minutes[]" defaultValue="0" className="auto-answer-interval" /> min.
                                <input type="text" name="seconds[]" defaultValue="10" className="auto-answer-interval" /> sec.
                            </div>
                        </div>
                    </div>

                    {autoanswers.map((item, index) => (
                        <div key={index}>
                            {item}
                        </div>
                    ))}

                    <div className={`${hiddenClass} ${classes.Line}`}>
                        <span className="load-more-auto-answer table-links" onClick={addAutoanswerHandler}>Add more auto answers</span>
                    </div>
                </>
                }

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>ENDS PREMIUM:</div>
                    <div className={classes.Inputs}>
                        <label htmlFor="premiumENDS">
                            <input type="checkbox" id="premiumENDS" name="premiumENDS" defaultChecked={false} />    
                            Was premium last week (only for registered)
                        </label>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Was PREMIUM:</div>
                    <div className={classes.Inputs}>
                        <label htmlFor="wasPREMIUM">
                            <input type="checkbox" id="wasPREMIUM" name="wasPREMIUM" defaultChecked={false} />    
                            Was premium member
                        </label>
                    </div>
                </div>

                <div className={`${hiddenClass} ${classes.Line}`}>
                    <div className={classes.Label}>Is recounting:</div>
                    <div className={classes.Inputs}>
                        <select name="is_recounting">
                            <option value="1">Is recounting</option>
                            <option value="0">No</option>
                        </select>
                        <input type="text" name="recount_date" defaultValue="2011-07-28 00:00:00" />
                    </div>
                </div>

                <div className={`${classes.Line} ${classes.LineCentered}`}>
                    <div className={classes.Label}>
                        <button type="submit" className="green-button-simple">Create</button>
                    </div>
                    <div className={classes.InputsWide}>
                        <div className="table-links" onClick={countAmountHandler}>Check amount of users</div> 
                    </div>
                    <div className={classes.Inputs}>
                        <label htmlFor="insteadMarishka">
                            <input type="checkbox" id="insteadMarishka" name="insteadMarishka" defaultChecked={false} />    
                            Instead Marishka
                        </label>
                    </div>
                </div>
                <input type="hidden" id="wasContact" value="1" name="wasContact" />

            </form>
            
        </div>
        }
        {showConfirmWindow && 
            <MassMessageConfirm data={formInfo} name={groupMessageData && groupMessageData.name} groupMsgId={groupMessageData && groupMessageId} />
        }
        </>
    );

    function submitHandler(event) {

        event.preventDefault();
        const formInformation = new FormData(event.target);
        for (let obj of formInformation) {
            setFormInfo(prevState => {
                return [ ...prevState, {name: obj[0], value: obj[1]} ]
            });
        }        
        setShowConfirmWindow(true);
    }

    function countAmountHandler() {

    }
}

export default MassMessageCreate
