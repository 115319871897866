import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import Classes from "./OnlineSender.module.scss";
import adminApi from "../../../api/admin";
import Avatar from "../../../common/Avatar/Avatar";
import Button from "../../../common/Button/Button";

const aggHours = hours => ("0" + hours).slice(-2);
const hourList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
const intervalList = Array.from(Array(10).keys());

const OnlineSender = () => {
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [addFormVisible, setAddFormVisible] = useState(false);
    const {countryList} = useSelector(s => s.auth.config);
    const getList = () => {
        adminApi.get('greetings/online_sender').then(r => {
            setLoading(false);
            setList(r.data.list);
        });
    }
    useEffect( getList, []);

    return (
        <div>
            <div className={Classes.Header}>
                <h2>Online sender</h2>
                <button onClick={() => setAddFormVisible(!addFormVisible)}>Add online sender</button>
            </div>
            {isLoading && <div className="loader mt-50"/>}
            {addFormVisible && <AddForm countryList={countryList} />}
            {list.length > 0 && <List list={list} />}
        </div>
    )
}

const List = ({list}) => (
    <table className={Classes.Table}>
        <thead>
        <tr>
            <th>user</th>
            <th style={{width: '50px'}}>Time</th>
            <th style={{width: '200px'}}>Content</th>
            <th style={{width: '200px'}}></th>
            <th>TI</th>
            <th></th>
        </tr>
        </thead>
        <tbody>{list.map((l, i) => <Item item={l} key={i} />)}</tbody>
    </table>
);

const Item = ({item}) => (
    <tr>
        <td><Avatar user={item.user} /></td>
        <td title={"Time then user is active"}>
            <b>{aggHours(item.hourFrom)}</b> - <b>{aggHours(item.hourTo)}</b>
        </td>
        <td>{item.content}</td>
        <td>
            Autoanswer: <b>{item.autoAnswer.enabled ? 'ON' : 'OFF'}</b>
            {item.autoAnswer.enabled && <p>{item.autoAnswer.content}</p>}
        </td>
        <td title={"Time Interval"}><b>{item.timeInterval}</b></td>
        <td title={"Delete it"}>
            <button onClick={() => alert('Does not work! Contant developers!')}>
                <i className="fa fa-trash" aria-hidden="true" />
            </button>
        </td>
    </tr>
);

const AddForm = ({countryList}) => (
    <form onSubmit={() => alert('Does not work')} className={Classes.AddForm}>
        <table>
            <tbody>
            <tr>
                <td>User Id</td>
                <td><input type={"text"} /></td>
            </tr>

            <tr>
                <td>Time</td>
                <td>
                    <select>
                        <option>From</option>
                        {hourList.map(k => <option key={k} value={k}>{aggHours(k)}</option>)}
                    </select>
                    &nbsp;&nbsp;
                    <select>
                    <option>To</option>
                    {hourList.map(k=> <option key={k}  value={k}>{aggHours(k)}</option>)}
                </select>
                </td>
            </tr>

            <tr>
                <td>Send for</td>
                <td>
                    <select>
                        <option value="reg_only">Only for registered</option>
                        <option value="premium_only">Only for premium</option>
                        <option value="all_level">For all</option>
                    </select>
                </td>
            </tr>

            <tr>
                <td>Message</td>
                <td><textarea name="message" placeholder="Message" /></td>
            </tr>

            <tr>
                <td>Autoanswer</td>
                <td><textarea name="reply_message" placeholder="Auto answer" /></td>
            </tr>
            <tr>
                <td>Photo</td>
                <td>
                    <select name="member_photo">
                        <option value="0">Send messages to all members</option>
                        <option value="1">Send messages for members with photo</option>
                    </select>
                </td>
            </tr>

            <tr>
                <td>Interval</td>
                <td>
                    <select name="member_OnlineInterval">
                        {intervalList.map(k => <option key={k} value={k+1}>{k+1}</option>)}
                    </select>
                </td>
            </tr>

            <tr>
                <td>Go Offline</td>
                <td>
                    <select name="goOFFLINE">
                        <option value="0">Don't go offline</option>
                        {intervalList.map(k => <option key={k} value={k+1}>{k+1}</option>)}
                    </select>
                </td>
            </tr>

            <tr>
                <td>Countries</td>
                <td>
                    <select name="countries" multiple={true} defaultValue={['LT', 'AU', 'AT', 'AA']}>
                        {Object.keys(countryList).map(k => <option key={k} value={k}>{countryList[k]}</option>)}
                    </select>
                </td>
                <td>
                    <input type="checkbox" defaultChecked name="exclude_hidden" /> Exclude hidden<br />
                    <input type="checkbox" defaultChecked name="exclude_fake" /> Exclude fake<br />
                    <input type="checkbox" defaultChecked name="exclude_blacklist" /> <b>Blacklist by IP</b><br />
                    <input type="checkbox" defaultChecked name="exclude_blacklist_cookie" /> <b>Blacklist by cookie</b><br />
                    <input type="checkbox" defaultChecked name="exclude_blocked_users" /> <b>Blocked users</b><br />
                    <input type="checkbox" defaultChecked name="exclude_old_premium" /> <b>Was premium</b>
                </td>
            </tr>
            </tbody>
        </table>
        <p>
            <Button type="submit">Add online member</Button>
        </p>
    </form>
);

export default OnlineSender;
