import {useState} from "react";

import adminApi from "../../../api/admin";

const AddUnwanted = ({hardcodedId, onAdd}) => {
    const [userId, setUserId] = useState(hardcodedId ? hardcodedId : '');
    const [unwantedId, setUnwantedId] = useState('')

    const onSubmit = (e) => {
        e.preventDefault();

        adminApi.post('users/add_to_unwanted_list', {userId: userId, unwantedUserId: unwantedId}).then((r) => {
            onAdd();

            setUnwantedId('');
            //userId.current.value = ''
            //unwantedId.current.value = '';
        });
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="group">
                <label htmlFor="userId">Member ID:</label>
                <input
                    type="text"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    disabled={!!hardcodedId} />
            </div>

            <div className="group">
                <label htmlFor="unwantedId">Unwanted User ID:</label>
                <input type="text" value={unwantedId} onChange={(e) => setUnwantedId(e.target.value)} />
            </div>
            <button type="submit">Add</button>
        </form>
    )
}

export default AddUnwanted;
