import {Fragment, useEffect, useState} from "react";
import {Link} from "react-router-dom";


import adminApi from "../../api/admin";
import {aggDateFromTimestamp, aggregateText} from "../../helpers";
import {aggProfileUrl} from "../../helpers/profile";


const Support = () => {
    const [list, setList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [lastId, setLastId] = useState(null);

    const getList = () => {
        if (isLoading) {
            return;
        }

        setLoading(true);
        adminApi.post('support', {lastId: lastId}).then(r => {
            setLoading(false);
            setLastId(r.data.lastId)
            setList([...list, ...r.data.list]);
        })
    }

    useEffect(getList, []);

    return (
        <Fragment>
            <h1>Administrators support</h1>
            <ResultsTable list={list}/>
            {!isLoading && lastId && list.length > 0 && <button onClick={() => getList()}>Load more</button>}
            {isLoading && <div className="loader mt-20"/>}
        </Fragment>
    );
}

const ResultsTable = ({list}) => {
    if (list.length === 0) {
        return null;
    }

    return (
        <table>
            <thead>
            <tr>
                <th style={{width: '15%'}}>Name</th>
                <th style={{width: '15%'}}>Received</th>
                <th>Subject</th>
            </tr>
            </thead>
            <tbody><List list={list} /></tbody>
        </table>
    );
}

const List = ({list}) => {
    return list.map((t, i) => {
        return(
            <tr key={i} className={t.done === '0' ? 'green-bg' : ''}>
                <td>
                    <User ticket={t} />
                    <span className="tf">{(t.app === 'tf') ? '(TF)' : ''}</span></td>
                <td>{aggDateFromTimestamp(t.createdAt)}</td>
                <td>
                    <Link to={`/support/${t.id}`} className="support-list-link">
                        <b>{t.subject}</b>
                        <div className="support-list-content" dangerouslySetInnerHTML={{__html: aggregateText(t.content)}}/>
                    </Link>
                </td>
            </tr>
        );
    });
}

const User = ({ticket}) => {
    if (ticket.userId && ticket.name) {
        return <a href={aggProfileUrl(ticket.userId)} className="table-links">{ticket.name}</a>
    }

    return ticket.nameFromSupport
}

export default Support;
