import { useEffect, useState } from 'react';

import apiAdmin from "../../api/admin";
import useQuery from '../../helpers/useQuery';
import AboutMeItem from './AboutMeItem';

const AboutMe = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [lastConfirmed, setLastConfirmed] = useState(null);

    const sex = useQuery().get('sex');

    const getList = () => {
        setIsLoading(true);
        apiAdmin.get('updates/about_me/'+(sex ? sex : '')).then((r) => {
            setList(r.data.users);
            setIsLoading(false);
        });
    }

    useEffect(getList, []);

    const removeFromListHandler = (userId) => {
        setLastConfirmed(list.find(l => l.id === userId));

        const newArray = list.filter(l => {
            return l.id !== userId
        });
        setList(newArray);
    }

    const onUndoClick = (e) => {
        e.preventDefault();

        if (lastConfirmed) {
            apiAdmin.post('updates/undo_about_me_update', {userId: lastConfirmed.id});
            setList([lastConfirmed, ...list]);
            setLastConfirmed(null);
        }
    }

    return (
        <div className='about-me'>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <h1>About me updates</h1>
                {lastConfirmed && (
                    <button
                        onClick={onUndoClick}
                        style={{fontSize: '14px', fontWeight: 'bold', background: 'none', border: 0, color: '#1C7D04'}}
                    >Undo</button>
                )}
            </div>

            <div>
                <table>
                    <thead>
                    <tr>
                        <th>User</th>
                        <th>About me</th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {list && list.map((m, i) => <AboutMeItem user={m} onConfirmAboutMe={removeFromListHandler} key={i} />)}
                    </tbody>
                </table>
            </div>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default AboutMe;
