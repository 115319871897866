import {useState} from "react";
import FsLightbox from 'fslightbox-react';
import adminApi from "../../api/admin";
import { aggPhoto } from "../../helpers";
import { aggOriginalPhoto } from "../../helpers/photos";
import Crop from "../../common/Crop/Crop";
import { useEffect } from "react";

const Photos = ({user, onReloadUser, openedMainPhoto, onCloseMainPhoto}) => {
    const [lightboxController, setLightboxController] = useState({toggler: openedMainPhoto, slide: 1});
    const [cropInfo, setCropInfo] = useState({isOpened:false, photoInfo: null, photo:null});
    const mainPhotoId = (user.member.mainPhotoAdminId) ? +user.member.mainPhotoAdminId : +user.member.mainPhotoId;
    const sortedPhotos = [...user.photos].sort((a, b) => a.id === mainPhotoId ? -1 : 1,);
    const [photos, setPhotos] = useState(sortedPhotos);

    const visibleImgList = photos.map(p => aggPhoto(p.url));

    const openModal = (id) => {
        setLightboxController({toggler: !lightboxController.toggler, slide: id+1});
    }

    useEffect(()=> {
        if (openedMainPhoto) {
            openModal(0);
        }
    },[openedMainPhoto]);

    const updatePhotoList = (data) => {
        setPhotos(photos.map(p => {
            if (p.id === data.id) {
              return { ...data };
            } else {
              return p;
            }
        }));
    }

    const rotatePhotoHandler = (photoId, angle) => {
        let postData = {photoId:  photoId, angle: angle}
        adminApi.post('photos/rotate/'+user.member.ID, postData).then((r) => {
            updatePhotoList(r.data);
            if (photoId === mainPhotoId) {
                onReloadUser();
            }
        });
    }

    const removePhotoHandler = (photoId) => {
        let postData = {photoId:  photoId};
        if (window.confirm('Are you sure?\n Do you really want to remove this photo?')) {
            adminApi.post('photos/remove_photo/'+user.member.ID, postData).then((r) => {
                setPhotos(
                    photos.filter(p => p.id !== photoId)
                );
                if (photos.length === 1) {
                    onReloadUser();
                }
            });
        }
    }

    const makeMainPhotoHandler = (photoId) => {
        let postData = {photoId:  photoId}
        adminApi.post('photos/make_front/'+user.member.ID, postData).then((r) => {
           onReloadUser();
        });
    }

    const hideInTf = (photo) => {
        let postData = {photoId: photo.id, status: photo.hideInTf}
        adminApi.post('photos/hide_in_tf/'+user.member.ID, postData).then((r) => {
            updatePhotoList(r.data);
        });
    }

    const removeMainPhotoHandler = (photoId) => {
        let postData = {photoId:  photoId}
        adminApi.post('photos/remove_front/'+user.member.ID, postData).then((r) => {
           onReloadUser();
        });
    }

    const makePrivatePhotoHandler = (photoId) => {
        let postData = {photoId:  photoId}
        adminApi.post('photos/make_private/'+user.member.ID, postData).then((r) => {
            if (photoId === mainPhotoId) {
                onReloadUser();
            } else {
                updatePhotoList(r.data);
            }
        });
    }

    const makePublicPhotoHandler = (photoId) => {
        let postData = {photoId:  photoId}
        adminApi.post('photos/make_public/'+user.member.ID, postData).then((r) => {
            updatePhotoList(r.data);
        });
    }

    const showCrop = (photo) => {
        setCropInfo({
            isOpened: !cropInfo.isOpened,
            photoInfo: photo,
            photo: aggPhoto(photo.url, '', user.member)
        });
    }

    const saveCropHandler = (photoId, coords) => {
        let postData = {
            photoId: photoId,
            imgWidth: coords.imageWidth,
            x: coords.x,
            y: coords.y,
            width: coords.width,
            height: coords.height
        }

        adminApi.post('photos/crop/'+user.member.ID, postData).then((r) => {
            updatePhotoList(r.data);
            if (photoId === mainPhotoId) {
                onReloadUser();
            }
        });
    }

    const closeModalHandler = () => {
        onCloseMainPhoto()
    }

    if (photos.length === 0) {
        return null;
    }

    return (
        <>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={visibleImgList}
                type="image"
                slide={lightboxController.slide}
                onClose={closeModalHandler}
            />

            {cropInfo.isOpened &&
                <Crop
                    photo={cropInfo.photo}
                    isOpened={cropInfo.isOpened}
                    onCropSave={(coords) => saveCropHandler(cropInfo.photoInfo.id, coords)}
                    onClose={() => setCropInfo({isOpened: false, photo: null, photoInfo: null})}
                    isSquare={false}
                />
            }

            {user.member.main_photo_set &&
                <div className="mainPhotoWarning">Member asked to change his main photo! &nbsp; {user.member.main_photo_set}</div>
            }
            <div className="photosList">
                <h3>Photos</h3>

                {photos.map((p, i) => {
                    const newPhotoClass = (user.updates.photos && user.updates.photos.includes(String(p.id))) ? 'newPhoto' : '';
                    const privatePhotoClass = (p.isPrivate) ? 'private_photo' : '';
                    const hideTfClass = (p.hideInTf) ? 'hidden-tf' : '';
                    return (
                        <div className="l photo_view_block" key={i}>
                            <a href={aggOriginalPhoto(p.photo, user.member)} className="orig_preview" target="_blank" rel="noreferrer"><i className="fa fa-eye"></i></a>
                            <div className="preview first_photo" onClick={() => openModal(i)}>
                                <img src={aggPhoto(p.url, 'thumb')} className={`${newPhotoClass} ${privatePhotoClass}`} width="80" height="106" alt="user main" />
                            </div>
                            {!user.isDeleted &&
                            <div className="photo_view_functions_block">
                                {/*user.member.mainPhotoId === String(p.id) && // users main photo
                                    <div className="photoEditAction" onClick={() => {makeMainPhotoByRequestHandler(p.id)}} title="main photo request by user"><i className="fa fa-check" aria-hidden="true"></i></div>
                                */}
                                {mainPhotoId !== p.id &&
                                    <div className="photoEditAction" onClick={() => {makeMainPhotoHandler(p.id)}} title="make main photo"><i className="fa fa-user" aria-hidden="true"></i></div>
                                }
                                {mainPhotoId === p.id &&
                                    <div className="photoEditAction" onClick={() => {removeMainPhotoHandler(p.id)}} title="Remove from main photo"><i className="fa fa-arrow-circle-o-down" aria-hidden="true"></i></div>
                                }
                                {!p.isPrivate && // public photo
                                    <div className="photoEditAction" onClick={() => {makePrivatePhotoHandler(p.id)}} title="make private photo"><i className="fa fa-lock" aria-hidden="true"></i></div>
                                }
                                {p.isPrivate && // private photo
                                    <div className="photoEditAction" onClick={() => {makePublicPhotoHandler(p.id)}} title="make public photo"><i className="fa fa-star" aria-hidden="true"></i></div>
                                }
                                <div className="photoEditAction" onClick={() => {showCrop(p)}} title="photo crop"><i className="fa fa-crop" aria-hidden="true"></i></div>
                                <div className={`${hideTfClass} photoEditAction`} onClick={() => {hideInTf(p)}} title="Hide photo in TF"><i className="fa fa-file-image-o" aria-hidden="true"></i></div>
                                <div className="cl"></div>
                                <div className="photoEditAction" onClick={() => {rotatePhotoHandler(p.id, 270)}} title="rotate photo"><i className="fa fa-undo" aria-hidden="true"></i></div>
                                <div className="photoEditAction" onClick={() => {rotatePhotoHandler(p.id, 90)}} title="rotate photo"><i className="fa fa-repeat" aria-hidden="true"></i></div>
                                <div className="photoEditAction" onClick={() => {removePhotoHandler(p.id)}} title="remove photo"><i className="fa fa-trash" aria-hidden="true"></i></div>
                            </div>
                            }
                        </div>
                    )
                })}

            </div>
        </>
    )
}


export default Photos
