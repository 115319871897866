import { useEffect, useState } from 'react';

import apiAdmin from "../../api/admin";
import {aggProfileUrl} from "../../helpers/profile";

const FemalePhoneDuplicates = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getList = () => {
        setIsLoading(true);

        apiAdmin.get('users/female_phone_duplicates').then((r) => {
            setList(Object.values(r.data));
            setIsLoading(false);
        })
    }

    useEffect(getList, []);

    const confirmHandler = (phone) => {
        apiAdmin.post('users/confirm_female_phone_duplicate', {phone: phone}).then((r) => {
            let newList = list.filter(r => {
                return r.phone !== phone;
            });
            setList(newList);
        });
    }

    return (
        <div>
            <h1>Female confirmed phone duplicates</h1>
            <table>
                <thead>
                    <tr>
                        <th>Phone</th>
                        <th>Females</th>
                        <th>Mark As Checked</th>
                    </tr>
                </thead>
                <tbody>
                {list && list.map((r, i) => {
                    return (
                        <tr key={i}>
                            <td style={{width: '20%'}}>{r.phone}</td>
                            <td style={{maxWidth: '60%'}}><MemberItem users={r.users} /></td>
                            <td style={{width: '20%'}}>
                                <button className="green-button" style={{fontWeight: "normal", cursor: "pointer"}} onClick={() => confirmHandler(r.phone)}>
                                    Mark as Checked
                                </button>
                            </td>
                        </tr>
                    )})}
                </tbody>
            </table>
            {isLoading && <div className="loader" />}
        </div>
    )
}

const MemberItem = ({users}) => {
    const itemJsx = users.map((u, i) => {
        let user = u.user;
        return (
            <div key={i} className="female-phone-duplicates">
                {(user.isVerified) ? <span className="green-text" title="Verified girl"><b>[V]</b></span> : ''}
                <a href={aggProfileUrl(user.id)} className="table-links">{user.name}</a>
                {(user.isBlocked) ? <span className="red-text">(B)</span> : ''}
                {(user.isHidden) ? <span className="red-text" title="Duplicated profile">(D)</span> : ''},&nbsp;
            </div>
        )
    });
    return itemJsx;
}

export default FemalePhoneDuplicates;
