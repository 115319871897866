import Classes from "./Buttons.module.scss";
const Button = (props) => {
    return (
        <button
            className={Classes.Button} {...props}>
            {props.children}
        </button>
    );
}

export default Button;
