import {useState, useEffect} from "react";
import classes from './MassMessage.module.scss';
import adminApi from "../../../api/admin";

const MassMessageConfirm = (props) => {

    const [disabledCreate, setDisabledCreate] = useState(true);
    const [disabledCreatePremium, setDisabledCreatePremium] = useState(true);
    const [disabledInsert, setDisabledInsert] = useState(false);
    const [disabledStart, setDisabledStart] = useState(true);
    const [loadingInsert, setLoadingInsert] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [loadingCreatePremium, setLoadingCreatePremium] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userCount, setUserCount] = useState(null);
    const [userCountPremium, setUserCountPremium] = useState(null);
    const [massMessageId, setMassMessageId] = useState(null);

    const postData = getAllFormData(props.data);

    let contentText = postData.message.replaceAll("%name%", "<span class='yellow-back'>%name%</span>");
    contentText = contentText.replaceAll("%country%", "<span class='yellow-back'>%country%</span>");

    const getCount = () => {
        if (postData.status_premium_paid) {
            postData.onlyPremium = true;
            adminApi.post('group_messages/mass_mail_messages/', postData)
                .then(r => {
                    setUserCountPremium(r.data.members);
                })
                .catch((e) => {
                    setUserCountPremium('> 200');
                });
        }

        postData.onlyPremium = false;
        adminApi.post('group_messages/mass_mail_messages/', postData)
            .then(r => {
                setUserCount(r.data.members);
                setIsLoading(false);
            })
            .catch((e) => {
                setUserCount('> 36000');
                setIsLoading(false);
            });
    }

    useEffect( getCount, []);

    return (
        <div>
            <h2>Confirmation of massmail</h2>

            <div>
                Sender: <b>{props.name}</b> [{postData.mass_memberID}]
            </div>

            <div className={classes.Content}>
                Content: <div dangerouslySetInnerHTML={{__html: contentText}}></div>
			</div>

            {isLoading && <div className="loader"></div>}

            {!isLoading &&
                <>
                    <div>Count of mass messages: <b>{userCount}</b></div>
                    <div >Count of premium members mass messages: <b>{userCountPremium}</b></div>

                    <div className={`${classes.Line} ${classes.LineCentered}`}>
                        <button type="button" disabled={disabledInsert} className="green-button-simple" onClick={insertMassMailInfo}>
                            {loadingInsert && <div className={`loader ${classes.SmallLoader}`}></div>}
                            {!loadingInsert && <span>Insert all senders data</span>}
                        </button>
                    </div>
                    {userCount &&
                        <div className={`${classes.Line} ${classes.LineCentered}`}>
                            <button type="button" disabled={disabledCreate} className="green-button-simple" onClick={createMassMailMessages}>
                                {loadingCreate && <div className={`loader ${classes.SmallLoader}`}></div>}
                                {!loadingCreate && <span>Create</span>}
                            </button>
                        </div>
                    }
                    {userCountPremium &&
                        <div className={`${classes.Line} ${classes.LineCentered}`}>
                            <button type="button" disabled={disabledCreatePremium} className="green-button-simple" onClick={createMassMailPremiummessages}>
                                {loadingCreatePremium && <div className={`loader ${classes.SmallLoader}`}></div>}
                                {!loadingCreatePremium && <span>Create Premium</span>}
                            </button>
                        </div>
                    }


                    <div className={`${classes.Line} ${classes.LineCentered}`}>
                        <button type="button" disabled={disabledStart} className="green-button-simple" onClick={startHandler}>Group message start!</button>
                    </div>
                    <div className={`${classes.Line} ${classes.LineCentered}`}>
                        <a href={process.env.REACT_APP_MP+'/group-messages'} className="table-links">Back to group message</a>
                    </div>
                </>
            }

        </div>
    );

    function insertMassMailInfo() {
        if (disabledInsert) {
            return false;
        }
        setDisabledInsert(true);
        setLoadingInsert(true);
        adminApi.post('group_messages/insert_mass_mail/', postData).then(r => {
            alert('Inserted. Now you can start insert messages');
            setMassMessageId(r.data.mass_message_id);
            setLoadingInsert(false);
            setDisabledCreate(false);
            setDisabledCreatePremium(false);
        });

    }

    function createMassMailMessages() {
        if (disabledCreate) {
            return false;
        }
        setDisabledCreate(true);
        setLoadingCreate(true);
        postData.onlyPremium = false;
        postData.insert = true;
        postData.massMessageId = massMessageId;

        adminApi.post('group_messages/mass_mail_messages/', postData).finally(r => {
            alert('Inserted messages');
            setLoadingCreate(false);
            setDisabledStart(false);
        });
    }

    function createMassMailPremiummessages() {
        if (disabledCreatePremium) {
            return false;
        }
        setDisabledCreatePremium(true);
        setLoadingCreatePremium(true);

        postData.onlyPremium = true;
        postData.insert = true;
        postData.massMessageId = massMessageId;
        adminApi.post('group_messages/mass_mail_messages/', postData).then(r => {
            alert('Inserted messages');
            setLoadingCreatePremium(false);
            setDisabledStart(false);
        });
    }

    function startHandler() {
        adminApi.post('group_messages/startmassmail/'+massMessageId).then(r => {
            setDisabledStart(true);
            alert('Started');
        });
    }

    function findInArr (jsObjects, needle) {
        const result = jsObjects.filter(obj => {
            return obj.name === needle;
        });

        if(result.length > 1) {
            const returnArr = result.map(function(obj){
                return obj.value;
            });
            return returnArr;
        }

        if (result[0]) {
            return result[0].value;
        }

        return false;
    }

    function getAllFormData(formData) {
        let result = {}

        result.sex_male = findInArr(formData, 'sex_male');
        result.sex_female = findInArr(formData, 'sex_female');
        result.mass_memberID = findInArr(formData, 'mass_memberID');
        result.message = findInArr(formData, 'message');
        result.mass_startDate = findInArr(formData, 'mass_startDate');
        result.status_registered = findInArr(formData, 'status_registered');
        result.status_premium_paid = findInArr(formData, 'status_paid_premium');
        result.status_premium_free = findInArr(formData, 'status_premium_free');
        result.status_no_purchase = findInArr(formData, 'status_purchase');
        result.priority = findInArr(formData, 'priority');
        result.isGreeting = findInArr(formData, 'insteadMarishka');
        result.premiumDayInt = findInArr(formData, 'premiumDayInt');
        result.countries = findInArr(formData, 'countries[]');
        result.photo_info = findInArr(formData, 'photo_info');
        result.age_from = findInArr(formData, 'age_from');
        result.age_to = findInArr(formData, 'age_to');
        result.language = findInArr(formData, 'language');
        result.wasPREMIUM = findInArr(formData, 'wasPREMIUM');
        result.premiumENDS = findInArr(formData, 'premiumENDS');
        result.GR_uniqueID = props.groupMsgId;
        result.exclude_none_active_user = findInArr(formData, 'exclude_none_active_user');
        result.exclude_blacklist_cookies = findInArr(formData, 'exclude_blacklist_cookies');
        result.exclude_blacklist = findInArr(formData, 'exclude_blacklist');
        result.auto_answer_select = findInArr(formData, 'auto_answer_select');
        result.go_offline_select = findInArr(formData, 'go_offline_select');
        result.show_in_gallery = findInArr(formData, 'show_in_gallery');
        result.messagesMinute = findInArr(formData, 'messagesMinute');
        result.order_by = findInArr(formData, 'order_by');
        result.is_recounting = findInArr(formData, 'is_recounting');
        result.recount_date = findInArr(formData, 'recount_date');
        result.auto_answer_content = findInArr(formData, 'auto_answer_content[]');
        result.autoAcceptPrivatePhotos = findInArr(formData, 'autoAcceptPrivatePhotos');
        result.timeInterval = findInArr(formData, 'timeInterval');
        result.after_response = findInArr(formData, 'after_response[]');
        result.hours = findInArr(formData, 'hours[]');
        result.minutes = findInArr(formData, 'minutes[]');
        result.seconds = findInArr(formData, 'seconds[]');

        let logasData = getLogString(result);

        result.logas = logasData;

        return result;
    }

    function getLogString(str) {
        let textas = '';
        let messageContent = str.message.replace(":", "//dvitaskis//");
        messageContent = messageContent.replace(";", "//kabliataskis//");

		textas = textas + 'ID:' + str.mass_memberID + ';';
		textas = textas + 'sex_male:' + str.sex_male + ';';
		textas = textas + 'sex_female:' + str.sex_female + ';';
		textas = textas + 'status_registered:' + str.status_registered + ';';
		textas = textas + 'status_premium_free:' + str.status_premium_free + ';';
		textas = textas + 'status_premium_paid:' + str.status_premium_paid + ';';
		textas = textas + 'photo_info:' + str.photo_info + ';';
		textas = textas + 'age_from:' + str.age_from + ';';
		textas = textas + 'age_to:' + str.age_to + ';';
		textas = textas + 'premiumDayInt:' + str.premiumDayInt + ';';
		textas = textas + 'countries:' + str.countries + ';';
		textas = textas + 'auto_answer_content:' + str.auto_answer_content + ';';
		textas = textas + 'after_response:' + str.after_response + ';';
		textas = textas + 'hours:' + str.hours + ';';
		textas = textas + 'minutes:' + str.minutes + ';';
		textas = textas + 'seconds:' + str.seconds + ';';
		textas = textas + 'language:' + str.language + ';';
		textas = textas + 'priority:' + str.priority + ';';
		textas = textas + 'exclude_blacklist:' + str.exclude_blacklist + ';';
		textas = textas + 'exclude_blacklist_cookies:' + str.exclude_blacklist_cookies + ';';
		textas = textas + 'exclude_none_active_user:' + str.exclude_none_active_user + ';';
		textas = textas + 'selfStop:' + str.selfStop + ';';
		textas = textas + 'message:' + messageContent + ';';
		textas = textas + 'order_by:' + str.order_by + ';';
		textas = textas + 'auto_answer_select:' + str.auto_answer_select + ';';
		textas = textas + 'go_offline_select:' + str.go_offline_select + ';';
		textas = textas + 'show_in_gallery:' + str.show_in_gallery + ';';
		textas = textas + 'timeInterval:' + str.timeInterval + ';';
		textas = textas + 'messagesMinute:' + str.messagesMinute + ';';
		textas = textas + 'autoAcceptPrivatePhotos:' + str.autoAcceptPrivatePhotos + ';';
		textas = textas + 'wasContact:' + str.wasContact + ';';
		textas = textas + 'premiumENDS:' + str.premiumENDS + ';';
		textas = textas + 'wasPREMIUM:' + str.wasPREMIUM + ';';
		textas = textas + 'GR_uniqueID:' + str.GR_uniqueID + ';';
		return textas;
    }
}

export default MassMessageConfirm;
