import { useEffect, useState } from 'react';
import apiAdmin from "../../api/admin";

const Vip = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDimensionsData = () => {
        setIsLoading(true);     

        apiAdmin.get('finance/vip_statistics').then((r) => {
            setList(current => [...current, ...r.data]);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getDimensionsData();
    }, []);

    
    return (
        <div>
            <h1>Vip statistics</h1>

            <table className="mt-10 dimensionsTable">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Total Vip members</th>
                        <th>Full price</th>
                        <th>Discount</th>
                        <th>Active</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                {list && 
                list.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.date}</td>
                            <td>{item.purchase}</td>
                            <td>{item.fullPrice}</td>
                            <td>{item.discounted}</td>
                            <td>{item.active}</td>
                            <td>{item.total} €</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default Vip;
