import {Fragment, useState} from "react";
import FsLightbox from 'fslightbox-react';


import {aggPhoto} from "../../helpers";
import styles from "./styles.module.scss";


const Attachments = ({list}) => {
    const [lightboxController, setLightboxController] = useState({toggler: false, slide: 1});

    if (!list || list.length === 0) {
        return null;
    }

    const openModal = (id) => {
        setLightboxController({toggler: !lightboxController.toggler, slide: id+1});
    }

    // Image list for lightbox
    const imgList = list.map(p => aggPhoto(p.imgUrl, ''));

    return (
        <Fragment>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={imgList}
                type="image"
                slide={lightboxController.slide}
            />

            <div className={styles.Area}>
                {list.map((img, i) => <Image key={img.imgUrl} img={img} type='micro' onClick={() => openModal(i)} />)}
            </div>
        </Fragment>
    );
}


const Image = ({img, type, onClick}) => (
    <img
        src={aggPhoto(img.imgUrl, type)}
        className={styles.Image}
        onClick={onClick ? onClick : () => {}}
    />
);


export default Attachments;
