import { useEffect, useState } from 'react';

import apiAdmin from "../../api/admin";
import {aggProfileUrl} from "../../helpers/profile";

const FemalePhotoDeleting = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getList = () => {
        apiAdmin.get('users/female_photo_deleting').then((r) => {
            setList(r.data.list);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getList();
    }, []);

    const confirmUser = (userId) => {
        apiAdmin.post('users/confirm_female_photo_deleting/', {userIds: [userId]}).then((r) => {
            let filteredArray = list.filter((el) => {
                return el.member_id !== userId
            });
            setList(filteredArray);
        })

    }

    const removeAll = () => {
        let userIds = [];
        list.map(el => {
            return userIds.push(el.member_id);
        });

        apiAdmin.post('users/confirm_female_photo_deleting/', {userIds: userIds}).then((r) => {
            setList(null);
            getList();
        });
    }

    return (
        <div>
            <h1>Female photo deleting</h1>

            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Delete from this list</th>
                    </tr>
                    </thead>
                    <tbody>
                    {list && list.map((r, i) => {
                        return (
                            <tr key={i}>
                                <td style={{width: '20%'}}>{r.maxdate}</td>
                                <td style={{maxWidth: '20%'}}>
                                    <a href={aggProfileUrl(r.member_id)} className="table-links">{r.name}</a>
                                </td>
                                <td style={{width: '60%'}}>
                                    <button
                                        className="green-button pointer"
                                        onClick={() => {confirmUser(r.member_id)}}
                                    >
                                        Delete from list
                                    </button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                {!isLoading && list.length > 0 &&
                    <button
                        className="green-button mt-10 pointer"
                        onClick={removeAll}
                    >
                        Delete All
                    </button>
                }
            </div>
            {(isLoading) ? <div className="loader" /> : '' }
        </div>
    );
}

export default FemalePhotoDeleting;
