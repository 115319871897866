import { useEffect, useState } from "react";
import apiAdmin from "../../../api/admin";
import VerifiedProfileItem from "./VerifiedProfileItem";

const VerifiedProfiles = () => {

    const [list, setList] = useState(false);
    const [loading, setLoading] = useState(false);
    const [verifiedAt, setVerifiedAt] = useState(null);
    const [search, setSearch] = useState(null);

    const getList = (loadMore = false) => {
        setLoading(true);

        let searchQuery =  (/^\d+$/.test(search)) ? parseInt(search) : search;
        let postData = {verifyDate: verifiedAt, search: searchQuery};
        if (!loadMore) {
            postData = {verifyDate: null, search: searchQuery};
            setList(null);
        }

        apiAdmin.post('verification/get_verified_profiles', postData).then((r) => {
            setLoading(false);
            if (loadMore) {
                setList(data => [...data, ...r.data.list])
            } else {
                setList(r.data.list);
            }
            setVerifiedAt(r.data.verifiedAt);
        })
    }

    useEffect(() => {
        getList();
    }, []);

    const removeVerification = (userId) => {
        apiAdmin.get('verification/remove_user_verification/' + userId).then((r) => {
            let newList = list.filter(el => {
                return el.user.id !== userId;
            });
            setList(newList);
        });
    }

    return (
        <div>
            <h1>Verified Profiles</h1>
            <a href={`${process.env.REACT_APP_MP}/verification`} className="fr table-links"><b>» Back to Profile verification</b></a>

            <input value={(search) ? search : ''} onChange={(e) => setSearch(e.target.value)}/>
            <button onClick={() => {getList()}}>Submit</button>

            {(loading) ? <div className="loader mt-20"/> : ''}
            {!loading &&
            <table className="verified-profiles">
                <tbody>
                { list &&
                    list.map((m, i) => {
                        return (
                            <VerifiedProfileItem user={m} onRemoveVerification={removeVerification} key={i}/>
                        )
                    })
                }
                </tbody>
            </table>
            }
        </div>
    )
}

export default VerifiedProfiles;
