import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {aggPhoto} from "../../helpers";
import {aggProfileUrl} from "../../helpers/profile";
import apiAdmin from "../../api/admin";
import { useRef } from "react";
import isInViewport from "../../helpers/viewport";

const BlockedUsers = () => {
    const [search, setSearch] = useState('');
    const [country, setCountry] = useState('');
    const [reason, setReason] = useState('');
    const [lastId, setLastId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);

    const {countryList} = useSelector(s => s.auth.config);

    const getBlockedUsers = (isUpdate) => {
        setIsLoading(true);
        let lastLoadedId = lastId;
        if (!isUpdate) {
            setData(null);
            setLastId(null);
            lastLoadedId = null;
        }
        let searchQuery =  (/^\d+$/.test(search)) ? parseInt(search) : search;
        let postData = {
            country: country,
            search: searchQuery,
            reason: reason,
            lastId: lastLoadedId
        };

        apiAdmin.post('users/blocked_users_list', postData).then((r) => {
            setIsLoading(false);
            if (isUpdate) {
                setData(data => [...data, ...r.data.list])
            } else {
                setData(r.data.list);
            }
            setLastId(r.data.lastId);
        })
    }

    const unblockUser = (userId) => {
        apiAdmin.post('users/unblock_user/' + userId).then((r) => {
            let filteredList = data.filter(el => {
                return el.id !== userId
            });
            setData(filteredList);
        });
    }

    useEffect(() => {
        getBlockedUsers();
    }, []);

    let timer = useRef(null);
    useEffect(() => {
        if (isLoading) {
            return;
        }

        const onScroll = () => {
            if (timer.current) {
                window.clearTimeout(timer.current);
            }
            timer.current = window.setTimeout(loadMore, 200);
        };
        document.addEventListener('scroll', onScroll);

        return () => {
            document.removeEventListener('scroll', onScroll);
        }
    }, [isLoading]);

    const loadMoreMessagesPointer = useRef();
    const loadMore = () => {
        if (isInViewport(loadMoreMessagesPointer.current)) {
            getBlockedUsers(true);
        }
    }

    const onInputChange = (event) => {
        switch (event.target.name) {
            case "country":
                setCountry(event.target.value);
                break;
            case "search":
                setSearch(event.target.value);
                break;
            case "reason":
                setReason(event.target.value);
                break;
            default:
                break;
        }
    };

    return (
        <div className="blocked-users-area">
            <h1 className="fl">Blocked users</h1>
            <div className="fl filters">
                Country:
                <select name="country" style={{width: '100px'}} onChange={onInputChange}>
                    <option value="">-</option>
                    {
                        Object.entries(countryList).map(([code, countryName]) => (
                            <option key={code} value={code}>{countryName}</option>
                        ))
                    }
                </select>
                Member:
                <input name="search" onChange={onInputChange} value={search} />
                Reason:
                <select name="reason" onChange={onInputChange}>
                    <option value=""/>
                    <option value="1">Fake photos</option>
                    <option value="2">Impolite</option>
                    <option value="3">Scam / Spam</option>
                    <option value="4">Escort</option>
                    <option value="6">For contacts</option>
                    <option value="7">Payment scam</option>
                    <option value="8">Celebrity photos</option>
                    <option value="9">Other</option>
                </select>
                <button onClick={() => getBlockedUsers()}>Filter</button>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Sex</th>
                        <th>Photo</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Unblock</th>
                    </tr>
                </thead>
                <tbody>
                    {data &&
                        data.map((user, i) => {
                            let sexFieldClass = (user.sex === 'male') ? 'male' : 'female';
                            let sexFieldContent = (user.sex === 'male') ? 'M' : 'F';
                            return (
                                <tr key={i}>
                                    <td className={sexFieldClass}>{sexFieldContent}</td>
                                    <td><img src={aggPhoto(user.photoUrl, 'thumb')} className="user-search-photo" alt="user" /></td>
                                    <td><a href={aggProfileUrl(user.id)} className="table-links">{user.name}</a></td>
                                    <td>{user.email}</td>
                                    <td>
                                        <span className="table-links" onClick={() => unblockUser(user.id)}>Unblock</span>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>

            <div className="loader-helper" ref={loadMoreMessagesPointer}>
                {(isLoading) ? <div className="loader"/> : ''}
            </div>
        </div>
    )
}

export default BlockedUsers;
