const ADMIN_ID = 'adminId';
const ADMIN_TOKEN = 'adminToken';


export const setAuthData = (adminId, token) => {
    localStorage.setItem(ADMIN_ID, adminId);
    localStorage.setItem(ADMIN_TOKEN, token);
}

export const unsetAuthData = () => {
    localStorage.removeItem(ADMIN_ID);
    localStorage.removeItem(ADMIN_TOKEN);
}

export const getAdminId = () => {
    return localStorage.getItem(ADMIN_ID);
}

export const getToken = () => {
    return localStorage.getItem(ADMIN_TOKEN);
}

