import {Routes, Route, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";


import '../styles/main.scss';
import {authActions, getAdmin} from "../store/auth";
import { getAdminBox } from "../store/admin-box";
import useQuery from "../helpers/useQuery";
import Header from './Header';
import Sidebar from "./Sidebar";
import Login from "./Login/Login";
import Loader from "../common/Loader/Loader";
import { getAdminId } from "../helpers/authentication"


//Members Components
import Suspicious from "./Members/Suspicious";
import SuspiciousTrips from "./Members/SuspiciousTrips";
import SuspiciousMessages from "./Members/SuspiciousMessages";
import UncheckedMessages from "./Members/UncheckedMessages";
// import SuspiciousMessagesEscort from "./Members/SuspiciousMessagesEscort";
import Chargeback from "./Members/Chargeback";
import FemalePhoneDuplicates from "./Members/FemalePhoneDuplicates";
import FemalePhotoDeleting from "./Members/FemalePhotoDeleting";
import VerifiedProfiles from "./Members/Verification/VerifiedProfiles";
import Verification from "./Members/Verification/Verification";
import VideoVerification from "./Members/VideoVerification";
import VerificationOld from "./Members/Verification/VerificationOld";
//
// // Fundamentals Components
import OnlineSender from "./Fundamentals/OnlineSender/OnlineSender";
import MemberOnline from "./Fundamentals/MemberOnline/MemberOnline";
import MassMessage from "./Fundamentals/MassMessage/MassMessage";
import MassMessageCreate from "./Fundamentals/MassMessage/MassMessageCreate";
import UnwantedUsers from "./Fundamentals/UnwantedUsers/UnwantedUsers";
//
// // Monitoring Components
import Support from "./Monitoring/Support";
import SupportTicket from "./Monitoring/SupportTicket";
import Reports from "./Monitoring/Reports";
import Reporters from "./Monitoring/Reporters";
import ErrorLog from "./Monitoring/ErrorLog";
import UserSearch from "./Monitoring/UserSearch";
import AboutMe from "./Monitoring/AboutMe";
import Cancel from "./Monitoring/Cancel";

import BlockedUsers from "./Monitoring/BlockedUsers";
import Faq from "./Monitoring/Faq";
import FaqEdit from "./Monitoring/FaqEdit";
//
// // Business Components
import Tests from "./Business/Tests";
import Dimensions from "./Business/Dimensions";
import Finance from "./Business/Finance";
import Statistics from "./Business/Statistics";
import Vip from "./Business/Vip";
import FemalePayments from "./Business/FemalePayments";

import Gallery from "./Gallery/Gallery";
import UserProfile from "./User/UserProfile";
import UserProfileUpdate from "./User/UserProfileUpdate";
import Inbox from "./Converstions/Inbox";
import Chat  from "./Converstions/Chat";
import GroupMessage from "./Members/GroupMessage/GroupMessage";
import MessagingGirls from "./Monitoring/MessagingGirls";


let isInitiated = false;

const PageNotFound = () => (<>Page not found 404.</>);

const App = () => {
    const dispatch = useDispatch();
    const {isChecked, isLogged, user, view} = useSelector(s => s.auth);
    const sidebarVisible = useQuery().get('sidebar') !== 'false';

    const adminId = getAdminId();

    useEffect(() => {
        if (!isInitiated) {
            isInitiated = true;

            if (adminId) {
                dispatch(getAdmin());
            }
            else {
                dispatch(authActions.setOnAuthError());
            }
        }
    }, [adminId]);

    useEffect(() => {
        if (isChecked && isLogged) {
            dispatch(getAdminBox());
        }
    }, [isChecked, isLogged])

    if (!isChecked) {
        return (<div className="flexCentered" style={{minHeight: '300px'}}><Loader /></div>);
    }

    if (isChecked && !isLogged) {
        return <Login />;
    }

    return (
        <div className="page-width">
            <Header />
            <div className="wrapper">
                {sidebarVisible && <Sidebar />}
                 <div className={sidebarVisible ? 'content' : 'content-fullWidth'}>
                     <Routes>
                         <Route path={'/'} element={<Gallery sidebarView={view} />} />
                         <Route path={'*'} element={<PageNotFound />} />
                         <Route path="/conversations" element={<Inbox />} />
                         <Route path="/conversations/:id" element={<Chat />} />

                         {/* Members Paths */}
                         <Route path="/verification" element={<Verification />} />
                         <Route path="/video-verification" element={<VideoVerification />} />
                         <Route path="/verified-profiles" element={<VerifiedProfiles />} />
                         <Route path="/suspicious" element={<Suspicious />} />
                         <Route path="/suspicious-trips" element={<SuspiciousTrips />} />
                         <Route path="/suspicious-messages" element={<SuspiciousMessages />} />
                         <Route path="/unchecked-messages" element={<UncheckedMessages />} />
                         <Route path="/phone-duplicates" element={<FemalePhoneDuplicates />} />
                         <Route path="/photo-deleting" element={<FemalePhotoDeleting />} />
                         <Route path="/chargeback" element={<Chargeback />} />
                         <Route path="/cancel" element={<Cancel />} />
                         <Route path="/messaging-girls" element={<MessagingGirls />} />

                         {/* Fundamentals Paths */}
                         <Route path="/online-sender" element={<OnlineSender />} />
                         <Route path="/member-online" element={<MemberOnline /> } />
                         <Route path="/mass-message" element={<MassMessage /> } />
                         <Route path="/mass-message-create" element={<MassMessageCreate /> } />
                         <Route path="/group-messages" element={<GroupMessage />} />
                         <Route path="/unwanted-users" element={<UnwantedUsers />} />

                         {/* Monitoring Paths */}
                         <Route path="/support/:id" element={<SupportTicket />} />
                         <Route path="/support" element={<Support />} />

                         <Route path="/reports" element={<Reports />} />
                         <Route path="/reporters" element={<Reporters />} />
                         <Route path="/error_logs" element={<ErrorLog />} />
                         <Route path="/user-search" element={<UserSearch />} />
                         <Route path="/updates-about-me" element={<AboutMe />} />
                         <Route path="/blocked" element={<BlockedUsers />} />
                         <Route path="/faq" element={<Faq />} />
                         <Route path="/faq/:id/:lang" element={<FaqEdit />} />

                         {/* Business Paths */}
                         <Route path="/tests" element={<Tests />} />
                         <Route path="/dimensions" element={<Dimensions />} />
                         <Route path="/finance" element={<Finance />} />
                         <Route path="/statistics" element={<Statistics />} />
                         <Route path="/vip" element={<Vip />} />
                         <Route path="/female_payments" element={<FemalePayments />} />

                         {/* User Profile  */}
                         <Route path="/user/:userId" element={<UserProfile />} />
                         <Route path="/user-updates/:sex" element={<UserProfileUpdate />} />

                         {/* TMP for old verification check */}
                         <Route path="/verifications-old/" element={<VerificationOld />} />
                     </Routes>
                 </div>
            </div>
        </div>
    );
}

export default App;
