import { useEffect, useState } from "react";

import apiAdmin from "../../../../api/admin";
import {aggProfileUrl} from "../../../../helpers/profile";

const VerificationItemInfo = (props) => {
    const v = props.item;

    const [blockReason, setBlockReason] = useState('');
    const [showBlock, setShowBlock] = useState(false);
    const [duplicates, setDuplicates] = useState(false);
    const [showDuplicates, setShowDuplicates] = useState(false);
    const blockUser = () => {
        if (window.confirm("Are you sure?")) {
            let data = {
                comment: blockReason,
                reason: 10,
                blockLevel: 'suspend',
                blockCookie: '1'
            };
            apiAdmin.post('users/block_user/' + v.user.id, data).then((r) => {
                props.onReload();
            })
        }
    }

    const getDuplicates = () => {
        apiAdmin.get('users/get_ip_duplicates/' + v.user.id).then((r) => {
            setDuplicates(r.data);
        })
    }

    useEffect(() => {
        getDuplicates();
    }, []);

    return (
        <td style={{paddingLeft: '12px'}}>
            <div>
                {(v.user.isBlocked) ? <span className="red"><b>Blocked</b></span> : ''}
                {(v.autoBlock) ? <span><br/>{v.autoBlock}</span> : ''}
                <br/>
                {(v.user.block !== null && v.user.block.adminId) ?
                    <span>
                        By Admin: <a href={aggProfileUrl(v.user.block.adminId)} className="table-links">
                            {v.user.block.adminName}
                        </a>
                    </span>
                : ''}
            </div>
            <div className="mt-10">
                User: <a href={aggProfileUrl(v.user.id)} className="table-links">{v.user.name}</a>
                {(v.user.app === 'tf') ? <span className="tf"> (TF) </span> : ''}
                {(v.user.isVerified) ? <span className="table-links"> (Verified)<i className="fa fa-check"></i></span> : null}
            </div>

            { duplicates &&
                (
                    <div className="mt-10">
                        <div className="table-links" onClick={() => {setShowDuplicates(!showDuplicates)}}>
                            IP Duplicates: <span className="pending-verification-count">{duplicates.length}</span>
                        </div>

                        {showDuplicates && duplicates.map((ip, i) => {
                            let sexFieldClass = (ip.sex === 'male') ? 'male' : 'female';
                            let sexFieldContent = (ip.sex === 'male') ? 'M' : 'F';
                            return (
                                <div className="pending-verification-duplicate" key={ip.member_id}>
                                    {(ip.deleted_name) ? 'Deleted: ' : ''}
                                    <span className={sexFieldClass}> {sexFieldContent} </span>
                                    <a href={aggProfileUrl(ip.member_id)} className="table-links">
                                        {(ip.name) ? ip.name : ip.deleted_name}
                                    </a>
                                    {(parseInt(ip.blocked) > 0) ? <span className="red"> (B) </span> : ''}
                                </div>
                            )
                        })}
                    </div>
                )
            }
            {showBlock &&
                <div>
                    <input
                        value={blockReason}
                        onChange={(event) => setBlockReason(event.target.value)}
                    />
                    <button onClick={blockUser}>Save</button>
                </div>
            }
            <span className="table-links mt-10" onClick={() => {setShowBlock(!showBlock)}}>
                Block permanently
            </span>
        </td>
    );
}

export default VerificationItemInfo;
