import FsLightbox from "fslightbox-react";
import { aggPhoto, aggDateFromTimestamp } from "../../../helpers";
import { useState } from "react";
import {aggProfileUrl} from "../../../helpers/profile";

const VerifiedProfileItem = (props) => {
    const [lightboxController, setLightboxController] = useState({toggler: false, slide: 1});
    const m = props.user;

    const photoList = [aggPhoto(m.user.photoUrl), aggPhoto(m.verificationPhoto)];

    const openModal = (id) => {
        setLightboxController({toggler: !lightboxController.toggler, slide: id});
    }

    return (
        <>
        <tr>
            <td>
                <img
                    alt="user"
                    src={aggPhoto(m.user.photoUrl, 'thumb')}
                    onClick={() => {openModal(1)}}
                />
            </td>
            <td>
                <img
                    alt="verification"
                    src={aggPhoto(m.verificationPhoto, 'thumb')}
                    onClick={() => {openModal(2)}}
                />
            </td>
            <td>
                <a href={aggProfileUrl(m.user.id)} className="table-links">{m.user.name}</a>
            </td>
            <td>
                <i className="fa fa-times" onClick={() => {
                    if (window.confirm("Are you sure?\n Do you really want to remove verification to this user?")) {
                        props.onRemoveVerification(m.user.id)
                    }
                }}/>
            </td>
            <td>
                <b> Verified by: {m.admin.name} </b><br/>
                {aggDateFromTimestamp(m.verifiedAt)}
            </td>
        </tr>

        <FsLightbox
            toggler={lightboxController.toggler}
            type="image"
            sources={photoList}
            slide={lightboxController.slide}
        />
        </>
    )
}

export default VerifiedProfileItem;
