import {useEffect, useState} from 'react';
import ReactPlayer from 'react-player'

import apiAdmin from "../../api/admin";
import {aggPhoto} from '../../helpers';
import {aggProfileUrl} from "../../helpers/profile";

const VideoVerification = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getList = (loadMore = false) => {
        setIsLoading(true);

        apiAdmin.get('video_verification/get_pending').then((r) => {
            setList(r.data);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getList();
    }, []);

    const makeAction = (action, userId, videoId, videoUrl, intro = null) => {
        let data = {
            "userId": userId,
            "action": action,
            "videoUrl": videoUrl,
            "videoId": videoId,
        };

        if (action === 'intro' && intro !== null) {
            data["intro"] = (intro) ? 0 : 1;
        }

        apiAdmin.post('video_verification/verify_video/' + data.userId, data).then((r) => {
            if (action !== 'intro') {
                removeUserFromList(userId)
            } else {
                let updatedList = list.map(el => {
                    if (el.video.id === videoId) {
                        el.video.intro = !el.video.intro
                    }
                    return el;
                });
                setList(updatedList);
            }
        });
    };

    const removeUserFromList = (userId) => {
        let newList = list.filter(el => {
            return el.user.id !== userId
        });
        setList(newList);
    };

    return (
        <div className="video-verification">
            <h1 className="fl">Video Verification</h1>

            <table>
                <tbody>
                {list && list.length > 0 &&
                    list.map((ver, i) => {
                        let sexFieldClass = (ver.user.sex === 'male') ? 'male' : 'female';
                        let sexFieldContent = (ver.user.sex === 'male') ? 'M' : 'F';
                        let isIntro = (ver.video.intro) ? 'green-button' : 'grey-button';
                        return (
                            <tr key={i}>
                                <td className="video-verification-photo">
                                    <img alt="user" src={aggPhoto(ver.user.photoUrl, 'thumb')}/><br/>
                                    <span className={sexFieldClass}>{sexFieldContent} </span>
                                    <a href={aggProfileUrl(ver.user.id)} className="table-links">{ver.user.name}</a>
                                </td>
                                <td className="video-verification-video">
                                    <ReactPlayer
                                        url={ver.video.url}
                                        controls={true}
                                        height={200}
                                        width={250}
                                        file={{
                                            file: {
                                                forceHLS: true
                                            }
                                        }}
                                    />
                                </td>
                                <td className="video-verification-actions">
                                    <button
                                        className="green-button button"
                                        onClick={() => {
                                            makeAction('verify', ver.user.id, ver.video.id, ver.video.url);
                                        }}
                                    >
                                        Verify
                                    </button>
                                    <button
                                        className="grey-button button"
                                        onClick={() => {
                                            if (window.confirm("Are you sure you want to decline this verification?")) {
                                                makeAction('decline', ver.user.id, ver.video.id, ver.video.url)
                                            }
                                        }}
                                    >
                                        Decline
                                    </button>
                                    <button
                                        className={`${isIntro} button`}
                                        onClick={() => makeAction('intro', ver.user.id, ver.video.id, ver.video.url, ver.video.intro)}
                                    >
                                        Intro
                                    </button>
                                    <button
                                        className="grey-button button"
                                        title="Click to copy video url"
                                        onClick={() => {navigator.clipboard.writeText(ver.video.url)}}
                                    >
                                        Copy URL
                                    </button>
                                </td>
                                <td></td>
                            </tr>
                        )
                })}
                {list && list.length === 0 &&
                    <tr><td>The list is empty</td></tr>
                }
                </tbody>
            </table>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default VideoVerification;
