import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


import store from "./store/index"
import App from './components/App';
import Landing from "./components/Landing";
import reportWebVitals from './reportWebVitals';

// Error logger only in production mode
if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: "https://f1a3527cf7074650909c4bbcb2fb8a25@o529142.ingest.sentry.io/4504559660695552",
        release: process.env.REACT_APP_NAME + "@" + process.env.REACT_APP_VERSION,
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 0,
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <BrowserRouter basename={'/adminpanel'}>
            <App />
        </BrowserRouter>

        <BrowserRouter basename="/">
            <Landing />
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
