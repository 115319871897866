import {createSlice} from "@reduxjs/toolkit";
import adminApi from "../api/admin";

const initialState = {
    data: null
}

const adminBoxSlice = createSlice({
    name: 'adminBox',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state.data = action.payload.data;
        },
        setData(state, action) {
            let updatedData = [...state.data];
            updatedData.map(u => {
                if (u.name === action.payload.name) {
                    u.value = action.payload.value;
                }
                return u;
            });
            state.data = updatedData;
        },
        decreaseCount(state, action) {
            let updatedData = [...state.data];
            updatedData.map(u => {
                if (u.name === action.payload.name) {
                    u.value--;
                }
                return u;
            });
            state.data = updatedData;
        }
    }
});

export const getAdminBox = () => {
    return async (dispatch) => {
        const userInfo = await adminApi.get('dashboard/get_administration_box');

        dispatch(adminBoxActions.set({
            data: userInfo.data
        }));
    }
}

export const adminBoxActions = adminBoxSlice.actions;
export default adminBoxSlice.reducer;
