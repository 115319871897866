import {useSelector} from "react-redux";
import {useState} from "react";

import apiAdmin from "../../api/admin";
import {aggPhoto, textHighlighter} from "../../helpers";
import {aggProfileUrl} from "../../helpers/profile";

const UserSearch = () => {
    const {countryList} = useSelector(s => s.auth.config);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [searchType, setSearchType] = useState('user');
    const [phrase, setPhrase] = useState('');

    const onUserSearchSubmit = (e) => {
        e.preventDefault();

        if (e.target.search.value.length === 0) {
            return;
        }

        const sType = e.target.type.value
        setSearchType(sType);
        setPhrase(e.target.search.value);

        const postData = {
            type: sType,
            search: e.target.search.value
        }

        if (sType === 'about') {
            postData.sex = e.target.sex.value;
            postData.order = e.target.order.value;
            postData.country = e.target.country.value;
        }

        initSearch(postData);
    }

    const initSearch = (data) => {
        setData(null);
        setLoading(true);

        apiAdmin.post('users/search', data).then((r) => {
            setLoading(false);
            setData(r.data.members);
        })
    }

    return (
        <div className='user-search'>
            <h1>User search</h1>
            <form onSubmit={onUserSearchSubmit} className="user-search-form">
                Search:
                <input type="hidden" name="type" value="user" />
                <input name="search" />
                <button type="submit">Find member</button>
            </form>

            <br/>

            <h1>About me search</h1>
            <form onSubmit={onUserSearchSubmit} className="user-search-form">
                Search:
                <input type="hidden" name="type" value="about" />
                <input name="search" />
                Country:
                <select name="country" style={{width: '140px'}}>
                    <option value=""/>
                    {
                        Object.entries(countryList).map(([code, countryName]) => (
                            <option key={code} value={code}>{countryName}</option>
                        ))
                    }
                </select>
                Sex:
                <select name="sex" >
                    <option value=""/>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
                Order By:
                <select name="order">
                    <option>Last registration</option>
                    <option value="online">Last online</option>
                </select>
                <button type="submit">Search</button>
            </form>

            {(isLoading) ? <div className="loader mt-50"/> : ''}

            {data && (
                <div>
                    <h3>Results:</h3>
                    <table>
                        <thead>
                        <tr>
                            <th>Sex</th>
                            <th>ID</th>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>About / Email</th>
                            <th/>
                            <th/>
                        </tr>
                        </thead>
                        <tbody>
                        <List data={data} type={searchType} phrase={phrase}/>
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

const List = ({data, type, phrase}) => {
    return data.map(user => {
        const sexFieldClass = (user.sex === 'male') ? 'male' : 'female';
        const sexFieldContent = (user.sex === 'male') ? 'M' : 'F';
        const isBlocked = (user.isBlocked) ? 'blocked' : '';
        const isHidden = (user.isHidden) ? 'hidden' : '';

        return (
            <tr key={user.id}>
                <td className={sexFieldClass}>{sexFieldContent}</td>
                <td>{user.id}</td>
                <td>
                    {user.photo && <img src={aggPhoto(user.photo, 'thumb')} className="user-search-photo" alt="user"/>}
                </td>
                <td>
                    <a href={aggProfileUrl(user.id)} className="user-search-link">
                        {user.name} {(user.isDeleted) ? <small className='red'>(deleted)</small> : ''}
                    </a>
                </td>

                {type === 'about' && <td dangerouslySetInnerHTML={{__html: textHighlighter(user.aboutMe, phrase)}}/>}
                {type === 'user' && <td>{user.email}</td>}

                <td className={isBlocked}>{isBlocked}</td>
                <td className={isHidden}>{isHidden}</td>
            </tr>
        )
    });
}

export default UserSearch;
