import { useEffect, useState } from "react";
import adminApi from "../../api/admin";
import { aggDateFromTimestamp, aggregateText } from "../../helpers";
import Attachments from "../Attachments";
import TranslatedText from "../TranslatedText/TranslatedText";

const ConversationItem = ({user1, user2}) => {
    const [isLoaded, setLoaded] = useState(false);
    const [list, setList] = useState([]);
    const [isGroupMessage, setIsGroupMessage] = useState(false);

    useEffect(() => {
        adminApi.post('conversations/get_messages/'+user1.id, {uid: user2.id}).then((r) => {
            setLoaded(true);
            setList(r.data.messages);
            setIsGroupMessage(r.data.isGroup);
        });
    }, []);

    return <>{!isLoaded ? <p>Loading ....</p> : <MessagesList list={list} user={user1} participant={user2} isGroupMessage={isGroupMessage} />}</>;
};

const MessagesList = ({list, user, participant, isGroupMessage}) => {
    const [translate, setTranslate] = useState(false);
    if (list.length === 0) {
        return <b>No messages!</b>
    }

    return (
        <table>
            <thead>
                <tr>
                    <th>Sender {isGroupMessage && <span style={{fontSize: '8px', color: '#ffd600', display: 'block'}}>Group message</span>}</th>
                    <th>Content {!translate && <span onClick={() => setTranslate(true)} style={{cursor: 'pointer'}}>(Translate)</span>}</th>
                    <th>Sent at</th>
                </tr>
            </thead>
            <tbody style={{backgroundColor: '#fafafa'}}>{list.map(m => <MessageItem message={m} user={user} translated={translate} participant={participant} key={m.id} />)}</tbody>
        </table>
    );
}

const MessageItem = ({message, user, participant, translated}) => (
    <tr>
        <td>{(message.senderId === user.id) ? user.name : participant.name}</td>
        <td style={{width: '65%'}}>
            {!translated && <div dangerouslySetInnerHTML={{__html: aggregateText(message.content)}} />}
            {translated && <div><TranslatedText textToTranslate={message.content} /></div>}
            <Attachments list={message.attachments} />
        </td>
        <td>{aggDateFromTimestamp(message.sentAt)}</td>
    </tr>
);

export default ConversationItem;