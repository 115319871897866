import {Fragment, useEffect, useState} from "react";


import adminApi from "../../api/admin";


const Tests = () => {
    const [testList, setTestList] = useState([])
    const [testResults, setTestResults] = useState(null);
    const [testId, setTestId] = useState(null);
    const [note, setNote] = useState('');

    // Get test list on page load
    useEffect(() => {
        adminApi.get('tests/').then(r => {
            setTestList(r.data);
            setTestId(r.data[0].testName);
            setNote(r.data[0].note);
        });
    }, []);

    // Get test result on test-id change (first test-id set)
    useEffect(() => {
        if (!testId) {
            return;
        }

        setTestResults(null);
        adminApi.get('tests/get_test_results/'+testId).then((r) => {
            setTestResults(Object.values(r.data));
        });
    }, [testId]);

    const onChange = (e) => {
        const currentTest = testList.find(i => i.testName === e.target.value);
        setTestId(currentTest.testName);
        setNote(currentTest.note);
    };

    const saveNote = (e) => {
        e.preventDefault();
        const newTestList = [...testList];
        const n = newTestList.find(i => i.testName === testId);
        n.note = e.target.note.value;

        setTestList(newTestList);

        adminApi.post('tests/update_test_note/', {testName: testId, note: n.note}).then(r => {
            console.log(r.data);
            alert('Updated');
        });
    };


    if (testList.length === 0) {
        return <div className="loader mt-50"/>
    }

    return (
        <div>
            <select onChange={onChange}>
                {testList.map(t => <option key={t.testName} value={t.testName}>{t.testName}</option>)}
            </select>

            {!testResults && <div className="loader mt-50"/>}
            {testResults && (
                <Fragment>
                    <Results data={testResults} />
                    <div className="mt-20">
                        <form onSubmit={saveNote}>
                            <textarea cols="50" rows="8" name="note" defaultValue={note} />
                            <button type="submit" className="green-button d-block">Save note</button>
                        </form>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

const Results = ({data}) => {
    if (!data) {
        return;
    }

    return (
        <table className="mt-20">
            <thead>
            <tr>
                <th>Version</th>
                <th>Reg Count</th>
                <th>Trial Count</th>
                <th>Subscribe Count</th>
                <th>Total Amount</th>
            </tr>
            </thead>
            <tbody>

            {data.map(test =>
                <tr key={test.version}>
                    <td>{test.version}</td>
                    <td>{test.regCount}</td>
                    <td>{test.trialCount}</td>
                    <td>{test.subscribeCount}</td>
                    <td>{test.total}</td>
                </tr>)}
            </tbody>
        </table>
    );
}

export default Tests;
