import { useEffect, useState } from 'react';

import apiAdmin from "../../../api/admin";
import { aggPhoto } from '../../../helpers';

const VerificationOld = () => {
    const [list, setList] = useState(null);
    const [previousList, setPreviousList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getList = (lastId = false) => {
        setIsLoading(true);
        if (lastId) {
            setPreviousList(list);
            setList(null);
        }

        apiAdmin.post('verification/get_old_verifications', {lastId: lastId}).then((r) => {
            setList(r.data);
            setIsLoading(false);
        })
    }

    const loadOldList = () => {
        setIsLoading(true);
        setList(previousList);
        setPreviousList(null);
        setIsLoading(false);
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <div>
            <h1>Old verifications. Total left: {list && list.total}</h1>
            {previousList && <button className="red-button" onClick={() => loadOldList()}>Go back</button>}
            <div className='old-verifications'>
                { list &&
                    list.data.map(row => {
                        return (
                            <Item data={row} key={row.userId} />
                        )
                    })
                }
            </div>
            {(isLoading) ? <div className="loader"/> : ''}
            {!isLoading && list && list.data.length > 0 && list.lastId && (
                <button className="green-button load-more" onClick={() => getList(list.lastId)}>Load more</button>
            )}
        </div>
    )
}

const Item = ({data}) => {
    const [inactive, setInactive] = useState(false);
    const deleteVerificationHandler = () => {
        if (!inactive) {
            setInactive(true);
            apiAdmin.post('verification/delete_bad_verification', {userId: data.userId});
        }
    }

    return (
        <div className='old-verification-item'>
            {inactive && <div className='inactive'>REMOVED</div>}
            <img
                alt="verification"
                src={aggPhoto(data.photo, '')}
            />
            {!inactive && <div className='delete-button' onClick={deleteVerificationHandler}>DELETE</div>}
        </div>
    )
}

export default VerificationOld;
