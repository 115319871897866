import {Fragment, useEffect, useState} from "react";
import FsLightbox from 'fslightbox-react';
import JSONPretty from 'react-json-pretty';
import { MultiSelect } from "react-multi-select-component";

import adminApi from "../../api/admin";
import {aggDateFromTimestamp} from "../../helpers";

const options = [
    {value: "tg", label: "TG"},
    {value: "tg-mobile", label: "Tg Mobile"},
    {value: "tf", label: "Travelfriend"},
    {value: "tm", label: "Travel app"},
    {value: "crons", label: "Cronjobs"},
    {value: "mailer", label: "Mailer"},
];
const selectedOptions = options.slice(0, 4);
const getSelectedOptionValue = (list) => list.map(l => l.value);

const ErrorLog = () => {
    const [logs, setLogs] = useState([]);
    const [filter, setFiler] = useState({
        apps: getSelectedOptionValue(selectedOptions),
        lastId: null,
        dateFrom: null,
        dateTo: null,
        search: '',
        show404: false
    });
    const [apps, setApps] = useState(selectedOptions);

    const getLogs = (givenFilter, isNewFilter = false) => {
        if (isNewFilter) {
            setLogs([]);
        }

        adminApi.post('error_log/', givenFilter).then((r) => {
            const {lastId, data} = r.data;
            setFiler({...givenFilter, lastId: lastId});
            setLogs(prevLogs => {
                return [...prevLogs, ...data];
            })
        });
    };

    useEffect(() => getLogs(filter), []);

    const onSubmit = (e) => {
        e.preventDefault();
        const newFilter = {
            apps: getSelectedOptionValue(apps),
            lastId: null,
            dateFrom: e.target.dateFrom.value,
            dateTo: e.target.dateTo.value,
            search: e.target.search.value,
            show404: e.target.show404.checked
        };
        getLogs(newFilter, true);
    }

    return (
        <div className="error-logs-form">
            <form onSubmit={onSubmit} className={"error-logs_header"}>
                <MultiSelect
                    options={options}
                    value={apps}
                    onChange={setApps}
                    labelledBy={"Platforms"}
                    className={"multi-select"}
                />
                <div>
                    <div><input type="date" name="dateFrom" /></div>
                    <div><input type="date" name="dateTo" /></div>
                </div>
                <div>
                    <div>
                        <input type="checkbox" name="show404" />
                        <span className="error-logs-form-checkbox-label">Show 404</span>
                    </div>
                    <div><input type="text" name="search" placeholder="Min. 3 characters to search..." /></div>
                </div>
                <button type="submit" className="green-button">Submit</button>
            </form>

            <LogList logs={logs} />
            {logs.length > 0 && filter.lastId && (
                <button className="green-button load-more" onClick={() => getLogs(filter)}>Load more</button>
            )}
        </div>
    );
}

const LogList = ({logs}) => {
    const [toggler, setToggler] = useState(false);
    const [data, setData] = useState(null);

    const openPopup = (log) => {
        setToggler(!toggler);
        setData(log);
    }

    return (
        <Fragment>
            <table className="error-logs-form-table mt-20">
                <thead>
                <tr>
                    <th/>
                    <th>IP</th>
                    <th>Time<br/>Request Method</th>
                    <th>URL<br/>Error Message</th>
                </tr>
                </thead>
                <tbody>
                    {logs.map((log) => {
                        return (
                            <tr key={log.id} onClick={() => openPopup(log)} className={log.level === '404' ? 'error-logs-form-error-404' : ''}>
                                <td className={log.isNew ? 'error-logs-form-error-unseen' : 'error-logs-form-error-seen'} />
                                <td className="error-logs-form-error-field">
                                    <b>{log.app}</b><br />
                                    {log.REMOTE_ADDR}
                                </td>
                                <td className="error-logs-form-error-field"><b>{log.REQUEST_METHOD}</b><br/>{aggDateFromTimestamp((log.createdAt))}</td>
                                <td className="error-logs-form-error-type">
                                    <span title={log.REQUEST_URI}>{log.REQUEST_URI}</span><br/>
                                    <span className="dark-grey-text" title={log.msg}>{log.msg}</span>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            <FsLightbox
                toggler={toggler}
                sources={[
                    <div style={{
                        background: 'white',
                        display: 'flex',
                        padding: '20px',
                        overflowY: 'scroll',
                        minHeight: '80vh',
                        height: '100vh'
                    }}>
                        <JSONPretty id="json-pretty" data={data} />
                    </div>
                ]} />
        </Fragment>
    );
}

export default ErrorLog;
