import { aggPhoto } from "../../../../helpers";
import apiAdmin from "../../../../api/admin";

const VerificationItemPhotos = (props) => {
    const v = props.item;

    const rotatePhoto = (userId, photoId, angle, selfie) => {
        if (selfie) {
            apiAdmin.get('photos/rotateVerificationSelfie/' + angle + '/' + userId + '/' + photoId).then((r) => {
                props.onReload();
            })
        } else {
            apiAdmin.get('photos/rotateVerificationPhoto/' + angle + '/' + userId + '/' + photoId).then((r) => {
                props.onReload();
            });
        }
    }

    const modalPhotoId = (v.selfiePhoto) ? 3 : 2;

    return (
        <>
            <td className="pending-verification-photo">                
                <div style={{height: "170px"}}>
                    <img
                        alt="user"
                        src={aggPhoto(v.user.photoUrl, 'thumb')}
                        onClick={() => {props.onOpenModal(1)}}
                    />
                    <br/>
                    <div>
                        {v.historyCount > 1 &&
                            <span className="table-links"  onClick={props.onShowHistory}>
                                <i className="fa fa-history" /> Verify history: <span className="red"><b> {v.historyCount} </b></span>
                            </span>
                        }
                    </div>
                </div>
                {v.verificationExample ?
                    <div>
                        <picture title={v.verificationExample.text}>
                            <source srcSet={v.verificationExample.urlWebp} type="image/webp" />
                            <img src={v.verificationExample.urlJpg} alt="verification example" />
                        </picture>
                    </div>
                : null}
            </td>
            <td className="pending-verification-photo">
                <div style={{height: '170px', position: 'relative'}}>
                {v.selfiePhoto ?
                    <>
                        <input
                            type="checkbox"
                            style={{position: 'absolute', top: '52px', right: '-11px'}}
                            checked={props.selfieSelected}
                            onChange={props.onSelfieSelect}
                        />
                        <img
                            alt="verification"
                            src={aggPhoto(v.selfiePhoto, 'thumb')}
                            onClick={() => {props.onOpenModal(modalPhotoId)}}
                        />
                        <br/>
                        <a href={aggPhoto(v.selfiePhoto)} target="_blank" rel="noopener noreferrer" className="table-links">
                            Original photo
                        </a>
                        
                        <br/>
                        <i className="fa fa-undo rotate-icon" onClick={() => {rotatePhoto(v.user.id, v.selfiePhotoId, 270, true)}}/>
                        <i className="fa fa-undo fa-flip-horizontal rotate-icon" onClick={() => {rotatePhoto(v.user.id, v.selfiePhotoId, 90, true)}}/>
                    </>
                : null}
                </div>
                <div style={{position: 'relative'}}>
                    {v.selfiePhoto && 
                        <input
                            type="checkbox"
                            style={{position: 'absolute', top: '52px', right: '-11px'}}
                            checked={!props.selfieSelected}
                            onChange={props.onSelfieSelect}
                        />
                    }
                    <img
                        alt="verification"
                        src={aggPhoto(v.photo, 'thumb')}
                        onClick={() => {props.onOpenModal(2)}}
                    />
                    <br/>
                    <a href={aggPhoto(v.photo)} target="_blank" rel="noopener noreferrer" className="table-links">
                        Original photo
                    </a>
                    {(parseInt(v.webcam)) ? <small className="red-text"> (webcam) </small> : ''}
                    <br/>
                    <i
                        className="fa fa-undo rotate-icon"
                        onClick={() => {rotatePhoto(v.user.id, v.id, 270)}}
                    />
                    <i
                        className="fa fa-undo fa-flip-horizontal rotate-icon"
                        onClick={() => {rotatePhoto(v.user.id, v.id, 90)}}
                    />
                </div>
            </td>
        </>
    );
}

export default VerificationItemPhotos;