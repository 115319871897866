import {Fragment, useEffect, useState} from "react";

import adminApi from "../../api/admin";
import {aggDateFromTimestamp, aggPhoto} from "../../helpers";
import Attachments from "../../common/Attachments";
import {aggProfileUrl} from "../../helpers/profile";
import ConversationItem from "../../common/Conversations/ConversationItem";
import useQuery from "../../helpers/useQuery";
import Modal from "../../common/Modal/Modal";
import { adminBoxActions } from "../../store/admin-box";
import { useDispatch } from "react-redux";

const reasonListAll = [
    {id: 1, value: 'Fake photos'},
    {id: 2, value: 'Impolite behavior'},
    {id: 3, value: 'Scam'},
    {id: 4, value: 'Escort'},
    {id: 5, value: 'Duplicated profile'},
    {id: 6, value: 'Sexual harasment'},
    {id: 9, value: 'Other'}
];

const reasonListMale = [
    {id: 1, value: 'Fake photos'},
    {id: 2, value: 'Impolite behavior'},
    {id: 3, value: 'Scam'},
    {id: 4, value: 'Escort'},
    {id: 5, value: 'Duplicated profile'},
    {id: 9, value: 'Other'}
];

const reasonListFemale = [
    {id: 2, value: 'Impolite behavior'},
    {id: 6, value: 'Sexual harasment'},
    {id: 1, value: 'Fake photos'},
    {id: 3, value: 'Scam'},
    {id: 9, value: 'Other'}
];

const Reports = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [userReportList, setUserReportList] = useState([])
    const [lastReportId, setLastReportId] = useState(null);
    const sex = useQuery().get('sex');

    useEffect(() => {
        getReports({status: 'n', sex: sex});
    }, [])

    const getReports = (data = {}, newList = true) => {
        setLoading(true);
        if (newList) {
            setList([]);
        }

        adminApi.get('reports', {params: data}).then(r => {
            setLoading(false);

            setList(prevList => {
                return [...prevList, ...Object.values(r.data.reports)];
            });
            setUserReportList(Object.values(r.data.userReports));
            setLastReportId(r.data.lastId);
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            userId: e.target.userId.value,
            name: e.target.name.value,
            email: e.target.email.value,
            status: e.target.status.value,
            sex: sex
        };
        getReports(data);
    }

    const removeItem = id => {
        let newList = list.filter(el => el.report.id !== id);
        setList(newList);
        dispatch(adminBoxActions.decreaseCount({name: 'Reported men'}));
    }

    const onAction = (action, report) => {
        if (action === 'filter') {
            getReports({userId: report.userId, status: 'all'});
            return;
        }
        if (action === 'removeItem') {
            removeItem(report);
            return;
        }
        if (action === 'updateAllUsers') {
            const newList = [...list];
            newList.map(l => {
                if (l.reportedUser.id === report.id) {
                    l.isSexist = report.isSexist;
                    if (report.score) {
                        l.politeness = report.score;
                    }
                    if (report.maxScore) {
                        l.politenessMax = report.maxScore;
                    }
                    if (report.comment) {
                        l.reportedUserComment = report.comment;
                    }
                }
                return l;
            });
            setList(newList);
            return;
        }
        if (window.confirm('Are you sure?')) {
            if (action === 'onConfirm') {
                removeItem(report.id);
                adminApi.post('reports/check', {id: report.id});
                alert('Confirmed!');
            }
            else if (action === 'onIgnore') {
                removeItem(report.id);
                const currentItem = list.find(el => el.report.id === report.id);
                if (currentItem) {
                    adminApi.post('reports/ignore', {id: report.id, userId: currentItem.user.id});
                }
            }
            else if (action === 'onDelete') {
                removeItem(report.id);
                adminApi.post('reports/delete', {id: report.id});
            }
            else if (action === 'onAddToUnwanted') {
                const currentItem = list.find(el => el.report.id === report.id);
                if (currentItem) {
                    adminApi.post('users/add_to_unwanted_list', {
                        userId: currentItem.user.id,
                        unwantedUserId: currentItem.reportedUser.id
                    });
                    alert('Added!');
                }
            }
            else if (action === 'onSendWarning') {
                const currentItem = list.find(el => el.report.id === report.id);
                if (currentItem) {
                    adminApi.post('reports/warning_about_escort', {
                        userId: currentItem.reportedUser.id,
                        reportId: report.id
                    });
                    alert("Warning sent!")

                    const newList = [...list];
                    newList.map(l => {
                        if (l.report.id === report.id) {
                            l.reportedUser.warningAboutEscort = true;
                        }
                        return l;
                    });
                    setList(newList);
                }
            }
        }
    }

    return (
        <div className="reports-area">
            <h1 className="fl"><span>{sex}</span> reports</h1>
            <form onSubmit={onSubmit} className="fl filters">
                <input name="userId" placeholder="User ID" style={{width: '80px'}} />
                <input name="name" placeholder="Name" />
                <input name="email" placeholder="Email" />
                <select name="status">
                    <option value="n">Unconfirmed</option>
                    <option value="y">Confirmed</option>
                    <option value="d">Declined</option>
                    <option value="i">Ignored</option>
                </select>
                <button type={"submit"}>Filter</button>
            </form>
            <ResultsTable list={list} userReportList={userReportList} onAction={onAction} updateSex={sex} /><br />
            {!isLoading && lastReportId && <button className="green-button load-more" onClick={() => getReports({status: 'n', sex: sex, lastId: lastReportId}, false)}>Load more</button>}
            {isLoading && <div className="loader mt-50"/>}
        </div>
    );
}

const ResultsTable = ({list, userReportList, onAction, updateSex}) => {
    if (list.length === 0 && userReportList.length === 0) {
        return (<div className="no-results">List is empty</div>);
    }

    return (
        <table className="report-table">
            <thead>
            <tr>
                <th style={{width: '70px'}}>Date</th>
                <th style={{width: '65px'}}>Sender</th>
                <th style={{width: '340px'}}>Reason</th>
                <th style={{width: '65px'}}>Reported User</th>
                <th style={{width: '100px'}}>Verdict</th>
                <th>Comment</th>
            </tr>
            </thead>
            {list.length > 0 && list.map((r, i) => <ResultItem key={r.report.id} r={r} onAction={onAction} updateSex={updateSex} />)}
            {userReportList.length > 0 && <tr><td colSpan="6" className="users-reports-title">{userReportList[0].user.name} reports: {userReportList.length}</td></tr>}
            {userReportList.length > 0 && userReportList.map((r, i) => <ResultItem key={r.report.id} r={r} onAction={onAction} updateSex={updateSex} />)}
        </table>
    );
}

const ResultItem = ({r, onAction, updateSex}) => {
    const [showConversation, setShowConversation] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showDeclinePopup, setShowDeclinePopup] = useState(false);
    const onConfirm = () => onAction('onConfirm', r.report);
    const onAddToUnwanted = () => onAction('onAddToUnwanted', r.report);
    const onSendWarning = () => onAction('onSendWarning', r.report);

    const onDecline = () => {
        onAction('onDelete', r.report);
        adminApi.post('reports/log_action', {id: r.report.id, action: 'decline'});
    }

    const removeItemHandler = (reportId, updateData) => {
        setShowPopup(false);
        setShowDeclinePopup(false);
        if  (updateData) {
            onAction('updateAllUsers', updateData);
        }
        onAction('removeItem', reportId);
    }

    const onIgnoreReporterHandler = ()  => {
        if (window.confirm("Are you sure you want to ignore reporter?")) {
            adminApi.post('reports/ignore', {id: r.report.id, userId: r.user.id});
            onAction('removeItem', r.report.id);
        }
    }

    const isBlocked = (r.reportedUser.isBlocked) ? 'blocked-border' : '';
    const isBlockedReporter = (r.user.isBlocked) ? 'blocked-border' : '';
    const sexClassReporter = (r.reportedUser.sex === 'male') ? ' user-male ' : ' user-female ';
    const sexClassUser = (r.user.sex === 'male') ? ' user-male ' : ' user-female ';
    return (
        <>
            {showPopup && (
                <Modal isOpened={showPopup} onClose={() => setShowPopup(false)}>
                    <PopupView data={r} onRemoveItem={removeItemHandler} />
                </Modal>
            )}
            {showDeclinePopup && (
                <Modal isOpened={showDeclinePopup} onClose={() => setShowDeclinePopup(false)}>
                    <PopupDeclineView data={r} onRemoveItem={removeItemHandler} />
                </Modal>
            )}
            <tbody>
            <tr>
                <td>
                    <div style={{width: '75px', marginBottom: '3px'}}>{aggDateFromTimestamp(r.report.createdAt)}</div>
                </td>
                <td>
                    <div className="image-wrapper">
                        <img src={aggPhoto(r.user.photoUrl, 'thumb')} className={`table-photo ${isBlockedReporter}`} alt="user" />
                        <span onClick={() => onAction('filter', {userId: r.user.id})} className="report-count" title="report count">{r.report.reportersReportCount}</span>
                        {r.user.level === 2 && <i className="fa fa-star premium" aria-hidden="true"></i>}
                    </div>
                    <div><a href={aggProfileUrl(r.user.id)} target="blank" rel="noreferrer" className={`table-links ${sexClassUser}`}>{r.user.name}</a></div>
                </td>

                <td>
                    <EditTypes report={r.report} />
                    <div className="table-links" onClick={() =>{setShowConversation(!showConversation)}}>
                        {r.report.comment ? r.report.comment : 'Conversation'}</div>
                    <br/>
                    <Attachments list={r.attachments} />
                </td>
                <td style={{position: 'relative'}}>
                    <div className="image-wrapper">
                        <img className={`table-photo ${isBlocked}`} src={aggPhoto(r.reportedUser.photoUrl, 'thumb')} alt="reported user" />
                        <span onClick={() => onAction('filter', {userId: r.reportedUser.id})} className="report-count" title="report count">{r.report.count}</span>
                        {r.reportedUser.level === 2 && <i className="fa fa-star premium" aria-hidden="true"></i>}
                    </div>
                    <a href={aggProfileUrl(r.reportedUser.id)} target="blank" rel="noreferrer" className={`table-links ${sexClassReporter}`}>{r.reportedUser.name}</a>
                    <span>
                        {(r.reportedUser.isVerified) && <strong className="green-text"> [V] </strong>}&nbsp;
                        {r.reportedUser.warningAboutEscort && <span className="warning-about-escort" title="Marked as displayed prices user">$</span>}
                    </span>
                </td>
                <td>
                    {updateSex === 'female' && r.report.status === 'n' &&
                        <>
                            <button className="green-button-simple" onClick={onConfirm}>Confirm</button>
                            <button className="red-button" onClick={onDecline}>Decline</button>
                        </>
                    }
                    {updateSex === 'male' && r.report.status === 'n' &&
                        <>
                            <button className="green-button-simple" style={{marginBottom: '5px'}} onClick={() => setShowPopup(true)}>Confirm</button>
                            <button className="red-button" onClick={() => setShowDeclinePopup(true)}>Decline</button>
                            <div className="table-links dark-grey-text ignore-user" onClick={onIgnoreReporterHandler}>Ignore reporter</div>
                        </>
                    }
                    {r.report.status === 'y' && <span className="green">Confirmed</span>}
                    {r.report.status === 'd' && <span className="red-text">Declined</span>}
                </td>
                <td>
                    <div>
                        <em>{r.reportedUserComment}</em>
                    </div>
                    {updateSex === 'female' &&
                        <>
                            <div className="add-to-unwanted table-links"><span onClick={onAddToUnwanted}> Add to unwanted </span></div>

                            {!r.reportedUser.warningAboutEscort && (
                                <div className="add-to-displayed-prices-action table-links">
                                    <span onClick={onSendWarning}>Displayed prices</span>
                                </div>
                            )}
                        </>
                    }
                </td>
            </tr>
            {showConversation && (<tr><td colSpan="7"><ConversationItem user1={r.user} user2={r.reportedUser} /></td></tr>)}
            </tbody>
        </>
    );
}

const EditTypes = ({report}) =>  {
    const [type, setType] = useState(+report.typeId);
    const sex = useQuery().get('sex');
    const reasonList = (sex === 'male') ? reasonListFemale : reasonListMale;

    const onChange = (e) => {
        e.preventDefault();
        const selectedId = e.target.value;
        const newType = reasonList.find(i => i.id === parseInt(selectedId)).id;

        setType(newType);
        adminApi.post('reports/change_type', {reportId: report.id, reportType: selectedId});
    }

    return (
        <Fragment>
            <select style={{marginBottom: '5px'}} defaultValue={reasonListAll.find(element => element.id === type).id} onChange={onChange}>
                {reasonList.map(l => <option key={l.id} value={l.id}>{l.value}</option>)}
            </select>
        </Fragment>
    );
}

const PopupView = ({data, onRemoveItem}) => {
    const [politenessScore, setPolitenessScore] = useState(1);
    const [currentPoliteness, setCurrentPoliteness] = useState(data.politeness);
    const [currentPolitenessMax, setCurrentPolitenessMax] = useState(data.politenessMax);
    const [unwantedUser, setUnwanteddUser] = useState(true);
    const [sexistUser, setSexistUser] = useState(data.isSexist);
    const [comment, setComment] = useState(data.reportedUserComment);
    const [updatedComment, setUpdatedComment] = useState(false);
    const cp = data.politeness;

    const changeScore = (e) => {
        setPolitenessScore(e.target.value);
        if (cp - e.target.value >= 0) {
            setCurrentPoliteness(cp - e.target.value);
            let curPoints = cp - e.target.value;
            let newCuiling = (20 - Math.floor( (20 - curPoints) / 3) );

            setCurrentPolitenessMax(newCuiling);
        }
    }

    const changeUnwantedStatus = (e) => {
        setUnwanteddUser(e.target.checked);
    }

    const changeSexistStatus = (e) => {
        setSexistUser(e.target.checked);
    }

    const updateComment = (e) => {
        setComment(e.target.value);
        setUpdatedComment(true);
    }

    const onConfirmMale = () => {
        let postData = {
            id: data.report.id,
            userId: data.reportedUser.id,
            reporterId: data.user.id,
            action: 'confirm',
            score: politenessScore,
            unwantedUser: unwantedUser,
            sexistUser: sexistUser,
            comment: comment
        }

        let updateData = {id: data.reportedUser.id, isSexist: sexistUser};
        adminApi.post('reports/check', {id: data.report.id});

        if (comment && updatedComment) {
            adminApi.post('users/update_comment', {comment: comment, userId: data.reportedUser.id, type: 'report'});
            updateData.comment = comment;
        }
        if (unwantedUser) {
            adminApi.post('users/add_to_unwanted_list', {unwantedUserId: data.reportedUser.id, userId: data.user.id});
        }
        adminApi.post('reports/log_action', postData).then(r => {
            updateData.score = r.data.score;
            updateData.maxScore = r.data.maxScore;
            onRemoveItem(data.report.id, updateData);
        });
    }

    return (
        <div className="popup-view">
            <div className="popup-line">
                Current score: <span className="red-text">{currentPoliteness}</span>
                <div className="ceiling">{currentPolitenessMax}</div>
            </div>
            
            <div className="popup-line">
                <div className="score-label">Points: </div>
                <select onChange={changeScore}>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
            <div className="popup-line">
                <textarea value={comment ? comment : ''} onChange={updateComment} placeholder="comment"></textarea>
            </div>
            <div className="popup-line">
                <input type="checkbox" id="unwanted" defaultChecked={unwantedUser} onChange={changeUnwantedStatus} />
                <label htmlFor="unwanted">Hide from reporter</label>
                <input type="checkbox" id="sexist" defaultChecked={sexistUser} onChange={changeSexistStatus} style={{marginLeft: '35px'}} />
                <label htmlFor="sexist">Sexist</label>
            </div>

            <button className="green-button-simple" onClick={onConfirmMale}>Confirm</button>
        </div>
    );
}

const PopupDeclineView = ({data, onRemoveItem}) => {
    const [unwantedUser, setUnwanteddUser] = useState(false);
    const [sexistUser, setSexistUser] = useState(false);

    const changeUnwantedStatus = (e) => {
        setUnwanteddUser(e.target.checked);
    }

    const changeSexistStatus = (e) => {
        setSexistUser(e.target.checked);
    }

    const onDeclineMale = () => {
        let postData = {
            id: data.report.id,
            userId: data.reportedUser.id,
            reporterId: data.user.id,
            action: 'decline',
            unwantedUser: unwantedUser,
            sexistUser: sexistUser
        }
        let updateData = {id: data.reportedUser.id, isSexist: sexistUser};
        adminApi.post('reports/log_action', postData);
        adminApi.post('reports/delete', {id: data.report.id});

        if (unwantedUser) {
            adminApi.post('users/add_to_unwanted_list', {unwantedUserId: data.reportedUser.id, userId: data.user.id});
        }

        onRemoveItem(data.report.id, updateData);
    }

    return (
        <div className="popup-view">
            <div className="popup-line">Current score: <span className="red-text">{data.politeness}</span></div>
            <div className="popup-line">
                <input type="checkbox" id="unwanted" defaultChecked={unwantedUser} onChange={changeUnwantedStatus} />
                <label htmlFor="unwanted">Add to unwanted list</label>
                <input type="checkbox" id="sexist" defaultChecked={sexistUser} onChange={changeSexistStatus} style={{marginLeft: '25px'}} />
                <label htmlFor="sexist">Sexist</label>
            </div>

            <button className="red-button" onClick={onDeclineMale}>Decline</button>
        </div>
    );
}

export default Reports;
