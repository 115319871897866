const defaultMessage = 'Hello %name%,\n' +
    '\n' +
    'Best regards,\n' +
    'Justina';

export const getMessage = (name) => defaultMessage.replace('%name%', name);

export const messageTemplates = [
    {id: 'blocked', title: 'Blocked', content: 'Hello %name%,\n\nWe suspend members if we get a report about their fake pictures, impolite behaviour, suspicion about them being a scammer or an escort. Sometimes the\ngirls can get unsuspended if they verify that their pictures are real.\n\nBest regards,\nJustina'},
    {id: 'paying', title: 'Paying', content: 'Hello %name%,\n\nYou can pay by credit card. Simply go to the "Membership" section and choose a payment processor.\n\nBest regards,\nJustina'},
    {id: 'cancel', title: 'Cancel recurring', content: 'Hello %name%,\n\nYou can buy a membership and then cancel the recurring payments, so you won\'t be charged again after your current membership expires. This option will\nappear at the "Membership" section as soon as you buy a membership. \n\nOr you can tell me and I will cancel it for you.\n\nBest regards,\nJustina'},
    {id: 'remove_acc', title: 'Remove account', content: 'Hello %name%,\n\nSimply go to the "My profile" section and you will find the "Remove account" option at the bottom of the page.\n\nBest regards,\nJustina'},
    {id: 'read_msg', title: 'Read Message', content: 'Hello %name%,\n\nYou have to upgrade your membership to be able to read and send messages.\n\nBest regards,\nJustina'},
    {id: 'trial', title: 'Free trial', content: 'Hello %name%,\n\nSorry, but we can\'t offer trial access to our members.\n\nBest regards,\nJustina'},
    {id: 'browse_members', title: 'Browse Members', content: 'Hello %name%,\n\nPlease use "Browse Members" option to find people you want to contact. \n\nBest regards,\nJustina'},
    {id: 'subscription_canceled', title: 'Sub cancelled', content: 'Hello %name%,\n\nYour subscription has been successfully canceled.\n\nBest regards,\nJustina'},
    //{id: 'verification', title: 'Verification', content: 'Hello %name%,\n\nThere are two ways to verify your profile. Please go to the "Profile Verification" section and either:\n\nTake a photo of yourself holding a piece of paper with “travelgirls” written on it.\n\nTake a photo of yourself with a webcam. \n\nBest regards,\nJustina'},
    {id: 'cc_failure', title: 'CC Failure', content: 'Hello %name%,\n\nPlease contact the payment processor for the reason why your credit card is being rejected, as we are not provided with any such information.\n\nBest regards,\nJustina'},
    {id: 'photos', title: 'Photos', content: 'Hello %name%,\n\nThe uploaded image file format must be jpg or png. Minimum size is 400x400 px and maximum size is 12 MB.\n\nBest regards,\nJustina'},
    {id: 'purchase_info', title: 'Purchase Info', content: 'Hello %name%,\n\nPlease provide me with a purchase ID or a transaction ID and the name of our payment processor you have paid through.\n\nBest regards,\nJustina'},
    {id: 'cookies', title: 'Cookies', content: 'Hello %name%,\n\nPlease try deleting cookies and cache in your browser (https://support.google.com/accounts/bin/answer.py?hl=en&answer=32050). We recommend using another browser as well. Let me know if you are still experiencing this problem.\n\nBest regards,\nJustina'},
    {id: 'cancelation', title: 'Cancellation', content: 'Hello %name%,\n\nI will cancel the recurring payments, so you will not be charged again.\n\nIn terms of feedback, can you please provide us some reasons for canceling your subscription? \n\nBest regards,\nJustina'},
    {id: 'site_desc', title: 'Site description', content: 'Hello %name%,\n\nOur goal is to connect people with passion for travel from all around the world. Most of them seek a traveling partner and exchange their traveling\nexperiences while others look for a job or specific adventures. Usually girls expect to travel, but sometimes they expect foreign\ntravelers to visit their country. We recommend communicating with members individually to find out what they are looking for.\n\nBest regards,\nJustina'},
    {id: 'forwarded_msg', title: 'Message forwarded', content: 'Hello %name%,\n\nI have forwarded your message to the responsible person and I will contact you as soon as I get any news.\n\nBest regards,\nJustina'},
    {id: 'pay', title: 'Why to pay', content: 'Hello %name%,\n\nAs you can see we have no advertisements or pop-ups on our site, we don\'t make money out of that, at the same time we invest a lot to attract new members. We could not exist without income that is collected from premium members. That\'s why you have to pay if you want to read and send messages. \n\nBest regards,\nJustina'},
    {id: 'escort_activity', title: 'Escort activity', content: 'Hello %name%,\n\nRecently I received a report from another member about you. Please understand that our site is not a platform for escort activity. The site is designed for relaxation not for work.\n\nBest regards,\nJustina'},
    {id: 'spam_msg', title: 'Spam messages', content: 'Hello %name%,\n\nPlease note that it is not allowed to spam contact information to other users. I kindly ask you to stop doing that, otherwise I will have to block your account.\n\nBest regards,\nJustina'},
    {id: 'igore_conv', title: 'Ignore converation', content: 'Hello %name%,\n\nRecently I received a report from another member about you. Impolite behavior is not acceptable on our site, so please use the "Ignore conversation" option if you don‘t want to talk with a member. \n\nBest regards,\nJustina'},
    {id: 'impolite', title: 'Impolite behavior', content: 'Hello %name%,\n\nPlease note that there is one very important rule to follow when using our platform.\n* The use of impolite or offensive language is prohibited in any situation.\n\nIf you were insulted or angered by another member, please submit a report to us.\nRespect and courtesy are the bedrocks of communication on our platform.\n\nThank you for your understanding, and best wishes on your travels.\nJustina'},
    //{id: 'photo_reject', title: 'Photo rejected', content: 'Hello %name%,\n\nYour verification photo has been rejected, because your face is not clearly visible in profile photo(s). Please upload at least one picture with your face clearly visible in it. \n\nBest regards,\nJustina'},
    {id: 'tf_description', title: 'TF description', content: 'Hello %name%,\n\nOur goal is to help people find friends and potential travel matches from all over the world.\nOur website is a perfect place to find a companion if you are planning a breathtaking adventure or simply a spontaneous weekend getaway and have nobody to accompany you. It\'s all about meeting new people and exploring new places together. Who knows, maybe you will end up meeting your soulmate or someone who would change your life for good.\n\nBest regards,\nJustina'},
    {id: 'tf_cancel', title: 'TF Cancel', content: 'Hello %name%,\n\nIf you want to cancel the auto renewal payments, simply go to the "Premium account" section, click on the unsubscribe link and follow instructions.\n\nIn terms of feedback, can you please provide us some reasons for canceling your subscription? \n\nBest regards,\nJustina'},
    {id: 'tf_delete', title: 'TF delete acc.', content: 'Hello %name%,\n\nSimply go to the menu "My profile" section and you will find the "Delete Account" option at the bottom of the page.\n\nBest regards,\nJustina'},
    {id: 'app_cancel', title: 'App cancel', content: 'Hello %name%,\n\nPlease follow the instructions on https://support.apple.com/en-us/HT202039 if you want to cancel the subscription.\n\nBest regards,\nJustina'},
    {id: 'app_refund', title: 'App refund', content: 'Hello %name%,\n\nYou have to apply for a refund directly via your Apple ID account as we do not manage payments made through the AppStore.\nPlease visit http://www.imore.com/how-to-get-refund-itunes-app-store for detailed instructions on how to get a refund.\n\nBest regards,\nJustina'},
    {id: 'report', title: 'Report', content: 'Hello %name%,\n\nRecently I received your report about the member (NAME). Could you please send me a screenshot of your conversation where she is offering escort services?\n\nBest regards,\nJustina'},
];
