import {Fragment, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import st from "./inbox.module.scss";
import useQuery from "../../helpers/useQuery";
import aggSquarePhoto from "../../helpers/photos";
import {aggProfileUrl, aggChatUrl} from "../../helpers/profile";
import {timeAgo} from "../../helpers/dates";
import adminApi from "../../api/admin";
import {resetConversations, getConversations} from "../../store/conversations";
import ButtonLink from "../../common/ButtonLink/ButtonLink";
import AdminFavorites from "../../common/AdminFavorites/AdminFavorites";

const typeList = ['inbox', 'starred', 'trash', 'unread'];
const aggNewQueryUrl = (type, name = '') => {
    const params = {};

    if (type) {
        params['type'] = type;
    }

    if (name && name.length > 0) {
        params['name'] = name;
    }

    return new URLSearchParams(params).toString();
}

const Inbox = () => {
    const dispatch = useDispatch();
    const nav = useNavigate();
    const type = useQuery().get('type');
    const name = useQuery().get('name');

    const [nameInput, setNameInput] = useState(name ? name : '');
    const [lastName, setLastName] = useState(nameInput);
    const {isInitiated, isLoading, list, hasMore, inboxType} = useSelector(state => state.conversations);

    // Init converstations
    useEffect(() => {
        dispatch(resetConversations(type, name))
    },[type, name]
    );

    // Reset conversations on name change
    useEffect(() => {
        const timerId = setTimeout(() => {
            if (lastName !== nameInput) {
                setLastName(nameInput);
                nav('/conversations?'+aggNewQueryUrl(type, nameInput));
            }
        }, 1000);

        return () => {
            clearTimeout(timerId);
        }
    }, [nameInput]);

    const onTypeChange = (item) => {
        if (item !== type) {
            nav('/conversations?'+aggNewQueryUrl(item, name));
        }
    }

    return (
        <Fragment>
            <h1 className={st.Title}>Conversations</h1>
            <div className={st.FilterArea}>
                <div className={st.Tabs}>
                    {typeList.map(n => <button key={n} className={n === type ? st.Selected : null} onClick={() => onTypeChange(n)}>{n}</button>)}
                </div>
                <input
                    className={st.SearchInput}
                    type="text"
                    placeholder={"Search by name ..."}
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                />
            </div>
            {list.length === 0 && isLoading && <div className="loader"/>}
            {list.length === 0 && !isLoading && <div className={st.EmptyList}>List is empty.</div>}
            {list.length > 0 && (
                <div>
                    <div>
                        {list.map((c, key) => {
                            return <Item key={key} data={c} type={type} />;
                        })}
                    </div>
                    {hasMore && (
                        <div className={st.LoadMoreArea}>
                            {isLoading
                                ? <div className="loader"/>
                                : (<button className="green-button" onClick={() => dispatch(getConversations())}>Show more</button>)}
                        </div>
                    )}
                </div>)
            }
        </Fragment>
    );
}

const Item = ({data, type}) => {
    const {user, favoritedAdminId, isUnread, isReplied, message} = data;
    const prefix = user.isBlocked ? 'blocked' : user.isHidden ? 'hidden' : null;
    const [isDeleted, setDeleted] = useState(false);
    const [forceHide, setForceHide] = useState(false);

    const onDelete = () => {
        setDeleted(true);
        adminApi.post('conversations/delete_conversation/'+user.id);
    }

    const onRestore = (hideOnRestore = false) => {
        setDeleted(false);
        if (hideOnRestore) {
            setForceHide(true);
        }
        adminApi.post('conversations/restore_conversation/'+user.id);
    }

    if (isDeleted) {
        return (
            <div className={[st.Item, st.ItemDeleted].join(' ')}>
                <span>Deleted conversation with <b>{user.name}</b>.</span><ButtonLink onClick={onRestore}>Restore</ButtonLink>
            </div>
        );
    }

    if (forceHide) {
        return  null;
    }

    const classList = [st.Item];
    if (isUnread) {
        classList.push(st.Unread);
    }
    else if (user && user.app === 'tm') {
        classList.push(st.FromTravelApp);
    }
    else if (user && user.app === 'tf') {
        classList.push(st.FromTravelfriend);
    }

    return (
        <div className={classList.join(' ')}>
            <a className={st.buttonUser} href={aggProfileUrl(user.id)}>
                <div className={st.RepliedArea}>{isReplied && <i className="fa fa-reply" />}</div>
                <div className={st.PhotoArea}><img src={aggSquarePhoto(user.photo, 100, user)} /></div>
                <div className={st.UserInfoArea}>
                    {user.name && (
                        <Fragment>
                        <span className={user.isOnline ? st.Online : [st.Offline, st.Online].join(' ')}>
                            <i className="fa fa-circle" />
                        </span>
                        <span className={st.Name}>{user.name}</span>
                        </Fragment>
                    )}
                    {!user.name && <span style={{color: '#666'}}>Deleted account</span>}
                    {prefix && <span className={st.Status}>{prefix}</span>}
                </div>
            </a>
            <AdminFavorites favoritedAdminId={favoritedAdminId} userId={user.id} />
            <a href={aggChatUrl(user.id)} className={st.ContentArea}>{message.content}</a>
            <div className={st.TimeAgoArea}>{timeAgo(message.sentAt)}</div>
            <div className={st.DeleteArea}>
                {type === 'trash' && <button onClick={() => onRestore(true)}><i className="fa fa-pencil" title={'Restore'} /></button>}
                {type !== 'trash' && <button onClick={onDelete}><i className="fa fa-trash" /></button>}
            </div>
        </div>
    );
}

export default Inbox;
