const emoticonList = {
    ':)': '🙂',
    ';)': '😉',
    '(blush)': '😊',
    ':D': '😀',
    '(cool)': '😎',
    '(laugh)': '😀',
    '(kiss)': '😘',
    '(love)': '😍',
    '(rolleyes)': '🙄',
    ':p': '😛',
    '(angel)': '😇',
    '(amazed)': '😨',
    '(angry)': '😡',
    ':(': '🙁',
    '(dazed)': '🤪',
    '(wave)': '👋',
    '(clap)': '🤗',
    '(chuckle)': '😊',
    '(rofl)': '🤣'
};

const textToEmoticons = (text) => {
    for (const key in emoticonList) {
        text = text.split(key).join(emoticonList[key]);
    }
    return text;
}


// Handlebars escapeExpression function and constants
const badChars = /[&<>"'`=]/g;
const possible = /[&<>"'`=]/;
const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;'
};

const escapeChar = chr => escape[chr];
const escapeExpression = (string) => {
    if (typeof string !== 'string') {
        if (string && string.toHTML) {
            return string.toHTML();
        } else if (string === null) {
            return '';
        } else if (!string) {
            return string + '';
        }
        string = '' + string;
    }

    if (!possible.test(string)) {
        return string;
    }

    return string.replace(badChars, escapeChar);
}

export const aboutMeText = (text) => {
    text = escapeExpression(text);
    // Replace new lines to br
    return text.replace(/(?:\r\n|\r|\n)/g, '<br />');
}