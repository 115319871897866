import { useEffect, useState } from 'react';
import apiAdmin from "../../api/admin";

const FemalePayments = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getData = () => {
        setIsLoading(true);     

        apiAdmin.get('finance/female_statistics').then((r) => {
            setList(current => [...current, ...r.data]);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
            <h1>Female payment statistics</h1>

            <table className="mt-10 dimensionsTable">
                <thead>
                    <tr>
                        <th></th>
                        <th colSpan={4}>Diamond</th>
                        <th colSpan={3}>Advanced</th>
                        <th></th>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <th>Trial</th>
                        <th>Diamond</th>
                        <th>Active</th>
                        <th>Money</th>
                        <th>Advanced</th>
                        <th>Active</th>
                        <th>Money</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                {list && 
                list.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.date}</td>
                            <td>{item.trial}</td>
                            <td>{item.purchaseDiamond}</td>
                            <td>{item.activeDiamond}</td>
                            <td>{item.totalDiamond}€</td>
                            <td>{item.purchaseAdvanced}</td>
                            <td>{item.activeAdvanced}</td>
                            <td>{item.totalAdvanced}€</td>
                            <td>{item.total}€</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default FemalePayments;
