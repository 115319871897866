import { useState } from "react";
import { useEffect } from "react";

import adminApi from "../../api/admin";
import Autocomplete from "../../common/Autocomplete/Autocomplete";
import { aggPhoto } from "../../helpers";
import { countryNameByCode } from "../../helpers/lang";
import { aggProfileUrl } from "../../helpers/profile";

const Gallery = () => {
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [loadedUsers, setLoadedUsers] = useState([]);
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        getGalleryUsers();
    }, [])

    const getGalleryUsers = (data, newList = true) => {
        setLoading(true);
        if (newList) {
            setList([]);
        } else {
            data.loadedUserIds = loadedUsers;
        }

        adminApi.post('gallery', data).then(r => {
            setLoading(false);
            if (r.data.hasMore) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }

            setList(prevList => {
                return [...prevList, ...Object.values(r.data.users)];
            });  
            setLoadedUsers(prevLoadedList => {
                return [...prevLoadedList, ...r.data.users.map(u => u.id)];
            });        
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        setLoadedUsers([]);
        
        const data = {
            userId: e.target.userId.value ? e.target.userId.value : null,
            name: e.target.name.value ? e.target.name.value : null,
            email: e.target.email.value ? e.target.email.value : null,
            age: e.target.age.value ? e.target.age.value : null,
            sex: e.target.sex.value
        };
        const newFilter = {...filter, ...data};
        setFilter(newFilter);
        getGalleryUsers(newFilter);
    }

    const onLocationSelect = (location) => {
        const dataToUpdate = {
            'locationId': (location) ? location.locationId : null,
            'locationName': (location) ? location.locationName : '',
            'locationType': (location) ? location.locationType : null
        }

        setFilter(prevFilter => ({...prevFilter, ...dataToUpdate}));
    }

    return (
        <div className="gallery">
            <h1>Gallery</h1>
            <form onSubmit={onSubmit} className="filters">
                <input name="userId" placeholder="User ID" style={{width: '75px'}} />
                <Autocomplete onLocationSelect={onLocationSelect} query={filter.locationName} />
                <input name="name" placeholder="Name" style={{width: '80px'}} />
                <input name="email" placeholder="Email" />
                <input name="age" placeholder="Age" style={{width: '35px'}} />
                <select name="sex">
                    <option value="female">Female</option>
                    <option value="male">Male</option>
                    <option value="all">Both</option>
                </select>
                <button type={"submit"}>Filter</button>
            </form>
            <ul>
                {list &&
                    list.map((user, i) => {
                        return (
                            
                            <li key={user.id}>
                                <a className="photo action-open-profile" href={aggProfileUrl(user.id)}>
                                    <img src={aggPhoto(user.photoUrl, 'thumb')} alt="users face" />
                                    {user.isOnline && <span className="online">On</span>}
                                </a>

                                <div className="details">
                                    <h2>{user.name} <span className="age">{user.age}</span></h2>
                                    <div className="country">{countryNameByCode(user.location.countryCode)}</div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
            {list && list.length === 0 && 
                <div>No users found.</div>
            }
            {!isLoading && hasMore && <button className="green-button load-more" onClick={() => getGalleryUsers(filter, false)}>Load more</button>}
            {isLoading && <div className="loader mt-20"/>}
        </div>
    );
}

export default Gallery;