import {useState} from "react";


import {aggPhoto} from "../../helpers";
import apiAdmin from "../../api/admin";
import {aggProfileUrl} from "../../helpers/profile";


const Reporters = () => {
    const [isLoading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    const onSearch = (e) => {
        e.preventDefault();
        setList([]);
        setLoading(true);

        apiAdmin.post('reports/get_user_reported_girls', {userId: e.target.userId.value}).then(r => {
            setLoading(false);
            setList(r.data);
        }) ;
    };

    return (
        <div className='user-search'>
            <h1>Reporters search</h1>
            <form className="user-search-form" onSubmit={onSearch}>
                Member ID: <input name="userId" className="ml-5" />
                <button type={"submit"} className="ml-5">Find all reported girls</button>
            </form>

            {isLoading && <div className="loader"/>}
            {!isLoading && list.length > 0 && <List list={list} />}
        </div>
    );
}

const List = ({list}) => {
    return (
        <div>
            <h3>All reported girls: </h3>
            <table>
                <thead>
                <tr>
                    <th style={{width: '20px'}}>Sex</th>
                    <th style={{width: '40px'}}>ID</th>
                    <th style={{width: '50px'}}>Photo</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th/>
                    <th/>
                </tr>
                </thead>
                <tbody>
                {list.map((u, i) => (
                        <tr key={i}>
                            <td className={u.sex}>{(u.sex === 'male') ? 'M' : 'F'}</td>
                            <td>{u.id}</td>
                            <td><img src={aggPhoto(u.photoUrl, 'thumb')} className="user-search-photo" alt="user" /></td>
                            <td><a href={aggProfileUrl(u.id)} className="table-links">{u.name}</a></td>
                            <td>{u.email}</td>
                            <td className={u.isBlocked ? 'blocked' : ''}>{u.isBlocked ? 'blocked' : ''}</td>
                            <td className={u.isHidden ? 'hidden' : ''}>{u.isHidden ? 'hidden' : ''}</td>
                        </tr>
                    )
                )}
                </tbody>
            </table>
        </div>
    )
}

export default Reporters;
