import Classes from './Modal.module.scss';
import {useEffect} from "react";

const Modal = ({children, isOpened, disabledClose = false, onClose}) => {
    useEffect(() => {
        document.body.style.overflow = isOpened ? 'hidden' : 'unset';
        return () => {
            document.body.style.overflow = 'unset';
        }
    }, [isOpened])

    if (!isOpened) {
        return null;
    }

    const onCloseInitAction = () => {
        document.body.style.overflow = 'unset';
        onClose();
    }

    const containerClassList = [Classes.ModalContainer];
    const modalClassList = [Classes.Modal];

    return (
        <div className={modalClassList.join(' ')}>
            <div className={Classes.ModalWrapper}>
                <div className={Classes.ModalWindow}>
                    {!disabledClose && (<div className={Classes.ModalClose} onClick={onCloseInitAction}>Close</div>)}
                    <div className={containerClassList.join(' ')}>{children}</div>
                </div>
            </div>
        </div>
    );
}

export default Modal;
