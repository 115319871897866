import {Route, Routes } from "react-router-dom";
import {useEffect} from "react";

const RedirectPage = () => {
    useEffect(() => {
        window.location.href = process.env.REACT_APP_MP;
    }, []);
}
const Home = () => (<></>);

const Landing = () => {
  return (
      <Routes>
          <Route path={(process.env.REACT_APP_MP+'/*')} element={<Home />} />
          <Route path={'*'} element={<RedirectPage />} />
      </Routes>
  );
}

export default Landing;

