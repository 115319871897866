import { useRef, useState } from 'react';

import apiAdmin from "../../api/admin";

const Cancel = () => {
    const ref = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [canceledId, setCanceledId] = useState(null);
    const [error, setError] = useState(null);

    const cancelHandler = (e) => {
        e.preventDefault();
        setError(null);
        setCanceledId(null);
        
        if (isLoading) return;
        if (e.target.subscription.value.length === 0) {
            return;
        }

        setIsLoading(true);
        apiAdmin.post('payments/cancel/', {subId: e.target.subscription.value}).then((r) => {
            if (r.data.cancel === true) {
                setCanceledId(e.target.subscription.value);
            } else {
                if (r.data.error) {
                    setError(r.data.error);
                } else {
                    setError('Failed to cancel subscription. Or already canceled.');
                }
            }
        }).catch(error => {
            setError(error.response.data.error);
        }).finally(() => {
            setIsLoading(false);
            ref.current.value = '';
        });
    }

    return (
        <div className='about-me'>
            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <h1>User subscription cancelation</h1>
            </div>

            <form onSubmit={cancelHandler} className="user-search-form">
                Subscription ID:
                <input name="subscription" ref={ref} type="text" />
                <button type="submit">Cancel subscription</button>
            </form>
            <div>You can cancel only Stripe, Segpay and CCBill subsriptions!</div><br />

            {error && <div className='red'><b>{error}</b></div>}
            {canceledId && <div className='green'><b>{canceledId}</b> Was canceled successfully!</div>}
            
        </div>
    )
}

export default Cancel;
