import {useState} from "react";

import Button from "../../common/Button/Button";
import Classes from "./Login.module.scss";
import adminApi from "../../api/admin";
import {setAuthData} from "../../helpers/authentication";
const Login = () => {
    const [error, setError] = useState(null);
    const onSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: e.target.email.value,
            password: e.target.password.value
        }

        adminApi.post('auth/login', data).then(r => {
            setAuthData(r.data.adminId, r.data.token);
            window.location.reload();
        }).catch(err => {
            setError('Wrong login credentials');
        });
    }

    return (
        <div className={"flexCentered"}>
            <form className={Classes.Form} onSubmit={(e) => onSubmit(e)}>
                <h1>Admin panel</h1>

                {error && <div className={"error-default"}>{error}</div>}

                <input type="email" name={"email"} defaultValue="" placeholder={"Email"} autoComplete={"email"} />
                <input type="password" name={"password"} defaultValue="" placeholder={"Password"} autoComplete={"current-password"} />
                <Button>Login</Button>
            </form>
        </div>
    );
}
export default Login;
