import { useEffect, useState } from 'react';

import apiAdmin from "../../api/admin";
import {aggPhoto, textHighlighter} from "../../helpers";
import {aggProfileUrl} from "../../helpers/profile";

const SuspiciousTrips = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [tripData, setTripData] = useState({});
    const [tripDescription, setTripDescription] = useState("");

    const getList = () => {
        apiAdmin.get('suspicious/suspicious_trips').then((r) => {
            setList(r.data);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getList();
    }, []);

    const updateTrip = (tripId, description) => {
        let data = {
            id: parseInt(tripId),
        };
        data.description = (description) ? description : null;
        apiAdmin.post('suspicious/update_suspicious_trip', data).then((r) => {
            getList();
        })
    };

    return (
        <div>
            <h1>Suspicious Trips</h1>

            <table>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Travelling to</th>
                    <th>Trip note</th>
                    <th></th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                { list && list.map((trip, i) => {
                    let sexFieldClass = (trip.user.sex === 'male') ? 'male' : 'female';
                    let sexFieldContent = (trip.user.sex === 'male') ? 'M' : 'F';
                    let description = textHighlighter(trip.trip.description, trip.words);
                    let showEdit = (tripData && description.length > 0 && tripData.id === trip.trip.id) ? true : false;
                    let editHtml = <div dangerouslySetInnerHTML={{__html: description}}/>;
                    if (showEdit) {
                        console.log(trip.id);
                        editHtml = (<span>
                        <textarea
                            value={tripDescription}
                            style={{ width: '98%'}}
                            onChange={(e) => {
                                setTripDescription(e.target.value)
                            }}
                        />
                        <button onClick={() => updateTrip(trip.trip.id, tripDescription)}>Save</button>
                    </span>);}
                    return (
                        <tr key={i}>
                            <td style={{width: '60px'}}>
                                <img src={aggPhoto(trip.user.photoUrl, 'thumb')} className="user-search-photo" alt="user" />
                                <br/>
                                <a href={aggProfileUrl(trip.user.id)} className="table-links">{trip.user.name}</a>
                                <span className={sexFieldClass}> {sexFieldContent} </span>
                            </td>
                            <td style={{width: '15%'}}>{trip.trip.location}</td>
                            <td>
                            {editHtml}
                            </td>
                            <td style={{width: '10%'}}>
                                <span
                                    className="table-links"
                                    onClick={() => {
                                        setTripData(trip.trip);
                                        setTripDescription(trip.trip.description);
                                    }}
                                >
                                    Change
                                </span>
                            </td>
                            <td style={{width: '15%'}}>
                                <button className="green-button pointer" onClick={() => {updateTrip(trip.trip.id, false)}}> Confirm </button>
                            </td>
                        </tr>
                    )})
                }
                </tbody>
            </table>
            {isLoading && <div className="loader" />}
        </div>
    );
}

export default SuspiciousTrips;
