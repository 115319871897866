import { useState } from 'react';

import apiAdmin from "../../api/admin";
import TranslatedText from "../../common/TranslatedText/TranslatedText";
import aggSquarePhoto from '../../helpers/photos';
import {aggProfileUrl} from "../../helpers/profile";

const AboutMeItem = ({user, onConfirmAboutMe}) => {
    const [confirmPressed, setConfirmPressed] = useState(false);
    const [showInIos, setShowInIos] = useState(true);
    const [showEdit, setShowEdit] = useState(false);
    const [name, setName] = useState(null);
    const [aboutMe, setAboutMe] = useState(null);
    const [sex, setSex] = useState(null);
    const [translate, setTranslate] = useState(false);
    const [warningMessageSent, setWarningMessageSent] = useState(false);
    const [warningMessageEscortSent, setWarningMessageEscortSent] = useState(false);

    let sexFieldClass = (user.sex === 'male') ? 'male' : 'female';
    let sexFieldContent = (user.sex === 'male') ? 'M' : 'F';

    const confirmAboutMeHandler = (userId) => {
        setConfirmPressed(true);

        let postData = {userId: userId, showInIos: showInIos};
        if (name) postData.name = name;
        if (sex) postData.sex = sex;
        if (aboutMe) postData.about_me = aboutMe;

        apiAdmin.post('updates/confirm_about_me', postData).then((r) => {
            setConfirmPressed(false);
            setShowEdit(false);
            setShowInIos(true);
            setAboutMe(null);
            setName(null);
            setSex(null);
            setTranslate(false);
            setWarningMessageSent(false);
            setWarningMessageEscortSent(false);
            onConfirmAboutMe(userId);
        });
    }

    const changeSelectedSex = (event) => {
        setSex(event.target.value);
    }

    const changeNameHandler = (event) => {
        setName(event.target.value)
    }

    const changeAboutMeHandler = (event) => {
        setAboutMe(event.target.value)
    }

    const sendWarningMessage = () => {
        if (window.confirm('Are you sure you want to send message about contacts on profile?')) {
            apiAdmin.post('updates/warning_message', {userId: user.id, type: 'contacts_on_profile'}).then((r) => {
                setWarningMessageSent(true);
            });
        }
    }

    const sendWarningMessageNotice = () => {
        if (window.confirm('Are you sure you want to send message about escort?')) {
            apiAdmin.post('updates/warning_message', {userId: user.id, type: 'notice'}).then((r) => {
                setWarningMessageEscortSent(true);
            });
        }
    }

    return (
        <tr>
            <td style={{width: '100px'}}>
                <a href={aggProfileUrl(user.id)}>
                    <img src={aggSquarePhoto(user.photo, 100, user)} className="aboutMePhoto" alt="user" />
                </a>
                    <br/>
                    {!showEdit &&
                        <>
                            <a href={aggProfileUrl(user.id)}><span className="table-links">{user.name}</span></a>
                            <span className={sexFieldClass}> {sexFieldContent} </span>
                        </>
                    }
                    {showEdit &&
                        <>
                            <input type="text" defaultValue={user.name} className="editName" onChange={changeNameHandler} />
                            <label htmlFor="sexMale">Male: </label>
                            <input type="radio" name="sex" id="sexMale" value="male" defaultChecked={user.sex === 'male'} onChange={changeSelectedSex}/><br />
                            <label htmlFor="sexFemale">Female: </label>
                            <input type="radio" name="sex" id="sexFemale" value="female" defaultChecked={user.sex=== 'female'} onChange={changeSelectedSex}/>
                        </>
                    }
            </td>
            <td style={{width: '60%', maxWidth: '400px'}}>
                {showEdit &&
                    <div><textarea defaultValue={user.about} onChange={changeAboutMeHandler} className="aboutMeEdit"></textarea></div>
                }
                {!showEdit && <div dangerouslySetInnerHTML={{__html: user.about}} style={{overflowWrap: 'break-word'}} />}

                {!translate && <div className='table-links translateLink' onClick={() => setTranslate(true)}>Translate</div>}
                {translate && <TranslatedText textToTranslate={user.about} />}
            </td>
            <td style={{width: '55px'}}>
                {!warningMessageSent && <span className="table-links" onClick={sendWarningMessage}>Contact</span>}
                <br />
                {!warningMessageEscortSent && <span className="table-links" onClick={sendWarningMessageNotice} title="This is notice automessage">Escort</span>}
            </td>
            <td style={{width: '55px'}}>
                <span className="table-links" onClick={() => setShowEdit(!showEdit)}>Change</span>
            </td>
            <td style={{position: 'relative'}}>
                {confirmPressed && (
                    <div style={{position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, background: '#000', opacity: '0.6'}}>
                        <div className="loader" />
                    </div>
                )}

                <button className="green-button pointer" onClick={() => confirmAboutMeHandler(user.id)}>Confirm</button>
                <br />
                <label><input type="checkbox" onChange={()=>{setShowInIos(!showInIos)}} checked={showInIos} /> Show in IOS app</label>
            </td>
        </tr>
    )
}

export default AboutMeItem;
