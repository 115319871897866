import {useEffect, useState} from "react";

import adminApi from "../../../api/admin";
import Classes from "../OnlineSender/OnlineSender.module.scss";
import Avatar from "../../../common/Avatar/Avatar";
import ButtonLink from "../../../common/ButtonLink/ButtonLink";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { aggProfileUrl } from "../../../helpers/profile";
import { aggPhoto } from "../../../helpers";

const MassMessage = () => {
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [lastId, setLastId] = useState(null);
    const [activeList, setActiveList] = useState([]);
    const [disableLimit, setDisableLimit] = useState(null);

    const getList = (lastMessageId) => {
        setLoading(true);
        adminApi.post('group_messages/mass_message', {from: lastMessageId}).then(r => {
            setLoading(false);
            setList(prevList => {
                return [...prevList, ...r.data.list];
            })
            if(r.data.activeList) {
                setActiveList(prevActiveList => {
                    return [...prevActiveList, ...r.data.activeList];
                });
            }
            if(r.data.showMore) {
                setLastId(r.data.lastId);
            }else{
                setLastId(null);
            }
            setDisableLimit(r.data.disableLimit);
        });
    }
    useEffect( getList, []);

    const changeLimitHandler = (event) => {
        event.preventDefault();
        adminApi.post('group_messages/update_mass_message_disable_limit', {limit: event.target.disableLimit.value});
    }

    const deleteItemHandler = (id) => {
        const newList = [...list].filter(l => l.id !== id);
        setList(newList);
    }

    return (
        <div>
            <h2>Mass senders</h2>
            <a href={process.env.REACT_APP_MP+'/mass-message-create'} style={{float:'right'}}>Create mass message</a>
            {disableLimit &&
            <div>
                <form onSubmit={changeLimitHandler}>
                    Disable sender after:
                    <input type="text" defaultValue={disableLimit} name="disableLimit" style={{width:'60px'}}/>
                    <input type="submit" name="butmit_button" value="Change messages limit" />
                </form>
            </div>
            }
            <ActiveMessages data={activeList} />
            <p>
                <span style={{color:'#fff1a8'}}><b>Yellow</b></span> - user stopped message by herself,
	            <span style={{color:'#d4d4d4'}}><b> Grey</b></span> - waiting for recount
            </p>

            {list.length > 0 && <List list={list} onDelete={deleteItemHandler} />}
            {list.length > 0 && lastId && !isLoading &&(
                <button className="green-button load-more" onClick={() => getList(lastId)} style={{width:'100%'}}>Load more</button>
            )}
            {isLoading && <div className="loader mt-50"/>}
        </div>
    );
}

const List = ({list, onDelete}) => (
    <table className={Classes.Table}>
        <thead>
        <tr>
            <th style={{width: '130px'}}>Sender</th>
            <th style={{width: '30px'}}>unr</th>
            <th style={{width: '90px'}}>Content</th>
            <th style={{width: '100px'}}>Start date</th>
            <th style={{width: '80px'}}>Stats</th>
            <th></th>
            <th></th>
            <th style={{width: '60px'}}>Recount</th>
            <th style={{width: '10px'}}></th>
            <th></th>
            <th></th>
            <th style={{width: '30px'}}></th>
        </tr>
        </thead>
        <tbody>{list.map((l, i) => <Item item={l} onDelete={onDelete} key={i} />)}</tbody>
    </table>
);

const Item = ({item, onDelete}) => {
    const [isGreeting, setIsGreeting] = useState(item.isGreeting);
    const [isStarted, setIsStarted] = useState(item.isStarted);
    const [statsData, setStatsData] = useState(null);
    let itemColor = '#fff';
    if (item.user_stop > 0) itemColor = '#fff1a8';
    if (item.recount && item.isRecounting === 0) itemColor = '#ff6666';
    if (item.recount && item.isRecounting > 0) itemColor = '#d4d4d4';
    return(
        <tr style={{backgroundColor: itemColor}}>
            <td><Avatar user={item.user} /></td>
            <td className="red">{item.unread}</td>
            <td>
                <span id={`content-${item.id}`} data-tooltip-content={item.content}>Content</span>
                {!isStarted ? <ButtonLink onClick={startMessageHandler}>Start</ButtonLink>  : <ButtonLink onClick={stopMessageHandler}><b>Stop</b></ButtonLink>}
                <ReactTooltip
                    anchorId={`content-${item.id}`}
                    place={"bottom"}
                    style={{maxWidth: '200px', padding: '15px'}}
                />
            </td>
            <td>
                <span style={{fontSize: '10px'}}>{item.startDate}</span><br />

            </td>
            <td>
                {!statsData && <ButtonLink onClick={checkStatsHandler}>Check</ButtonLink>}
                {statsData && <span>{statsData}</span>}
            </td>
            <td><ButtonLink onClick={deleteMessageHandler}>Delete</ButtonLink></td>
            <td><b>0</b></td>
            <td><ButtonLink><b>{item.isRecounting ? 'Yes!' : 'No'}</b></ButtonLink></td>
            <td>{item.alwaysRecount ? 'Always' : ''}</td>
            <td>Age: {item.ageFrom}/{item.ageTo}</td>
            <td title={item.countries}><b>Countries</b></td>
            <td>
                <label htmlFor={`greeting-${item.id}`}>
                    <input type="checkbox" id={`greeting-${item.id}`} checked={isGreeting} onChange={changeGreetingHandler} />
                    Insterad Marishka
                </label>
            </td>
        </tr>
    )

    function changeGreetingHandler(event) {
        adminApi.post('group_messages/update_mass_mail_as_greeting', {'messageId' : item.id, 'isGreeting': event.target.checked}).then((r) => {
            setIsGreeting(current => !current);
        });
    }

    function startMessageHandler() {
        adminApi.post('group_messages/startmassmail/'+item.id).then((r) => {
            setIsStarted(current => !current);
        });
    }

    function stopMessageHandler() {
        adminApi.post('group_messages/stopmassmail/'+item.id).then((r) => {
            setIsStarted(current => !current);
        });
    }

    function deleteMessageHandler() {
        adminApi.post('group_messages/deletemassmail/'+item.id+'/'+item.user.id).then((r) => {
            onDelete(item.id);
        });
    }

    function checkStatsHandler() {
        adminApi.post('group_messages/get_massmail_messages_stats', {'messageId' : item.id}).then((r) => {
            setStatsData('Total: '+r.data.total + ' (sent: '+r.data.sent+') (left: '+r.data.pending+')');
        });
    }
}

const ActiveMessages = ({data}) => {
    return (
        <>
        {data &&
        data.map((user, i) => {
            return (
                <div className="activeSenders" key={i} style={{display: 'inline', marginRight: '2px'}} >
                    <a href={aggProfileUrl(user.id)}>
                        <img src={aggPhoto(user.photoUrl, 'thumb')} alt="user" style={{width:'40px'}} />
                    </a>
                </div>
            )
        })
        }
        </>
    );
}

export default MassMessage
