import { useEffect, useState } from 'react';

import apiAdmin from "../../api/admin";
import {aggProfileUrl} from "../../helpers/profile";

const Chargeback = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getList = (loadMore = false) => {
        setIsLoading(true);

        apiAdmin.get('payments/get_chargeback_list').then((r) => {
            setList(r.data);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <div>
            <h1> Chargeback (last 7 days) </h1>
            <table>
                <thead>
                <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Value</th>
                    <th>Type</th>
                </tr>
                </thead>
                <tbody>
                { list &&
                    list.map(row => {
                        return (
                            <tr key={row.memberID}>
                                <td>{row.maxdate}</td>
                                <td>
                                    <a href={aggProfileUrl(row.memberID)} className="table-links">{row.name}</a>
                                </td>
                                <td>{row.amount} &#8364;</td>
                                <td>Chargeback</td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>
            {(isLoading) ? <div className="loader"/> : ''}
        </div>
    )
}

export default Chargeback;
