import {configureStore} from "@reduxjs/toolkit";

import modalReducer from "./modal";
import authReducer from "./auth";
import adminBoxReducer from "./admin-box";
import conversationsReducer from "./conversations";

const store = configureStore({
    reducer: {
        modal: modalReducer,
        auth: authReducer,
        adminBox: adminBoxReducer,
        conversations: conversationsReducer
    }
});

export default store;
