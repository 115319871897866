import {Fragment, useEffect, useRef, useState} from "react";
import {aggProfileUrl} from "../../../helpers/profile";
import adminApi from "../../../api/admin";

const UnwantedSearch = ({hardcodedId, initUpdate}) => {
    const [id, setId] = useState('');

    const [filteredId, setFilteredId] = useState(null);
    const [unwantedByMe, setUnwantedByMe] = useState([]);
    const [unwantedByUsers, setUnwantedByUsers] = useState([]);


    useEffect(() => {
        if (hardcodedId) {
            onFind(hardcodedId);
        }
    }, [hardcodedId, initUpdate])


    const onFind = (givenId) => {
        // Clear search
        setUnwantedByMe([]);
        setUnwantedByUsers([]);
        setFilteredId(givenId);

        // Init api call and set results
        adminApi.post('users/get_unwanted_list', {userId: givenId}).then((r) => {
            setUnwantedByMe(r.data.unwantedByMe);
            setUnwantedByUsers(r.data.unwantedByUsers);
        });
    }

    const onRemove = (idToRemove) => {
        if (window.confirm('Are you sure?')) {
            adminApi.post('users/remove_unwanted', {userId: filteredId, unwantedUserId: idToRemove});

            setUnwantedByMe(unwantedByMe.filter(i => i.unwantedID !== idToRemove));
            setUnwantedByUsers(unwantedByUsers.filter(i => i.unwantedID !== idToRemove));
        }
    };

    const onSubmit = (e) => {
        e.preventDefault();
        onFind(id);
    }

    return (
        <Fragment>
            {!hardcodedId && (
                <form onSubmit={onSubmit}>
                    <h1>Search</h1>
                    <div className="group">
                        <label htmlFor="searchUser">Member ID:</label>
                        <input value={id} onChange={(e) => setId(e.target.value)} />
                    </div>
                    <button type="submit">Find</button>
                </form>
            )}

            {unwantedByMe.length > 0 && (<div>
                <h3>Unwanted By Me</h3>{
                unwantedByMe.map(unwanted => {
                    return (
                        <div key={unwanted.unwantedID}>
                            <a href={aggProfileUrl(unwanted.unwantedID)} className="table-links">{unwanted.name}</a>
                            <span
                                className="table-links"
                                onClick={() => onRemove(unwanted.unwantedID, "unwantedByMe")}
                            > Remove from unwanted by me </span>
                        </div>
                    )
                })
            }
            </div>
                )}

            {unwantedByUsers.length > 0 && (<div>
                <h3>Unwanted By Users</h3>
                {unwantedByUsers.map(unwanted => {
                    return (
                        <div key={unwanted.memberID}>
                            <a href={aggProfileUrl(unwanted.memberID)} className="table-links">{unwanted.name}</a>
                        </div>
                    )
                })
                }
            </div>
                )}
        </Fragment>
    );
}

export default UnwantedSearch;
