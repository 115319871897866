import { useEffect, useState } from "react";
import adminApi from "../../api/admin";
import { aggPhoto } from "../../helpers";
import { timeAgo } from "../../helpers/dates";
import { aggProfileUrl } from "../../helpers/profile";
import ConversationItem from "./ConversationItem";

const ConversationList = ({user}) => {
    const [isLoaded, setLoaded] = useState(false);
    const [list, setList] = useState([]);

    if (user.ID) {
        user.id = +user.ID;
    }
    useEffect(() => {
        adminApi.post('conversations/get_conversations/'+user.id).then((r) => {
            setLoaded(true);
            setList(r.data);
        });
    }, []);

    return <>{!isLoaded ? <p>Loading ....</p> : <List list={list} user={user} />}</>;
};

const List = ({list, user}) => {
    if (list.length === 0) {
        return <b>No conversations!</b>
    }

    return (
        <div className="conversationSentBox">
            {list.map(m => <ListItem conversation={m} user={user} key={m.id} />)}
        </div>
    ); 
}

const ListItem = ({conversation, user}) => {
    const [showConversation, setshowConversation] = useState(false);
    return (
        <table key={conversation.id}><tbody>
            <tr>
                <td className="tableCell" style={{width: '50px'}}>
                    <a href={aggProfileUrl(conversation.id)}>
                        <img src={aggPhoto(conversation.user.photoUrl, 'mini', conversation.user)} width="30" height="40" alt="" />
                    </a>
                </td>
                <td className="tableCell" style={{width: '140px'}}>
                    <a href={aggProfileUrl(conversation.id)}><span className="row_user_name">{conversation.user.name}</span></a>
                </td>
                <td className="tableCell" style={{width: '400px'}}>
                    <span className="table-links"><div><p onClick={() => {setshowConversation(!showConversation)}}>{conversation.content}</p></div></span>
                </td>
                <td className="tableCell">
                    {timeAgo(conversation.timeAgo)}
                </td>
            </tr>
            {showConversation && (<tr><td colSpan="4" className="tableCell"><ConversationItem user1={user} user2={conversation.user} /></td></tr>)}
        </tbody></table>
    )
}

export default ConversationList;