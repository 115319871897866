import {useEffect, useState, Fragment} from 'react';

import Classes from "./UncheckedMessages.module.scss";
import apiAdmin from "../../api/admin";
import useQuery from '../../helpers/useQuery';
import {timeAgo} from "../../helpers/dates";
import ButtonLink from "../../common/ButtonLink/ButtonLink";
import {aggProfileUrl} from "../../helpers/profile";
import ConversationItem from "../../common/Conversations/ConversationItem";

const UncheckedMessages = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const sexParam = useQuery().get('sex');

    const getList = () => {
        setIsLoading(true);
        apiAdmin.get('conversations/get_unchecked?sex='+sexParam).then((r) => {
            setIsLoading(false);
            setList(r.data.list);
        });
    }

    useEffect(getList, []);

    const onConfirm = (userId, recipientId, messageId) => {
        apiAdmin.post('conversations/confirm_unchecked', {userId: userId, recipientId: recipientId, sex: sexParam});
        const newArray = list.filter(l => l.message.id !== messageId);
        setList(newArray);
    };
    const onConfirmAll = (userId) => {
        apiAdmin.post('conversations/confirm_unchecked', {checkAll: true, userId: userId, sex: sexParam});
        const newArray = list.filter(l => l.user.id !== userId);
        setList(newArray);
    };

    return (
        <div className='about-me'>
            <h1>Unchecked messages</h1>
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Message</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>{list &&
                        list.map((m, i) => <Item data={m} onConfirm={onConfirm} onConfirmAll={onConfirmAll} key={i} />)}
                    </tbody>
                </table>
            </div>
            {isLoading && <div className="loader" />}
        </div>
    );
}
const Item = ({data, onConfirm, onConfirmAll}) => {
    const [showMessages, setMessages] = useState(false);
    const sentAt = new Date(data.message.sentAt * 1000).toLocaleString();

    return (
        <Fragment>
            <tr style={{height: '40px'}}>
                <td style={{width: '60px'}}>
                    <UserArea user={data.user} />
                </td>
                <td style={{width: '60px'}}>
                    <UserArea user={data.recipient} />
                </td>
                <td className={Classes.MessageColumn} onClick={() => setMessages(!showMessages)}>
                    <div className={Classes.Content}>{data.message.content}</div>
                    <div title={sentAt} className={Classes.Time}>{timeAgo(data.message.sentAt)}</div>
                </td>
                <td style={{width: '100px'}}>
                    <ButtonLink onClick={() => onConfirm(data.user.id, data.recipient.id, data.message.id)}>Confirm</ButtonLink>
                    <br />
                    <ButtonLink onClick={() => onConfirmAll(data.user.id)}>Confirm All</ButtonLink>
                </td>
            </tr>
            {showMessages && (
            <tr>
                <td colSpan={4}>
                    <ConversationItem user1={data.user} user2={data.recipient} />
                </td>
            </tr>)}
        </Fragment>
    );
}

const UserArea = ({user}) => (
    <Fragment>
        <a href={aggProfileUrl(user.id)}>
        {user.name}
        {user.isBlocked && <span style={{color: 'indianred', marginLeft: '5px'}}>(B)</span>}
        </a>
    </Fragment>
)

export default UncheckedMessages;
