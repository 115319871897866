import { useEffect, useState } from 'react';
import apiAdmin from "../../api/admin";
import { aggPhoto, textHighlighter } from '../../helpers';
import {countryNameByCode} from "../../helpers/lang";
import {aggProfileUrl} from "../../helpers/profile";

const SuspiciousMessages = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getList = (loadMore = false) => {
        setIsLoading(true);

        apiAdmin.get('suspicious/suspicious_messages').then((r) => {
            setList(r.data);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getList();
    }, []);


    const confirmMessage = (all = false, messageId = null) => {
        let idList = [];

        if (!all && messageId) {
            idList.push(parseInt(messageId));
        } else {
            list.map(m => {
                return idList.push(parseInt(m.message.id));
            });
        }

        if (idList.length > 0 ) {
            apiAdmin.post('suspicious/confirm_suspicious_message', {id: idList}).then((r) => {
                setList(r.data);
                setIsLoading(false);
                if (!all) {
                    let newArray = list.filter(m => {
                        return m.message.id !== messageId
                    });
                    setList(newArray);
                } else {
                    setList(null);
                }
            })
        }
    }

    return (
        <div>
            <h1>Suspicious Messages</h1>

            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Sender</th>
                        <th>Country</th>
                        <th>Message</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {list &&
                    list.map((m, i) => {
                        let sexFieldClass = (m.user.sex === 'male') ? 'male' : 'female';
                        let sexFieldContent = (m.user.sex === 'male') ? 'M' : 'F';
                        let description = textHighlighter(m.message.content, m.words);
                        return (
                            <tr key={i}>
                                <td style={{width: '60px'}}>
                                    <img src={aggPhoto(m.user.photoUrl, 'thumb')} className="user-search-photo" alt="user" />
                                    <br/>
                                    <a href={aggProfileUrl(m.user.id)} className="table-links">{m.user.name}</a>
                                    <span className={sexFieldClass}> {sexFieldContent} </span>
                                </td>
                                <td>{countryNameByCode(m.user.countryCode)}</td>
                                <td style={{width: '60%'}} dangerouslySetInnerHTML={{__html: description}}/>
                                <td><button className="green-button pointer" onClick={() => confirmMessage(false, m.message.id)}>Confirm</button></td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>
                <p className="mt-10 ml-5">Total number of suspicious messages : <b>{list && list.length}</b></p>
                <button className="green-button pointer" onClick={() => {
                    if (window.confirm('Are you sure? \nDo you really want to confirm all suspicious messages?')) {
                        confirmMessage(true)
                    }
                }}>
                    Confirm all messages
                </button>
            </div>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default SuspiciousMessages;
