import {useEffect, useRef, useState} from "react";

import Classes from "./MainInfo.module.scss";
import adminApi from "../../api/admin";
import ConversationList from "../../common/Conversations/ConversationList";
import Crop from "../../common/Crop/Crop";
import WantVisit from "./components/WantVisit";
import { reportReasons } from "../../config/reportReasons";
import { aggPhoto } from "../../helpers";
import { aggDateFromTimeStamp, aggTripDate, timeAgo } from "../../helpers/dates";
import aggSquarePhoto, { aggOriginalPhoto } from "../../helpers/photos";
import { aggProfileUrl } from "../../helpers/profile";
import { aboutMeText } from "../../helpers/text";
import ConversationItem from "../../common/Conversations/ConversationItem";

const getYears = () => {
    let list = [];
    let startYear = 18;
    while ( startYear <= 99 ) {
        let currentVal = startYear++;
        list.push(currentVal);
    }
    return list;
}
const yearList = getYears();

const MainInfo = (props) => {
    const user = props.user.member;
    const [showEditName, setShowEditName] = useState(false);
    const [showEditSex, setShowEditSex] = useState(false);
    const [selectedSex, setSelectedSex] = useState(user.sex);
    const [showEditAboutMe, setShowEditAboutMe] = useState(false);
    const [loginCountries, setLoginCountries] = useState(null);
    const [updatedSquarePhoto, setUpdatedSquarePhoto] = useState(null);
    const [updatedOrigPhoto, setUpdatedOrigPhoto] = useState(null);
    const [showConversations, setShowConversations] = useState(false);
    const [cropInfo, setCropInfo] = useState({isOpened:false});
    const [userAge, setUserAge] = useState(props.user.member.age);
    const nameRef = useRef(null);
    const aboutMeRef = useRef(null);
    const showSexChangeButton = (user.isConfirmedEver === '0' && user.sex === 'male') ? true : false;

    const getLoginCountries = () => {
        adminApi.get('users/getLogInCountries/'+user.ID).then((r) => {
            setLoginCountries(r.data.countries);
        });
    }

    useEffect(()=> {
        getLoginCountries();
    },[]);

    const mainPhotoObj  = (!props.user.photos) ? null : props.user.photos.find(element => {
        return element.photo.isMain;
    });

    let mainPhotoObjAdmin = null;
    if (user.mainPhotoAdminId && user.mainPhotoAdminId !== user.mainPhotoId) {
        mainPhotoObjAdmin = props.user.photos.find(element => {
            return String(element.id) === user.mainPhotoId;
        });
    }

    const mainPhoto = aggPhoto((mainPhotoObj) ? mainPhotoObj.url : null, 'thumb', user);
    const squarePhoto = (updatedSquarePhoto) ? updatedSquarePhoto : aggSquarePhoto((mainPhotoObj) ? mainPhotoObj.photo : null, 300, user);
    const orginalPhoto = (updatedOrigPhoto) ? aggOriginalPhoto(updatedOrigPhoto, user) : aggOriginalPhoto((mainPhotoObj) ? mainPhotoObj.photo : null, user);

    const updateAgeHandler = (e) => {
        const d = new Date();
        d.setFullYear(d.getFullYear()-e.target.value);
        const newDate = aggDateFromTimeStamp(d.getTime());
        props.onUserUpdate({age: newDate});
        setUserAge(e.target.value);
    }

    const updateNameHandler = () => {
        props.onUserUpdate({name: nameRef.current.value});
        setShowEditName(false);
    }

    const changeSelectedSex = (event) => {
        setSelectedSex(event.target.value);
    }

    const promptChangeSelectedSex = (event) => {
        if (event.target.value === selectedSex) {
            return false;
        }
        setSelectedSex(event.target.value);
        updateSexHandler(event.target.value);
    }

    const updateSexHandler = (sex) => {
        const newSex = (sex === 'male' || sex === 'female') ? sex : selectedSex;
        if (newSex === 'female' && userAge >= 40) {
            props.onUserUpdate({sex: newSex, limitedWriteAccess: 1});
        } else {
            props.onUserUpdate({sex: newSex});
        }
        setShowEditSex(false);
    }

    const updateAboutMeHandler = () => {
        props.onUserUpdate({about_me: aboutMeRef.current.value});
        setShowEditAboutMe(false);
    }

    const reloadSrc = (e, photo) => {
        setTimeout(() => {
            e.target.src = photo;
        }, 1000);
    }

    const movePhoto = (direction) => {
        let postData = {photoId:  mainPhotoObj.id, direction: direction}
        adminApi.post('photos/moveUp/'+user.ID, postData).then((r) => {
            setUpdatedSquarePhoto(r.data.generatedUrl);
        });
    }

    const darkerPhoto = (value) => {
        let postData = {photoId:  mainPhotoObj.id, increment: value}
        adminApi.post('photos/photoLight/'+user.ID, postData).then((r) => {
            setUpdatedSquarePhoto(r.data.generatedUrl);
        });
    }

    const photoContrastHandler = (value) => {
        let postData = {photoId:  mainPhotoObj.id, increment: value}
        adminApi.post('photos/photoContrast/'+user.ID, postData).then((r) => {
           setUpdatedSquarePhoto(r.data.generatedUrl);
        });
    }

    const rotatePhotoHandler = (angle) => {
        let postData = {photoId:  mainPhotoObj.id, angle: angle}
        adminApi.post('photos/rotateSquare/'+user.ID, postData).then((r) => {
            setUpdatedSquarePhoto(r.data.generatedUrl);
            setUpdatedOrigPhoto(r.data.photo);
        });
    }

    const cropHandler = (coords) => {
        adminApi.post('photos/crop_square_photo/' + user.ID + '/' + mainPhotoObj.id, {crop: coords}).then((r) => {
            setUpdatedSquarePhoto(r.data.generatedUrl);
        });
    }

    return (
        <>
            <h1 className="user-name">
                {user.verified === '1' &&
                    <i className="fa fa-check-circle-o userVerified" aria-hidden="true"></i>
                }
                <span id="user_name_holder">{user.name}</span>

                <span className="user-age">
                    <span id="user-age-label">{userAge}</span> years
                </span>

                <span>
                    <select name="user-age" className="ageSelect" defaultValue={user.age} onChange={updateAgeHandler}>
                        {yearList.map((item, i) => {
                            return (<option key={i} value={item}>{item}</option>);
                        })}
                    </select>
                </span>
                {user.hidden === '1' &&
                    <span className="red f14">[hidden]</span>
                }
                {user.hidden === '2' &&
                    <span className="red f14">[hidden] (Profile duplicate)</span>
                }
            </h1>

            <div className="name-edit">
                {showEditName &&
                    <div className="name_edit">
                        <input type="text" defaultValue={user.name} ref={nameRef} name="edit_name" className="name-edit-input" />
                        <i className="fa fa-check inline table-links actionSaveField" aria-hidden="true" onClick={updateNameHandler}></i>
                    </div>
                }
                {!showEditName &&
                    <div className="edit_member_field table-links inline" onClick={() => {setShowEditName(!showEditName)}} >
                        <i className="fa fa-pencil-square-o edit-icon" aria-hidden="true"></i>
                    </div>
                }
            </div>

            { user.blocked !== '0' &&
            <table className="blockTable"><tbody>
                <tr>
                    <td className="w120 red">
                        <b className="f14">[Blocked]</b><br/>
                        <span className="f11">{(user.blocked === '4' || user.verified === '1') ? '[suspended]' : '[verify]'}</span>
                        <span className="f11"> {(user.blockInfo.cookie_block !== '0') ? '[duplicates]' : ''}</span>
                        <span className="f11"> {(user.verified === '1') ? '[forever]' : ''}</span>
                    </td>
                    <td className="w90 f13"><span className="inline table-links">{user.blockInfo.reasonTitle}</span></td>
                    <td className="w160 f9 dark-grey-text">{user.blockInfo.comment}</td>
                    <td>
                        {user.blockInfo.systemBlock === '0' && <span>by: {user.blockInfo.adminName}</span>}
                        {user.blockInfo.systemBlock === '1' && <span>Auto-On registering</span>}
                        {user.blockInfo.systemBlock === '2' && <span>Auto-On about-me update</span>}
                        {user.blockInfo.systemBlock === '3' && <span>by Report system</span>}
                    </td>
                </tr>
            </tbody></table>
            }

            <div className="cl"></div>
            {props.user.reports && props.user.reports.length > 0 &&
                <Reports reports={props.user.reports} user={props.user} escortReports={user.warningAboutEscort} />
            }
            <div className="cl"></div>

            <div className="profilePhoto fl">
                <div className="photo-container">
                    {mainPhotoObj &&
                        <div className="app-photo">
                            <div className="image-square">
                                <img src={squarePhoto} alt="user profile" onError={event => reloadSrc(event, squarePhoto)}/>
                            </div>
                            <div className="app-photo-actions">
                                <div className="editButton table-links inline" title="Move Up" onClick={() => movePhoto(-1)}>&nbsp;<i className="fa fa-long-arrow-up" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline"  title="Move Down" onClick={() => movePhoto(1)}><i className="fa fa-long-arrow-down" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline"  title="Rotate left" onClick={() => rotatePhotoHandler(90)}><i className="fa fa-undo" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline"  title="Rotate right" onClick={() => rotatePhotoHandler(270)}><i className="fa fa-repeat" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline" title="Crop photo" onClick={() => setCropInfo({isOpened: !cropInfo.isOpened})}><i className="fa fa-crop" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline" title="Brighter" onClick={() => darkerPhoto(1)}><i className="fa fa-sun-o" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline" title="Darker" onClick={() => darkerPhoto(-1)}><i className="fa fa-moon-o" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline" title="Sharper" onClick={() => photoContrastHandler(1)}><i className="fa fa-adjust" aria-hidden="true"></i></div>
                                <div className="editButton table-links inline" title="Softer" onClick={() => photoContrastHandler(-1)}><i className="fa fa-circle-o" aria-hidden="true"></i></div>
                            </div>
                        </div>
                    }
                    <img src={mainPhoto} width="180" height="240" id="front_photo" alt="user photos" onClick={props.onMainPhotoOpen} />
                    { (props.user.online) ?  <div className="user-online">Online</div> : ''}
                </div>
                <a href={process.env.REACT_APP_MP+'/conversations/'+user.ID} ><button className="green-button pointer messageButton"><i className="fa fa-commenting" aria-hidden="true"></i> Send message</button></a>
                <div className="matchPref">
                    Match pr: {user.matchSex} [{user.matchAgeFrom} - {user.matchAgeTo}]
                </div>
                <div className="show_conversations_sentbox green-text pointer" onClick={() => setShowConversations(!showConversations)}>Show conversations sentbox</div>
                {showConversations && <ConversationList user={user} />}
                {mainPhotoObjAdmin &&
                    <div className="userMainPhoto">
                        <div className="mainPhotoLabel">User main photo</div>
                        <img src={aggPhoto((mainPhotoObjAdmin) ? mainPhotoObjAdmin.url : null, 'thumb', user)} alt="real main foto" />
                    </div>
                }
            </div>
            <div className="publicInfo fr">
                {!props.user.isDeleted &&
                    <div className="online-status-wrapper">
                        <div className="online-status">
                            {(props.user.online === 1) && <span>Online</span>}
                            {!props.user.online && <span>Last activity: {timeAgo(user.activeAt)}</span>}
                        </div>
                        {user.sex === 'female' &&
                            <ReplyRate rate={user.replyRate} />
                        }
                    </div>
                }
                {props.user.isDeleted &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Deletion date:</div>
                        <div className={Classes.MainInfoLineContent}>{user.deleteDate}</div>
                    </div>
                }
                <div className="member_information_block">
                    <table width="100%"><tbody>
                        <tr>
                            <td className="member_column">City, Country:</td>
                            <td className={`member_information locationContainer`}>
                                {user.city_name && user.city_name+', '} {user.country_name}&nbsp;
                                {loginCountries &&
                                    <span className="other-countries">
                                        {loginCountries.map((country, i)=>{
                                            return (<span key={i}>{country}, </span>)
                                        })}
                                    </span>
                                }
                            </td>
                        </tr>
                    </tbody></table>
                </div>
                {user.nationality &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Nationality:</div>
                        <div className={Classes.MainInfoLineContent}>{user.nationality}</div>
                    </div>
                }
                <div className="member_information_block">
                    <table><tbody>
                        <tr>
                            <td className="member_column">Sex:</td>
                            {showSexChangeButton ? 
                                <td className="member_information">
                                    <label className={Classes.SexChangeButton} htmlFor="sexMale">Male: <input type="radio" name="sex" id="sexMale" value="male" defaultChecked={user.sex === 'male'} onClick={promptChangeSelectedSex}/></label>
                                    <label className={`${Classes.SexChangeButton} ${Classes.FemaleSexButton}`} htmlFor="sexFemale">Female: <input type="radio" name="sex" id="sexFemale" value="female" defaultChecked={user.sex=== 'female'} onClick={promptChangeSelectedSex}/></label>
                                </td>
                            :
                                <>
                                <td className="member_information">
                                    {(!showEditSex) ? user.sex : ''}
                                    {showEditSex &&
                                        <>
                                        <label htmlFor="sexMale">Male: </label>
                                        <input type="radio" name="sex" id="sexMale" value="male" defaultChecked={user.sex === 'male'} onChange={changeSelectedSex}/>
                                        <label htmlFor="sexFemale">Female: </label>
                                        <input type="radio" name="sex" id="sexFemale" value="female" defaultChecked={user.sex=== 'female'} onChange={changeSelectedSex}/>
                                        </>
                                    }
                                </td>
                                <td>
                                    {!showEditSex &&
                                        <i className="fa fa-pencil-square-o edit-icon" aria-hidden="true" onClick={() => {setShowEditSex(!showEditSex)}}></i>
                                    }
                                    {showEditSex &&
                                        <i className="fa fa-check inline table-links actionSaveField" aria-hidden="true" onClick={updateSexHandler}></i>
                                    }
                                </td>
                                </>
                            }
                        </tr>
                    </tbody></table>
                </div>
                {user.language &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Language:</div>
                        <div className={Classes.MainInfoLineContent}>{user.language}</div>
                    </div>
                }
                {user.height &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Height:</div>
                        <div className={Classes.MainInfoLineContent}>{user.height} cm</div>
                    </div>
                }
                {user.weight &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Body type:</div>
                        <div className={Classes.MainInfoLineContent}>{user.weight}</div>
                    </div>
                }
                {user.eyes &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Eyes:</div>
                        <div className={Classes.MainInfoLineContent}>{user.eyes}</div>
                    </div>
                }
                {user.hair &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Hair:</div>
                        <div className={Classes.MainInfoLineContent}>{user.hair}</div>
                    </div>
                }
                {user.looking_for &&
                    <div className={Classes.MainInfoLine}>
                        <div className={Classes.MainInfoLineTitle}>Looking for:</div>
                        <div className={Classes.MainInfoLineContent}>{user.looking_for}</div>
                    </div>
                }
                {!props.user.isDeleted &&
                    <>
                        {props.user.wantsToVisit.length > 0 &&
                            (
                                <div className={Classes.MainInfoLine}>
                                    <div className={Classes.MainInfoLineTitle}>Wants to visit:</div>
                                    <div className={Classes.MainInfoLineContent}>
                                        <WantVisit list={props.user.wantsToVisit} />
                                    </div>
                                </div>
                            )
                        }

                        {/* Planned trips */}
                        {props.user.plannedTrips.length > 0 &&
                        <div className="member_information_block fl">
                            <span className="trip-title">Planned trips:</span>
                            <div>
                                {props.user.plannedTrips.map((m, i) => {
                                    return (
                                        <div className="trip_info" key={i}>
                                            <div className="trip_country">
                                                <div className="trip_icon"><i className="fa fa-map-marker" aria-hidden="true"></i></div>
                                                <div className="trip_country_name">{m.location_title}</div>
                                            </div>
                                            <div className="trip_date">{aggTripDate(m.date_from)} - {aggTripDate(m.date_to)}</div>
                                        </div>
                                    )
                                })}

                            </div>
                        </div>
                        }
                    </>
                }

                <div className="cl"></div>
                <p>
                    <b className={(props.user.updates.about_me) ? 'red' : ''}>About me</b>
                    {!showEditAboutMe &&
                        <i className="fa fa-pencil-square-o edit-icon" aria-hidden="true" onClick={() => {setShowEditAboutMe(!showEditAboutMe)}}></i>
                    }
                    {showEditAboutMe &&
                        <i className="fa fa-check table-links inline actionSaveField" aria-hidden="true" onClick={updateAboutMeHandler}></i>
                    }

                </p>
                {!showEditAboutMe &&
                    <div><p className={Classes.AboutMeText} dangerouslySetInnerHTML={{__html: aboutMeText(user.about_me)}}></p></div>
                }
                {showEditAboutMe &&
                    <div><textarea defaultValue={user.about_me} ref={aboutMeRef} className="aboutMeEdit"></textarea></div>
                }
            </div>
            <div className="cl"></div>
            {cropInfo.isOpened &&
                <Crop
                    photo={orginalPhoto}
                    isOpened={cropInfo.isOpened}
                    onCropSave={(coords) => cropHandler(coords)}
                    onClose={() => setCropInfo({isOpened: false})}
                    isSquare={true}
                />
            }
        </>
    )
}

const Reports = ({reports, escortReports, user}) => {
    const [showReports, setShowReports] = useState(false);
    const [reportList, setReportList] = useState(reports);

    const deleteReport = (reportId) => {
        if (window.confirm('Are you sure?')) {
            adminApi.post('users/delete_report/' + reportId).then((r) => {
                setReportList(
                    reportList.filter(p => p.report_id !== reportId)
                );
            });
        }
    }
    return (
        <div className="reportsArea">
            <div className="reportsLink table-links inline f14" onClick={() => setShowReports(!showReports)}>
                <b>Reports</b>
                {escortReports === '1' && <b className="purple"> $</b>}
            </div>
            {showReports &&
                <div id="reports_about_member" className="table-1">
                    {reportList.map((report, i)=> {
                    return (
                        <ReportItem report={report} user={user} key={report.report_id} onDelete={(reportId) => deleteReport(reportId)} />
                    );
                })}
                </div>
            }
        </div>
    )
}

const ReportItem = ({report, user, onDelete}) => {
    const [showConversation, setshowConversation] = useState(false);
    user.member.id = +user.member.ID;
    report.id = +report.ID;

    return (
        <table><tbody>
            <tr>
                <td style={{width:'20px'}}>
                    <i className="fa fa-close red table-links" aria-hidden="true" onClick={() => onDelete(report.report_id)}></i>
                </td>
                <td className="f9" style={{width:'75px'}}>
                    <a href={aggProfileUrl(report.ID)} className="row_user_name">
                        {report.name}
                    </a>
                </td>
                <td className="f9">{reportReasons[report.report_type]} {report.warningAboutEscort === '1' && <b className="purple"> $</b>}</td>
                <td className="f9" style={{width:'345px'}}>
                    <div onClick={() => {setshowConversation(!showConversation)}} className='table-links'>{report.report_comments}</div>
                </td>
                <td className="f9" style={{width:'110px'}}>{report.date}</td>
            </tr>
            {showConversation && (<tr><td colSpan="5" className="tableCell"><ConversationItem user1={user.member} user2={report} /></td></tr>)}
        </tbody></table>
    )
}

const ReplyRate = ({rate}) => {
    const activeRate = Math.ceil(rate/10);

    let rateItems = [];
    for (let i = 0; i < 10; i++) {
        if (i < activeRate) {
            rateItems.push(<div key={i} className="rateTab rateActive"></div>)
        } else {
            rateItems.push(<div key={i} className="rateTab"></div>);
        }
    }
    return (
        <div className="replyRateWrapper">
            <span className="fl">Reply rate:</span>
            <div className="replyRateBlock">
                {rateItems}
            </div>
        </div>
    )
}

export default MainInfo
