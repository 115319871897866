import {useEffect, useState} from "react";
import {useSelector} from "react-redux";


import Logo from "../assets/logo.png";
import adminApi from "../api/admin";
import {isDevMode} from "../helpers/devMode";
import ButtonLink from "../common/ButtonLink/ButtonLink";
import {unsetAuthData} from "../helpers/authentication";


const Header = () => {
    const user = useSelector(s => s.auth.user);
    const supportConversations = useSelector(s => s.auth.supportConversations);

    const onLogout = () => {
        adminApi.post('auth/logout').then(r => {
            unsetAuthData();
            alert('Logged out');
            window.location.href = process.env.REACT_APP_MP;
        });
    }

    return (
        <div className="header">
            <a className="header-logo" href={`${process.env.REACT_APP_MP}`}>
                <img src={Logo} alt="Travelgirls" style={isDevMode() ? {opacity: '0.4'} : {}} />
            </a>

            {/*<div className="header-menu"><a href="/members/girls">Browse Members</a></div>*/}
            <OnlineUsers />
            <AdministrationBox />

            {supportConversations && (
                <div className="header-user-info">
                    <Bubble count={supportConversations.unreadCount} />
                    <a href={supportConversations.url} className="header-user-info-name">Support</a>

                    <div style={{padding: '6px 0 0 0'}}>
                        <ButtonLink onClick={onLogout} className="header-user-info-logout-link">Log out</ButtonLink>
                        <span className="header-user-info-sep"> | </span> <b>{user.name}</b>
                    </div>
                </div>
            )}
        </div>
    );
}

const Bubble = ({count, conversationsUrl}) => {
    if (Number(count) === 0) {
        return null;
    }

    return (<a href={conversationsUrl} className="header-user-info-conversations-bubble">{count}</a>);
}

const OnlineUsers = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        adminApi.get('dashboard/get_online_users_count').then(r => {
            setData(r.data);
        });
    }, []);

    if (data && typeof data.onlineUsers !== "undefined" && typeof data.onlineUsers1Hour !== "undefined") {
        return (
            <div className="header-online-users-count">
                <div>
                    {data.onlineUsers.name}: {data.onlineUsers.value}<br/>
                    {data.onlineUsers1Hour.name}: {data.onlineUsers1Hour.value}
                </div>
            </div>
        );
    }

    return null;
}

const AdministrationBox = () => {
    const data = useSelector(s => s.adminBox.data);

    if (!data) {
        return null;
    }

    return (
        <div className="header-administration-box">
            {data.map(item => {
                return (
                    <div key={item.url}>
                        <a href={item.url}>{item.name}: {item.value}</a><br/>
                    </div>
                );
            })}
        </div>
    );
}

export default Header;
