import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    unreadViews: 0
}

const countersSlice = createSlice({
    name: 'counters',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state[action.payload.name] = action.payload.count;
        }
    }
});

export const countersActions = countersSlice.actions;
export default countersSlice.reducer;
