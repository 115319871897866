import {useState, useEffect} from "react";
import apiAdmin from "../../../api/admin";
import Cropper from 'react-easy-crop';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import VerifiedNewestProfiles from "./VerifiedNewestProfiles";
import VerificationItem from "./VerificationItem/VerificationItem";
import { aggPhoto } from "../../../helpers";

const Verification = () => {
    const [loading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [cropImage, setCropImage] = useState(null);
    const [cropped, setCropped] = useState({x: 0,y: 0,width: 0,height: 0,});
    const [zoom, setZoom] = useState(1);
    const [cropUserId, setCropUserId] = useState(null);
    const [cropPhotoId, setCropPhotoId] = useState(null);
    const [selfieAsMainPhoto, setSelfieAsMainPhoto] = useState(false);

    const getList = () => {
        setLoading(true);

        apiAdmin.get('verification/get_pending_verifications').then((r) => {
            setLoading(false);
            setList(r.data);
        });
    }

    useEffect(() => {
        getList();
    }, []);

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
        setCropped({x: croppedAreaPixels.x,
            y: croppedAreaPixels.y,
            width: croppedAreaPixels.width,
            height: croppedAreaPixels.height})
    };

    const closeModal = () => setOpenModal(false);

    const updateCropImage = (img, userId, photoId, isSelfiePhoto) => {
        setCropImage(aggPhoto(img));
        setCropUserId(userId);
        setCropPhotoId(photoId);
        setOpenModal(true);
        if (isSelfiePhoto) {
            setSelfieAsMainPhoto(true);
        }
    }

    const verifyHandler = () => {
        apiAdmin.post('verification/verify_user/' + cropUserId, {crop: cropped, photoId: cropPhotoId, selfie: selfieAsMainPhoto}).then((r) => {
            setCrop({ x: 0, y: 0 });
            setCropped({x: 0,y: 0,width: 0,height: 0,});
            setZoom(1);
            closeModal();
            removeUserFromList(cropUserId);
        });
    }

    const removeUserFromList = (userId) => {
        let newList = list.filter(el => {
            return el.user.id !== userId
        });
        setList(newList);
    };

    return (
        <div>
            <h3>Newest verified profiles</h3>
            <VerifiedNewestProfiles />
            <h1 className="fl">Profile verification</h1>
            <a href={process.env.REACT_APP_MP+'/verified-profiles'} className="fr table-links mt-15"><b>» Verified profiles</b></a>
            {loading && <div className="loader mt-20"/> }
            {!loading &&
                <table style={{position: "relative"}}>
                    { list &&
                        list.map((m, i) => {
                            return (
                                <VerificationItem user={m} onRemove={removeUserFromList} onReload={getList} onCrop={updateCropImage} key={m.id} />
                            )
                        })
                    }
                </table>
            }
            <Popup open={openModal} closeOnDocumentClick onClose={closeModal}>
                <div className="crop-wrapper">
                <div className="modal">
                    <div className="close-button" onClick={closeModal}><i className="fa fa-times" aria-hidden="true"></i></div>
                    <div className="crop-container">
                        <Cropper
                        image={cropImage}
                        crop={crop}
                        zoom={zoom}
                        showGrid={false}
                        aspect={3 / 4}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        />
                    </div>
                    <button className="green-button big-button mt-10 crop-button" onClick={verifyHandler}>
                        Crop and verify
                    </button>
                </div>
                </div>
            </Popup>
        </div>
    );

}

export default Verification;
