import axios from "axios";
import {useEffect, useState} from "react";

const apiKey = process.env.REACT_APP_TRANSLATE_GOOGLE_API_KEY;
const urlTranslate = 'https://www.googleapis.com/language/translate/v2';

const TranslatedText = ({textToTranslate, targetLanguage = 'en'}) => {
    const [text, setText] = useState(null);

    const params = {key: apiKey, target: targetLanguage, q: textToTranslate}

    useEffect(() => {
        if (textToTranslate !== '') {
            axios.get(urlTranslate, {params}).then(r => {setText(r.data.data.translations)});
        }
    }, [textToTranslate, targetLanguage]);

    if (text === null) {
        return null;
    }

    return (
        <div>
            {text.map((t,i) => <TextItem key={i} t={t} />)}
        </div>
    );
}

const TextItem = ({t}) => (
    <div
        title={"detectedSourceLanguage: "+t.detectedSourceLanguage}
        dangerouslySetInnerHTML={{__html: t.translatedText}}
    />
);

export default TranslatedText;
