import FsLightbox from "fslightbox-react";
import {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import apiAdmin from "../../api/admin";
import Crop from "../../common/Crop/Crop";
import { aggVerificationUrl } from "../../helpers/photos";

const RightSide = (props) => {
    const user = props.user.member;
    const nav = useNavigate();
    const [showExample, setShowExample] = useState(false);
    const [verificationMagnified, setVerificationMagnified] = useState(false);
    const [showAutoMsgList, setShowAutoMsgList] = useState(false);
    const [tfVisibility, setTfVisibility] = useState(user.showInApp !== '2');
    const [tgVisibility, setTgVisibility] = useState(user.showInApp !== '1');
    const [limitedAnswerAccess, setLimitedAnswerAccess] = useState(user.limitedAnswerAccess === '1');
    const [limitedWriteAccess, setLimitedWriteAccess] = useState(user.limitedWriteAccess === '1');
    const [showBlockMember, setShowBlockMember] = useState(false);
    const [userIsOnMassMail, setUserIsOnMassMail] = useState(null);
    const [userSeeGroupMessage, setUserSeeGroupMessage] = useState(props.user.hasGroupMessage);
    const [blockReason, setBlockReason] = useState(3);
    const [blockCookie, setBlockCookie] = useState(true);
    const [cropInfo, setCropInfo] = useState({isOpened:false});
    const [sentWarnings, setSentWarnings] = useState([]);
    const blockCommentRef = useRef(null);

    const handleMouseEnter = () => {
        setShowExample(true);
    }
    const handleMouseLeave = () => {
        setShowExample(false);
    }

    const tgVisibilityHandler = (event) => {
        setTgVisibility(event.target.checked);
    }

    const tfVisibilityHandler = (event) => {
        setTfVisibility(event.target.checked);
    }

    const forbidToChatHandler = (event) => {
        setLimitedWriteAccess(!limitedWriteAccess);
        apiAdmin.post('users/update/'+user.ID, {limitedWriteAccess: event.target.checked }).then((r) => {});
    }

    const forbidToAnswerHandler = (event) => {
        setLimitedAnswerAccess(!limitedAnswerAccess);
        apiAdmin.post('users/update/'+user.ID, {limitedAnswerAccess: event.target.checked }).then((r) => {});
    }

    const groupMessageStatusHandler = () => {
        let action = userSeeGroupMessage ? 'remove' : 'add';
        apiAdmin.post('users/group_message_status/'+user.ID, {action: action}).then((r) => {
            setUserSeeGroupMessage( (userSeeGroupMessage) ? 0 : 1 );
        });
    }

    const setOnlineHandler = () => {
        if (window.confirm('Are you sure? \nDo you realy want to set this user online?')) {
            props.onUserRefresh(true);
            apiAdmin.post('users/set_online/'+user.ID).then((r) => {
                props.onReloadUser();
                props.onUserRefresh(false);
            });
        }
    }

    const showBlockArea = () => {
        if (userIsOnMassMail === null) {
            apiAdmin.post('users/check_is_member_greeter/'+user.ID).then((r) => {
                setUserIsOnMassMail(r.data.isGreeter);
            });
        }
        setShowBlockMember(!showBlockMember);
    }

    const unblockUserHandler = () => {
        props.onUserRefresh(true);
        apiAdmin.post('users/unblock_user/'+user.ID).then((r) => {
            props.onUserRefresh(false);
            props.onReloadUser();
        });
    }

    const blockUserHandler = (blockLevel) => {
        props.onUserRefresh(true);
        let postData = {
            userID: user.ID, 
            reason: blockReason, 
            blockCookie: blockCookie ? 1 : 0,  
            comment: blockCommentRef.current.value, 
            blockLevel: blockLevel
        }

        apiAdmin.post('users/block_user/'+user.ID, postData).then(() => {
            props.onUserRefresh(false);
            apiAdmin.get('/users/get_updating_member/'+user.sex+'/true').then((r) => {
                nav('/user/'+r.data.userId);
            });
        });
    }

    const blockReasonHandler = (event) => {
        setBlockReason(event.target.value);
    };

    const blockCookieChangeHandler = (event) => {
        setBlockCookie(event.target.checked);
    }

    const changePriority = (priority) => {
        props.onUserRefresh(true);
        apiAdmin.post('users/update/'+user.ID, {showInFirst: priority}).then((r) => {
            props.onUserUpdate({showInFirst: priority});
            props.onUserRefresh(false);
        });
    }

    const sendWarningMessage = (type) => {
        if (window.confirm('Are you sure?')) {
            apiAdmin.post('conversations/auto_msg/'+type+'/'+user.ID).then((r) => {
                setSentWarnings(current => [...current, type]);
            });
        }
    }

    const sendWarningEscortMessage = () => {
        if (window.confirm('Are you sure?')) {
            apiAdmin.post('reports/warning_about_escort', {userId: user.ID, reportId: 0}).then((r) => {
                setSentWarnings(current => [...current, 'prices']);
            });
        }
    }

    const blockForDuplicate = () => {
        if (window.confirm('Are you sure \nDo you realy want to block a member for duplicated profile?')) {
            props.onUserRefresh(true);
            apiAdmin.post('users/duplicate_profile/', {userId: user.ID, action: 'add'}).then((r) => {
                props.onUserUpdate({hidden: '2'});
                props.onUserRefresh(false);
            });
        }
    }

    const unblockForDuplicate = () => {
        props.onUserRefresh(true);
        apiAdmin.post('users/duplicate_profile/', {userId: user.ID, action: 'remove'}).then((r) => {    
            props.onUserUpdate({hidden: '0'});
            props.onUserRefresh(false);
        });
    }

    const verifyUser = () => {
        props.onUserRefresh(true);
        apiAdmin.post('verification/verify_user/'+user.ID).then((r) => {    
            props.onUserUpdate({verified: '1'});
            props.onUserRefresh(false);
        });
    }

    const unverifyUser = () => {
        props.onUserRefresh(true);
        apiAdmin.post('verification/remove_user_verification/'+user.ID).then((r) => {
            props.onUserUpdate({verified: '0'});
            props.onUserRefresh(false);
        });
    }

    const deleteAllPhotos = () => {
        if (window.confirm('Are you sure? \nDo you realy want to delete all members photos?')) {
            props.onUserRefresh(true);
            apiAdmin.post('photos/delete_all_photos/'+user.ID).then((r) => {
                props.onReloadUser();
                props.onUserRefresh(false);
            });
        }
    }

    const cropHandler = (coords) => {
        apiAdmin.post('photos/crop_verification_photo/'+user.ID, {crop: coords, photoId: user.ver_photo_id}).then((r) => {
            props.onReloadUser();
        });
    }

    const rotateVerificationPhotoHandler = (angle) => {
        apiAdmin.post('photos/rotateVerificationPhoto/'+angle+'/'+user.ID+'/'+user.ver_photo_id).then((r) => {
            props.onReloadUser();
        });
    }

    const magnifyVerificationHandler = () => {
        setVerificationMagnified(!verificationMagnified);
    }

    return (
        <div className="right-side">
            {user.verified === '1' && user.ver_url  &&
                <div className="verification_box">
                    {user.verificationExample && 
                        <div 
                            className="green-text verification_example pointer" 
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>Show example
                        </div>
                    }
                    {showExample && 
                        <img src={user.verificationExample} width="180" height="240" className="verifyExample" alt="verify_example" />
                    }
                    {!showExample && 
                        <img onClick={magnifyVerificationHandler} src={aggVerificationUrl(props.user.member, 'thumb')} width="180" height="240" alt="verify foto" />
                    }
                    <div className="verification_actions">

                        <div className="table-links inline" onClick={() => setCropInfo({isOpened: !cropInfo.isOpened})}>
                            <i className="fa fa-crop" aria-hidden="true"></i>
                        </div>

                        <div className="table-links inline" onClick={() => {rotateVerificationPhotoHandler(270)}}>
                        <i className="fa fa-undo" aria-hidden="true"></i>
                        </div>

                        <div className="table-links inline" onClick={() => {rotateVerificationPhotoHandler(90)}}>
                        <i className="fa fa-repeat" aria-hidden="true"></i>
                        </div>

                        <div><b>Verified by: {props.user.verify_admin}</b></div>
                        {props.user.from_webcam === '1' && 
                            <span style={{color:"red"}}>webcam</span>
                        }
                    </div>
                    <FsLightbox
                        toggler={verificationMagnified}
                        sources={[aggVerificationUrl(props.user.member)]}
                        type="image"
                        slide={1}
                    />
                </div>
            }
            {!props.user.isDeleted && 
            <div className="admin_tolls l">
                <div className="member_information_block">
                    <table id="priotites_box">
                        <tbody><tr>
                            <td className="first-td"></td>
                            <td>
                                {+user.showInFirst === 0 && 
                                    <div className="action-priority-change" onClick={() => changePriority(-1)}><i className="fa fa-star" aria-hidden="true"></i></div>
                                }
                                {+user.showInFirst === -1 && 
                                    <div className="action-priority-change-low" onClick={() => changePriority(0)}><i className="fa fa-user" aria-hidden="true"></i></div>
                                }
                            </td>
                            <td className="last-td"><div className="table-links inline" onClick={setOnlineHandler}>Set Online</div></td>
                        </tr>
                    </tbody></table>

                    {user.sex === 'female' &&
                        <div className={(limitedWriteAccess) ? 'forbidden-to-whrite' : ''}>
                            <input type="checkbox" id="limitedWriteAccess" checked={limitedWriteAccess} onChange={forbidToChatHandler} />
                            <label htmlFor="limitedWriteAccess" title="If checked - User is not allowed to start a conversation without a membership">Forbidden to start chat</label>

                            {limitedWriteAccess && 
                                <div className="forbidden-to-answer">
                                    <input type="checkbox" id="limitedAnswerAccess" checked={limitedAnswerAccess} onChange={forbidToAnswerHandler} />
                                    <label htmlFor="limitedAnswerAccess" title="If checked - User is not allowed to answer to a new conversations">Forbidden to answer</label>
                                </div>
                            }
                        </div>
                    }
                </div>

                <div className="member_information_block">
                    <div className="automessages_list_show green-text pointer" onClick={() => {setShowAutoMsgList(!showAutoMsgList)}}>Send auto message</div>
                    {showAutoMsgList &&
                    <div id="automessages_list">
                        <ul>
                            {!sentWarnings.includes('contacts_on_profile') && 
                                <li>
                                    <div className="table-links inline automessage-send" onClick={() => sendWarningMessage('contacts_on_profile')}>{props.user.admin_alerts && props.user.admin_alerts.contacts_on_profile && <span className="red">[{props.user.admin_alerts.contacts_on_profile}]</span>} Contact on profile</div>
                                </li>
                            }
                            {!sentWarnings.includes('notice') && 
                                <li>
                                    <div className="table-links inline automessage-send" onClick={() => sendWarningMessage('notice')}>{props.user.admin_alerts && props.user.admin_alerts.notice && <span className="red">[{props.user.admin_alerts.notice}]</span>} Escort in about me</div>
                                </li>
                            }
                            {!sentWarnings.includes('duplicated_profiles') && 
                                <li>
                                    <div className="table-links inline automessage-send" onClick={() => sendWarningMessage('duplicated_profiles')}>{props.user.admin_alerts && props.user.admin_alerts.duplicated_profiles && <span className="red">[{props.user.admin_alerts.duplicated_profiles}]</span>} Duplicated profiles</div>
                                </li>
                            }
                            {!sentWarnings.includes('removed_photos') && 
                                <li>
                                    <div className="table-links inline automessage-send" onClick={() => sendWarningMessage('removed_photos')}>{props.user.admin_alerts && props.user.admin_alerts.removed_photos && <span className="red">[{props.user.admin_alerts.removed_photos}]</span>} Removed photos</div>
                                </li>
                            }
                            {!sentWarnings.includes('impolite') && 
                                <li>
                                    <div className="table-links inline automessage-send" onClick={() => sendWarningMessage('impolite')}>{props.user.admin_alerts && props.user.admin_alerts.impolite && <span className="red">[{props.user.admin_alerts.impolite}]</span>} Impolite behavior</div>
                                </li>
                            }
                            {!sentWarnings.includes('prices') && 
                                <li><div className="table-links inline automessage-send" onClick={() => sendWarningEscortMessage()}>{props.user.admin_alerts && props.user.admin_alerts.prices && <span className="red">[{props.user.admin_alerts.prices}]</span>} Displayed prices</div></li>
                            }
                            </ul>
                    </div>
                    }
                </div>
                <div className="member_information_block">
                    {user.blocked !== '0' &&
                        <div className="table-links inline" onClick={unblockUserHandler}>Unblock user</div>
                    }
                    {user.blocked === '0' &&
                        <div className="table-links inline" onClick={showBlockArea}><b>Block user</b></div>
                    }
                    {showBlockMember && 
                    <div className="expandedBlockArea">
                        {userIsOnMassMail && <span className="red">This member is on mass message!<br />(Contact Audrius)</span>}
                        <table>
                            <tbody><tr>
                                <td>Reason</td>
                                <td>
                                    <select className="block_reason" onChange={blockReasonHandler}>
                                        <option value="3">Scam / Spam</option>
                                        <option value="1">Fake photos</option>
                                        <option value="8">Celebrity photos</option>
                                        <option value="2">Impolite</option>
                                        <option value="4">Escort</option>
                                        <option value="6">For contacts</option>
                                        <option value="7">Payment scam</option>
                                        <option value="9">Other</option>
                                    </select>
                                </td>
                            </tr>
                            <tr><td colSpan="2">Comment</td></tr>
                            <tr><td colSpan="2"><textarea name="comment" id="block_comment" ref={blockCommentRef}></textarea></td></tr>
                        </tbody></table>

                        {user.verified === '0' &&
                            <button
                                className="styledButton buttonVerify"
                                title="Block, ask user for verification"
                                onClick={() => blockUserHandler('verify')}
                            >Verify</button>
                        }
                        <button 
                            className="styledButton buttonBlock" 
                            title="Block, user will see an 'Account is suspended' window"
                            onClick={() => blockUserHandler('block')}
                        ><b className="red">Block</b></button>
                        <input 
                            className="fr" 
                            type="checkbox" 
                            id="block_cookie" 
                            title="Blocks cookies. All duplicates will be blocked same as this one!"
                            defaultChecked="checked"
                            onChange={blockCookieChangeHandler}
                        />
                        <label className="fr" htmlFor="block_cookie">Block duplicates</label>
                    </div>
                    }
                </div>
                <div className="member_information_block">
                    {user.hidden < 2 && <div className="table-links inline" onClick={blockForDuplicate}>Duplicated profile</div>}
                    {user.hidden === '2' && <div className="table-links inline" onClick={unblockForDuplicate}>Remove duplicated profile</div>}
                </div>
                {/* Verify girl */}
                {user.sex === 'female' && 
                    <div className="member_information_block">
                        {user.verified === '1' && 
                            <div className="table-links inline" onClick={unverifyUser}>Unverify</div>
                        }
                        {user.verified !== '1' && 
                            <div className="table-links inline" onClick={verifyUser}>Verify</div>
                        }
                    </div>
                }
                {/*Delete all photos*/}
                {props.user.photos.length > 0 && 
                    <div className="member_information_block">
                        <div className="table-links inline" onClick={deleteAllPhotos}>Delete all photos</div>
                    </div>
                }
                {/* group message */}
                {user.sex === 'female' && 
                    <div className="member_information_block">
                        <div className={`table-links inline ${userSeeGroupMessage === 1 ? '' : 'dark-grey-text'}`}>
                            {userSeeGroupMessage === 1 && <span onClick={groupMessageStatusHandler}>Has Group message</span>}
                            {userSeeGroupMessage !== 1 && <span onClick={groupMessageStatusHandler}>No group message</span>}
                        </div>
                    </div>
                }
                {/* able to reply */}
                {user.sex === 'male' && 
                    <div className="member_information_block">
                        Able to reply: <b>{ (user.showInFirst === '0') ? 'Yes' : 'No' }</b>
                    </div>
                }

                <div id="user_update_holder">
                    {user.sex === 'female' && 
                    <>
                        <input id="confirm_only_tg" type="checkbox" name="only-tg" onChange={tgVisibilityHandler} defaultChecked={tgVisibility} />
                        <label htmlFor="confirm_only_tg" className="labelTg">TG</label>
                        <input id="confirm_only_tf" type="checkbox" name="only-tf" onChange={tfVisibilityHandler} defaultChecked={tfVisibility} />
                        <label htmlFor="confirm_only_tf" className="labelTf">TF</label>
                    </>
                    }

                    <button className="styledButton button_update fl" onClick={() => {props.onConfirmUpdates(tgVisibility, tfVisibility)}}>Confirm</button>
                </div>
                <div>
                    <button className="styledButton button_skip fl" onClick={props.onSkipUpdates}>Skip</button>
                </div>
                {cropInfo.isOpened && 
                    <Crop
                        photo={aggVerificationUrl(user)} 
                        isOpened={cropInfo.isOpened} 
                        onCropSave={(coords) => cropHandler(coords)}
                        onClose={() => setCropInfo({isOpened: false})}
                        isSquare={false}
                    />
                }
            </div>
            }
        </div>
    )
}

export default RightSide