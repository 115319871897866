import {Fragment, useEffect, useLayoutEffect, useRef, useState} from "react";

import Classes from "./WantVisit.module.scss";

const WantVisit = ({list}) => {
    const ref = useRef();
    const [height, setHeight] = useState(0);
    const [hasMore, setMore] = useState(false);
    const [showMore, setShowMore] = useState(false);

    useLayoutEffect(() => setHeight(ref.current.clientHeight), []);

    useEffect(() => {
        if (height > 20) {
            setMore(true);
        }
    }, [height])


    const containerClasses = [Classes.Container];

    if (hasMore && !showMore) {
        containerClasses.push(Classes.ContainerWithOverflow)
    }

    return (
        <Fragment>
            <div className={containerClasses.join(' ')} ref={ref}>
                {list.map((m, i) => (<div key={i} className={Classes.Item}>{m.name}</div>))}
            </div>

            {hasMore && (
                <button
                    className={Classes.ButtonShowMore}
                    onClick={() => setShowMore(!showMore)}>
                    {showMore ? 'Show less' : 'Show More'}
                </button>
            )}
        </Fragment>
    );
}

export default WantVisit;
