import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import apiAdmin from "../../api/admin";

const Statistics = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {countryList} = useSelector(s => s.auth.config);

    const getStatisticsData = (data = {}) => {
        setIsLoading(true);

        apiAdmin.get('finance/statistics', {params: data}).then((r) => {
            setList(current => [...current, ...r.data]);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getStatisticsData();
    }, []);

    const onSubmit = (e) => {
        setList([]);
        e.preventDefault();
        const data = {
            app: e.target.app.value,
            provider: e.target.provider.value,
            sex: e.target.sex.value,
            country: e.target.country.value,
            date_from: e.target.date_from.value,
            date_to: e.target.date_to.value,
            interval: e.target.period.value
        };
        getStatisticsData(data);
    }

    return (
        <div>
            <h1>Statistics</h1>

            <form onSubmit={onSubmit} className="filters" style={{fontSize:'10px'}}>
                <span>App: </span>
                <select name="app">
                    <option value="">All</option>
                    <option value="tg">TravelGirls</option>
                    <option value="tf">TravelFriend</option>
                </select>
                <span> Provider: </span>
                <select name="provider" style={{width: '75px'}}>
                    <option value="">All</option>
                    <option value="CCBill">CCBill</option>
                    <option value="SegPay">SegPay</option>
                    <option value="hipay">Hipay</option>
                    <option value="appStore">AppStore</option>
                    <option value="tg-Android">Android</option>
                    <option value="tm-Android">Andtroid Travel!</option>
                </select>
                <span> Sex:</span>
                <select name="sex">
                    <option value="">All</option>
                    <option value="tg">Male</option>
                    <option value="tf">Female</option>
                </select>
                <span> Country:</span>
                <select name="country" style={{width: '85px'}}>
                    <option value="">-</option>
                    {
                        Object.entries(countryList).map(([code, countryName]) => (
                            <option key={code} value={code}>{countryName}</option>
                        ))
                    }
                </select>
                <span> Date from:</span>
                <input type="text" name="date_from" defaultValue="" placeholder="yyyy-mm-dd" style={{width: '80px'}}></input>
                <span> Date to:</span>
                <input type="text" name="date_to" defaultValue="" placeholder="yyyy-mm-dd" style={{width: '80px'}}></input>
                <span> Interval:</span>
                <select name="period">
                    <option value="day">Day</option>
                    <option value="month">Month</option>
                </select>
                <button type={"submit"}> Filter</button>
            </form>

            <table className="mt-10 dimensionsTable statisticsTable">
                <thead>
                    <tr>
                        <th colSpan={9}>Real Money</th>
                        <th colSpan={7}>Fact Money</th>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <th>Reg</th>
                        <th>Trial</th>
                        <th>Purchase</th>
                        <th>Rebills</th>
                        <th>Cancel</th>
                        <th>Refund</th>
                        <th>Charge</th>
                        <th>Income</th>
                        <th>Trial</th>
                        <th>Purchase</th>
                        <th>Rebills</th>
                        <th>Cancel</th>
                        <th>Refund</th>
                        <th>Charge</th>
                        <th>Income</th>
                    </tr>
                </thead>
                <tbody>
                {list &&
                list.map((item, i) => {
                    return (
                        <tr key={i}>
                            <td>{item.registrationDate}</td>
                            <td>{item.registrations}</td>
                            <td>{item.realTrial}</td>
                            <td>{item.realFirstUserPayment}</td>
                            <td>{item.realRebill}</td>
                            <td>{item.realCancel}</td>
                            <td>{item.realRefund}</td>
                            <td>{item.realChargeback}</td>
                            <td>{item.realIncome}€</td>
                            <td>{item.factTrial}</td>
                            <td>{item.factFirstUserPayment}</td>
                            <td>{item.factRebill}</td>
                            <td>{item.factCancel}</td>
                            <td>{item.factRefund}</td>
                            <td>{item.factChargeback}</td>
                            <td>{item.factIncome}€</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default Statistics;
