import {useState, useRef, useEffect} from "react";
import {useParams} from "react-router-dom";
import {Textarea} from "@mui/joy";

//import useAutosizeTextArea from "../../helpers/useAutosizeTextarea";
import Classes from "./Chat.module.scss";
import apiAdmin from "../../api/admin";
import {aggregateText} from "../../helpers";
import {timeAgo} from "../../helpers/dates";
import AdminFavorites from "../../common/AdminFavorites/AdminFavorites";
import ButtonLink from "../../common/ButtonLink/ButtonLink";
import Button from "../../common/Button/Button";
import {Tooltip} from "react-tooltip";
import OnlineIndicator from "../../common/OnlineIndicator/OnlineIndicator";
import aggSquarePhoto from "../../helpers/photos";
import {messageTemplates, getMessage} from "./messagesTemplatesConfig";
import {aggProfileUrl} from "../../helpers/profile";
import Attachments from "../../common/Attachments";

const Chat = () => {
    const {id} = useParams();
    const [firstLoad, setFirstLoad] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [admin, setAdmin] = useState(null);
    const [favoritedAdminId, setFavoritedAdminId] = useState(null);
    const [list, setList] = useState([]);
    const [hasMore, setHasMore] = useState([]);
    const [isAttLoading, setAttLoading] = useState(false);

    const [message, setMessage] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [showHelpers, setHelpers] = useState(false);

    const getMessages = (loadMore = false) => {
        setLoading(true);
        const messageId = loadMore && list ? list[list.length-1].id : null;

        apiAdmin.get('conversations/supportConversation/'+id+'?messageId='+messageId).then((r) => {
            if (firstLoad) {
                setFirstLoad(false);

                setAdmin(r.data.admin);
                setUser(r.data.user);
                setFavoritedAdminId(r.data.favoritedAdminId);

                if (r.data.user) {
                    setMessage(getMessage(r.data.user.name));
                }
            }
            setList([...list, ...r.data.messageList.messages]);
            setHasMore(r.data.messageList.hasMore);

            setLoading(false);
        })
    }

    useEffect(getMessages, [])

    const onTemplateClick = (content) => {
        setMessage(content.replace('%name%', user.name));
        setHelpers(false);
    }

    const onDelete = (messageId) => {
        setList(list.filter(m => m.id !== messageId));
        apiAdmin.post('conversations/delete_message/'+messageId+'/'+user.id).then((r) => {
            if (!r.data.isDeleted) {
                alert('Error. Refresh this page manually and make sure message is deleted');
            }
        });
    }

    const showSendError = () => alert('Error. Message is not sent!');
    const onSend = () => {
        apiAdmin.post('conversations/send/'+id, {message: message, attachments: attachments}).then(r => {
            if (r.data.isSent) {
                window.location.replace(process.env.REACT_APP_MP+'/conversations');
            }
            else {
                showSendError();
            }
        }).catch(error => {
            console.log(error);
            showSendError();
        })
    }

    const onAttach = (e) => {
        if (isAttLoading) {
            return;
        }
        setAttLoading(true);

        let fd = new FormData();
        fd.append("attachment", e.target.files[0]);

        apiAdmin.post('/conversations/attachment', fd, {
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(r => {
            setAttLoading(false);
            alert('Temporary does not work! Contact Darius');
            // TODO Actions
            //updateUserAttachments(r.data);
        }).catch(error => {
            alert('Upload error. Try attach another photo.');
            setAttLoading(false);
        });
    }

    if (firstLoad && isLoading) {
        return <div className="loader" />
    }

    const pageStyle = user && user.app === 'tf'
        ? {background: '#d8e9f5'}
        : user && user.app === 'tm' ? {background: '#9cacfb'} : {};

    return (
        <div>
            <div className={Classes.PageBackground} style={pageStyle} />
            <div className={Classes.Header}>
                {user && <AdminFavorites favoritedAdminId={favoritedAdminId} userId={user.id} />}
                <h2>
                    Chat with <a href={aggProfileUrl(id)}>{user ? user.name : 'Deleted acc'}</a>
                    {user && user.isDeleted && ( <span style={{marginLeft: '5px', fontSize: '12px', color: '#666'}}>(deleted)</span>)}
                </h2>
                <button
                    className={Classes.ButtonBack}
                    onClick={() => window.location = (process.env.REACT_APP_MP+'/conversations')}
                >← Back</button>
            </div>
            {user && !user.isDeleted && (
                <div className={Classes.ChatArea}>
                    <div>
                        <div style={{position: 'relative', textAlign: 'right'}}>
                            <ButtonLink onClick={() => setHelpers(!showHelpers)}>Message Templates</ButtonLink>
                            {showHelpers && <Helpers onClick={onTemplateClick} />}
                        </div>
                        <Textarea
                            className={Classes.Textarea}
                            minRows={4}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <div className={Classes.Actions}>
                            <div className={Classes.ActionAttach}>
                                <form className={Classes.UploadAttForm}>
                                    {isAttLoading ? (<div className={['loader', Classes.AttachmentLoader].join(' ')} style={{width: '10px', height: '10px', margin: '0'}} />) : <i className="fa fa-camera" aria-hidden="true" /> }
                                    <input type="file" id="photo_datafile" onChange={onAttach} name="file_upload" className={Classes.FileInput} />
                                </form>
                            </div>

                            <Button onClick={onSend}>Send</Button>
                        </div>
                    </div>
                </div>
            )}
            <div className={Classes.MessageList}>
                {list.map(m => <MessageItem
                    key={m.id}
                    id={m.id}
                    content={m.content}
                    sentAt={m.sentAt}
                    user={m.senderId === admin.id ? admin : user}
                    supportData={m.supportData}
                    onDelete={() => onDelete(m.id)}
                    myMessage = {m.senderId === admin.id}
                    attachments={m.attachments}
                    itsMe={m.senderId === admin.id}
                />)}
            </div>
            {hasMore && (
                <div className={Classes.LoadMoreArea}>
                    {!isLoading && (
                        <Button style={{width: '200px'}} onClick={() => getMessages(true)}>
                            Load more messages
                        </Button>
                    )}
                    {isLoading && <div className={"loader"} />}
                </div>
            )}
        </div>
    );
}

const MessageItem = ({content, sentAt, id, user, supportData, onDelete, myMessage, attachments, itsMe = false}) => {
    const time = new Date(sentAt * 1000).toLocaleString();
    const [showMessageInfo, setShowMessageInfo] = useState(false);
    const [isDeleting, setDeleting] = useState(false);

    const onDeleteAction = () => {
        if (window.confirm('Are you sure want to delete this message?')) {
            setDeleting(true);
            onDelete();
        }
    }

    return (
        <div className={myMessage ? [Classes.Item, Classes.Mine].join(' ') : Classes.Item}>
            <UserArea user={user} itsMe={itsMe} />
            <div className={Classes.MessageArea}>
                <div dangerouslySetInnerHTML={{__html: aggregateText(content)}} />
                <Attachments list={attachments} />
                {showMessageInfo && <MessageInfo {...supportData} />}
                {supportData.msgId && (
                    <div className={Classes.MoreInfoButton} onClick={() => setShowMessageInfo(!showMessageInfo)}>
                        <i className="fa fa-plus" aria-hidden="true" />
                    </div>
                )}

                <div className={Classes.DeleteButton}>
                    <ButtonLink onClick={onDeleteAction}>
                        <i className="fa fa-trash" aria-hidden="true" />
                    </ButtonLink>
                </div>

                <div className={Classes.TimeArea} id={"for-tooltip-"+id} data-tooltip-content={time}>{timeAgo(sentAt)}</div>
                <Tooltip anchorId={"for-tooltip-"+id} place={"left"} style={{maxWidth: '200px', padding: '10px'}} />
            </div>
            {isDeleting && <div className={Classes.DeletingOverflow}><div className="loader" /></div>}
        </div>
    );
}

const MessageInfo = ({app, appInfo, ip, browserInfo}) => (
    <div className={Classes.MessageInfo}>
        <div>Info</div>
        <div>{app}, {appInfo}, {ip}</div>
        <div>Browser</div>
        <div>{browserInfo}</div>
    </div>
);

const UserArea = ({user, itsMe}) => {
    if (!user) {
        return null;
    }

    return (
        <div className={Classes.UserArea}>
            <a href={aggProfileUrl(user.id)} className={Classes.Link}>
                <img className={Classes.UserImage} src={aggSquarePhoto(user.photo, 100, user)} alt={"Avatar"} />
                <div>
                    <div className={Classes.UsertextArea}>
                        {user.name}
                        {!itsMe && <OnlineIndicator isOnline={user.isOnline} />}
                    </div>
                    {user.isBlocked && <div className={[Classes.UsertextArea, Classes.Blocked].join(' ')}>Blocked</div>}
                    {user.isHidden && <div className={[Classes.UsertextArea, Classes.Hidden].join(' ')}>Hidden</div>}
                    {!itsMe && user.isVerified && <div className={[Classes.UsertextArea, Classes.Verified].join(' ')}>Verified</div>}
                </div>
            </a>
        </div>
    );
}

const Helpers = ({onClick}) => (
    <div className={Classes.HelpersWrapper}>
        {messageTemplates.map(m => (
            <ButtonLink style={{textAlign: 'left'}} key={m.id} onClick={() => onClick(m.content)}>{m.title}</ButtonLink>)
        )}
    </div>
);

export default Chat;
