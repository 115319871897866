import { useEffect, useState } from 'react';
import apiAdmin from "../../api/admin";

const Dimensions = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDimensionsData = (data = {}) => {
        setIsLoading(true);     

        apiAdmin.get('finance/dimensions', {params: data}).then((r) => {
            setList(current => [...current, ...r.data]);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getDimensionsData();
    }, []);

    const onSubmit = (e) => {
        setList([]);
        e.preventDefault();
        const data = {
            period: e.target.period.value
        };
        getDimensionsData(data);
    }

    const loadMoreHandler = () => {
        getDimensionsData({from: list.length});
    }
    
    return (
        <div>
            <h1>Dimensions</h1>

            <form onSubmit={onSubmit} className="filters">
                <select name="period">
                    <option value="day">Days</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                </select>
                <button type={"submit"}>Filter</button>
            </form>

            <table className="mt-10 dimensionsTable">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>GC</th>
                        <th>MC</th>
                        <th>SC</th>
                        <th>Rebills</th>
                        <th>Pr.</th>
                        <th>Income</th>
                        {/*<th>Cost</th>
                        <th>Profit</th>*/}
                        <th>Growth</th>
                    </tr>
                </thead>
                <tbody>
                {list && 
                list.map((item, i) => {
                    let subscribesDiff = item.subscribe_new - item.subscribe_deleted;
                    let subscribesDiffClass = (subscribesDiff > 0) ? 'green' : 'red';
                    let growthClass = (item.growth > 0) ? 'green' : 'red';
                    return (
                        <tr key={i}>
                            <td>{item.dateFormated}</td>
                            <td title={`New: ${item.female_new} Deleted:${item.female_deleted}`}>{item.female_new - item.female_deleted}</td>
                            <td title={`New: ${item.male_new} Deleted:${item.male_deleted}`}>{item.male_new - item.male_deleted}</td>
                            <td title={`New: ${item.subscribe_new} Repeat:${item.subscribe_old} Cancel: ${item.subscribe_deleted}`} className={subscribesDiffClass}>{subscribesDiff}</td>
                            <td>{item.rebills}</td>
                            <td>{item.premium}</td>
                            <td>{item.income} €</td>
                            {/*<td>{item.cost}</td>
                            <td>{item.income - item.cost}</td>*/}
                            <td className={growthClass}>{item.growth} %</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            <div className="table-links" onClick={loadMoreHandler}><b>Show more</b></div>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default Dimensions;
