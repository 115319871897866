import {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import "react-tooltip/dist/react-tooltip.css";

import styles from "./styles.module.scss";
import adminApi from "../../../api/admin";
import {aboutMeText} from "../../../helpers/text";
import Modal from "../../../common/Modal/Modal";
import Avatar from "../../../common/Avatar/Avatar";

const types = [
    {id: 1, name: 'Pending'},
    {id: 2, name: 'Accepted'},
    {id: 0, name: 'Declined'}
];

const defaultDeclineMessage =
    "Hello,\n" +
    "Please understand that our site is not a platform for escort activity.\n"+
    "It is designed for relaxation not for work.\n\n"+
    "Best regards,\n"+
    "Justina";

const GroupMessage = () => {
    const [isLoaded, setLoaded] = useState(false);
    const [list, setList] = useState([]);
    const [filter, setFilter] = useState({selectedId: types[0].id, showBlocked: false});

    const getList = (f) => {
        setList([]);
        setLoaded(false);

        adminApi.get('group_messages/get?type='+f.selectedId+'&include_blocked='+f.showBlocked).then((r) => {
            setList(r.data.list);
            setLoaded(true);
        });
    }

    useEffect(() => {
        getList(filter);
    }, [filter]);

    const onRemoveItemHandler = (id) => {
        const newArray = list.filter(l => l.groupMessage.id !== id);
        setList(newArray);
    }

    return (
        <div>
            <h2>Group message administration</h2>
            <Filter filter={filter} setFilter={setFilter} />
            <Results isLoaded={isLoaded} list={list} onRemove={onRemoveItemHandler} />
        </div>
  );
}

const Filter = ({filter, setFilter}) => {
    const onSelect = (e) => setFilter({...filter, selectedId: parseInt(e.target.value)});
    const onCheck = (e) => setFilter({...filter, showBlocked: e.target.checked});

    return (
        <div className={styles.Filter}>
            <select onChange={onSelect}>
                {types.map(i => <option key={i.id} value={i.id}>{i.name}</option>)}
            </select>
            <div className={styles.AreaBlocked}>
                <input type="checkbox" id="blocked" name="blocked" onChange={onCheck} />
                <label htmlFor="blocked">Show blocked group messages</label>
            </div>
        </div>
    );
}

const Results = ({isLoaded, list, onRemove}) => {
    if (isLoaded && list.length === 0) {
        return <p>List is empty</p>
    }

    return (
        <div>
            <div className={[styles.Head, styles.Table].join(' ')}>
                <div className={styles.CellUnread}></div>
                <div className={styles.CellProfile}>Profile</div>
                <div className={styles.CellAgeFrom}>From</div>
                <div className={styles.CellAgeTo}>To</div>
                <div className={styles.CellCountries}>Countries</div>
                <div className={styles.CellContent}>Content</div>
                <div className={styles.CellStatus}>Status</div>
            </div>
            {list.map(i => <Item key={i.groupMessage.id} data={i} onRemove={onRemove}/>)}
        </div>
    );
}

const Item = ({data, onRemove}) => {
    const [modalOpened, setModalOpened] = useState(false);
    const addMessageUrl = process.env.REACT_APP_MP+'/mass-message-create?id='+data.groupMessage.id;

    const onAccept = (e) => {
        e.preventDefault();
        adminApi.post('group_messages/accept/'+data.groupMessage.id).then(() => {
            onRemove(data.groupMessage.id);
        });
    }

    const onDecline = (e) => {
        e.preventDefault();
        setModalOpened(true);
    }

    const onDeclineSubmit = (e) => {
        e.preventDefault();
        setModalOpened(false);

        adminApi.post('group_messages/decline/'+data.groupMessage.id+'/'+data.user.id, {
            message: e.target.message.value,
            sendMessage: e.target.sendMessage.checked
        }).then(() => {
            onRemove(data.groupMessage.id);
        });
    }

    return (
        <Fragment>
        {modalOpened && (
            <Modal isOpened={modalOpened} onClose={() => setModalOpened(false)}>
                <DeclineWindow onSubmit={onDeclineSubmit} />
            </Modal>
        )}
        <div className={[styles.Table, styles.Row].join(' ')}>
            <div className={styles.CellUnread}>{data.unreadCount}</div>
            <div className={styles.CellProfile}>
                <Avatar user={data.user} />
            </div>
            <div className={styles.CellAgeFrom}>{data.groupMessage.ageFrom}</div>
            <div className={styles.CellAgeTo}>{data.groupMessage.ageTo}</div>
            <div className={styles.CellCountries}><MulitpleSelect countryList={data.groupMessage.countryList} /></div>
            <div className={styles.CellContent} dangerouslySetInnerHTML={{__html: aboutMeText(data.groupMessage.content)}} />
            <div className={styles.CellStatus}>
                <div style={{marginBottom: '10px'}}>{types.find(el => el.id === data.groupMessage.type)['name']}</div>
                <a href={addMessageUrl} className={`${styles.Button} green-button-simple`}>Add</a>
                {data.groupMessage.type === 1 && (
                    <div style={{marginTop: '10px'}}>
                        <button onClick={onAccept}>Accept</button><br />
                        <button onClick={onDecline}>Decline</button>
                    </div>
                )}

                {data.groupMessage.type === 0 && (
                    <div>
                        <b id="decline-reason" data-tooltip-content={data.groupMessage.declineReason}>Reason</b>
                        <ReactTooltip
                            anchorId="decline-reason"
                            place={"bottom"}
                            style={{maxWidth: '200px', padding: '20px'}}
                        />
                    </div>
                )}
            </div>
        </div>
        </Fragment>
    );
}

const MulitpleSelect = ({countryList}) => {
    const countries = useSelector(state => state.auth.config.countryList);
    return (
        <Fragment>
            <select multiple={true} className={styles.MultipleSelectox} disabled>
                {countryList.map(code => <option key={code}>{countries[code]}</option>)}
            </select>
            <span>{countryList.length} of {Object.entries(countries).length} selected</span>
        </Fragment>
    );
}

const DeclineWindow = ({onSubmit}) => {
    return (
        <form style={{padding: '10px 0'}} onSubmit={onSubmit}>
            <textarea name={'message'} defaultValue={defaultDeclineMessage} />
            <div style={{padding: '10px 0'}}>
                <input type="checkbox" id="sendMessage" name="sendMessage" onChange={() => {}} />
                <label htmlFor="sendMessage">Send message to user</label>
            </div>
            <button type={"submit"}>Decline</button>
        </form>
    );
}

export default GroupMessage;
