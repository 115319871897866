import {useState} from "react";
import {useSelector} from "react-redux";

import adminApi from "../../api/admin";
import {adminColors} from "./adminColors";
import Classes from "./AdminFavorites.module.scss";

const getColor = (id) => {
    return (id === null || !(id in adminColors)) ? adminColors['default'] : adminColors[id];
}

const AdminFavorites = ({userId, favoritedAdminId}) => {
    const [id, setId] = useState(favoritedAdminId);

    const defaultStyle = {color: (!id) ? 'inherit' : getColor(id)};
    const [style, setStyle] = useState(defaultStyle);

    const adminId = useSelector(s => s.auth.user.id);
    const hoverStyle = {color: getColor(adminId)}

    const onToggle = () => {
        const action = (id) ? 'remove' : 'add';
        if (action === 'add') {
            setId(adminId);
        }
        else {
            setId(null);
        }
        adminApi.post('conversations/favorites/'+action+'/'+adminId, {userId: userId});
    }

    return (
        <button
            className={Classes.Favorites}
            onMouseEnter={() => setStyle(hoverStyle)}
            onMouseLeave={() => setStyle(defaultStyle)}
            onClick={onToggle}>
            {!id ? <i className="fa fa-star-o" style={style} /> : <i className="fa fa-star" style={style} />}
        </button>
    );
}

export default AdminFavorites;
