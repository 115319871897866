import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import { useDispatch } from "react-redux";

import apiAdmin from "../../api/admin";
import { getAdminBox } from "../../store/admin-box";
import Header from "./Header";
import TopInfo from "./TopInfo";
import MainInfo from "./MainInfo";
import RightSide from "./RightSide";
import Photos from "./Photos"
import UnwantedUsers from "../Fundamentals/UnwantedUsers/UnwantedUsers";
import PhotosDeleted from "./PhotosDeleted";

const UserProfile = () => {
    const {userId} = useParams();
    const nav = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [openedMainPhoto, setOpenedMainPhoto] = useState(false);
    const [updatedSex, setUpdatedSex] = useState(null);

    const getUser = () => {
        setIsLoading(true);

        apiAdmin.get('users/get_profile/'+userId).then((r) => {
            if (r.data.error) {
                setError(r.data.error);
                setIsLoading(false);
                return;
            }
            setUser(r.data);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        getUser();
        dispatch(getAdminBox());
    }, [userId]);

    const updateUserHandler = (data) => {
        const keyName = Object.keys(data)[0];

        apiAdmin.post('users/update/' + userId, data).then((r) => {
            if (keyName === 'sex') {
                setUpdatedSex(user.member.sex);
            }
            if (keyName !== 'age') {
                updateUserState(data);
            }
        })
    }

    const updateUserState = (data) => {
        const keyName = Object.keys(data)[0];
        const value = Object.values(data)[0];
        setUser({
            ...user,
            member: {
                ...user.member,
                [keyName]: value,
            }
        });
    }

    const refreshUser = (status) => {
        setIsRefreshing(status);
    }

    const skipUserHandler = () => {
        let updatinSex = updatedSex ? updatedSex : user.member.sex;
        apiAdmin.post('users/skip_update/', {userId: user.member.ID, sex: updatinSex}).then((r) => {
            if (r.data.newUser) {
                nav('/user/'+r.data.newUser);
            } else {
                alert('No more updates');
            }
        });
    }

    const confirmUpdatesHandler = (tgVisibility, tfVisibility) => {
        let postData = {
            userId: user.member.ID,
            profileUpdatesIds: user.updates.udpates_ids,
            tgVisibility: tgVisibility,
            tfVisibility: tfVisibility,
            sex: updatedSex ? updatedSex : user.member.sex
        };
        apiAdmin.post('users/confirm_updates/', postData).then((r) => {
            if (r.data.newUser) {
                nav('/user/'+r.data.newUser);
            } else {
                alert('No more updates');
            }
        });
    }

    const openMainPhoto = () => {
        setOpenedMainPhoto(true);
    }

    const closeMainPhoto = () => {
        setOpenedMainPhoto(false);
    }

    return (
        <>
            {isLoading && <div className="loader" />}
            {!isLoading && error && <div>{error}</div>}
            {!isLoading && user &&
                <div className={user.isDeleted ? 'deletedProfile' : ''}>
                    {isRefreshing && <div className="RefreshingUser"></div>}
                    <Header user={user.member} onUpdate={updateUserState} onReloadUser={getUser} />
                    <TopInfo user={user} onReloadUser={getUser}/>
                    <RightSide
                        user={user}
                        onConfirmUpdates={confirmUpdatesHandler}
                        onSkipUpdates={skipUserHandler}
                        onUserUpdate={updateUserState}
                        onUserRefresh={refreshUser}
                        onReloadUser={getUser}
                    />
                    <MainInfo user={user} onUserUpdate={updateUserHandler} onMainPhotoOpen={openMainPhoto}/>
                    <Photos
                        user={user}
                        openedMainPhoto={openedMainPhoto}
                        onReloadUser={getUser}
                        onCloseMainPhoto={closeMainPhoto}
                    />
                    <PhotosDeleted id={user.member.ID} />

                    <div style={{display: 'block', clear: 'both', marginBottom: '30px'}} />
                    <UnwantedInfo userId={user.member.ID} />
                </div>
            }
        </>
    )
}

const UnwantedInfo = ({userId}) => {
    const [isHidden, setHidden] = useState(true);

    return (
        <div style={{margin: '0 0 30px 0'}}>
            {isHidden && <button onClick={() => setHidden(false)}>Show Unwanted Info</button>}
            {!isHidden && <UnwantedUsers userId={userId} />}
        </div>
    )
}

export default UserProfile;
