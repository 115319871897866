import {Fragment, useEffect, useState} from "react";

import adminApi from "../../api/admin";
import Logo from "../../assets/user/tg-icon.png";
import TfLogo from "../../assets/user/tf-icon.png";
import TravelLogo from "../../assets/user/travel-icon.png";
import { aggDateFromTimestamp } from "../../helpers";
import Modal from "../../common/Modal/Modal";
import Styles from "./Header.module.scss";
import {getToken} from "../../helpers/authentication";
import {API_BASE_URL} from "../../config/config";

const Header = ({user, onUpdate, onReloadUser}) => {
    const [showInfoBlock, setShowInfoBlock] = useState(false);
    const [unreadCount, setUnreadCount] = useState(0);
    const [payments, setPayments] = useState(null);
    const [showPayments, setShowPayments] = useState(true);
    const [showFullRef, setShowFullRef] = useState(false);
    const [showFullLanding, setShowFullLanding] = useState(false);
    const [modalPrivileges, setModalPrivileges] = useState(false);
    const [modalSettings, setModalSettings] = useState(false);

    let logoImg = Logo;
    let headerClass = '';
    if (user.app === 'tf') {
        logoImg = TfLogo;
        headerClass = 'tf-header';
    }
    if (user.app === 'tm') {
        logoImg = TravelLogo;
        headerClass = 'tm-header';
    }

    const getUnreadMessages = () => {
        adminApi.get('users/get_unread_count/'+user.ID).then((r) => {
            setUnreadCount(r.data.count);
        });
    }

    const getPayments = () => {
        adminApi.get('users/get_payments/'+user.ID).then((r) => {
            setPayments(r.data);
        });
    }

    useEffect(()=> {
        getUnreadMessages();
        getPayments();
    },[]);

    const handleKeyDown = (e) => {
        if (e.altKey && e.keyCode === 65) {
            setShowInfoBlock(prevState => !prevState);
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown',handleKeyDown);
        }

    }, []);

    const removeUserHandler = () => {
        if (window.confirm('Are you sure you want to remove this user?')) {
            adminApi.get('users/remove_user/'+user.ID).then((r) => {
                onReloadUser();
            });
        }
    }

    const restoreUserHandler = () => {
        if (window.confirm('Are you sure you want to restore deleted user?')) {
            adminApi.get('users/restore_deleted_user/'+user.ID).then((r) => {
                onReloadUser();
            });
        }
    }

    const cancelSubscriptionHandler = (subscriptionId) => {
        if (window.confirm('Are you sure you want to cancel subscription?')) {
            adminApi.post('payments/cancel/', {subId:subscriptionId }).then((r) => {
                if (r.data.cancel === true) {
                    onReloadUser();
                } else {
                    if (r.data.error) {
                        alert(r.data.error);
                    } else {
                        alert('Failed to cancel subscription. Or already canceled.');
                    }
                }
            }).catch(error => {
                alert('Something went wrong');
            });
        }
    }

    const urlLogin = API_BASE_URL+`auth/admlogin/${user.ID}/${user.app}?token=${getToken()}`;

    const shortUrl = (url) => {
        return (url) ? url.substring(0, 80) : null;
    }
    return (
        <Fragment>
            {modalPrivileges && (
                <Modal isOpened={modalPrivileges} onClose={() => setModalPrivileges(false)}>
                    <PrivilegesWindow user={user} />
                </Modal>
            )}

            {modalSettings && (
                <Modal isOpened={modalSettings} onClose={() => setModalSettings(false)}>
                    <EditEmailPassword user={user} onUpdate={onUpdate} />
                </Modal>
            )}

            <div className={`user-info-header ${headerClass}`}>
                <div className="little-logo" onClick={() => {setShowInfoBlock(!showInfoBlock)}}>
                    <img src={logoImg} alt="logo" style={{width: "24px"}} />
                </div>

                <div className="reg-info">
                    <UserStatusComponent user={user} payments={payments} />
                    <div>reg. Date: <b>{user.regDate}</b></div>
                    <div>reg. Type: <b>{user.registrationType}</b></div>
                </div>
                {!user.deleteDate &&
                    <div className="fr header-right-side">
                        <button onClick={() => setModalPrivileges(true)} className="link">Set privileges</button> |
                        <button onClick={() => setModalSettings(true)} className="link">Edit user</button> |&nbsp;
                        <div className={Styles.LoginAsName}><a href={urlLogin} rel="noreferrer" target="_blank" className="link">Login as {user.name}</a></div> |&nbsp;
                        <span className="link" onClick={removeUserHandler}>
                            Remove user <i className="fa fa-trash" style={{fontSize: "14px", lineHeight: "12px"}} />
                        </span>
                    </div>
                }
                {user.deleteDate &&
                    <div className="fr header-right-side">
                        <span className="link" onClick={restoreUserHandler}>Restore user</span>
                    </div>
                }
                {showInfoBlock &&
                <div id="user_information_block">
                    <table>
                        <tbody>
                        <tr>
                            <td style={{width:"120px"}}><b>Registration date:</b></td>
                            <td style={{width:"300px"}}>{user.regDate}</td>
                            <td style={{width:"120px"}}><b>First visit date:</b></td>
                            <td>{user.visitDate}</td>
                        </tr>
                        <tr>
                            <td valign="top"><b>Online:</b></td>
                            <td valign="top">{user.lastLogin}</td>
                            <td valign="top"><b>Last update</b></td>
                            <td><a href="/member/{user.admin_updated_ID}">{user.admin_updated_name}</a><br />[{user.admin_update_date}]</td>
                        </tr>
                        <tr>
                            <td valign="top">Ref URL:</td>
                            <td colSpan="3">
                                <div className="table-links" onClick={() => {setShowFullRef(!showFullRef)}}>{shortUrl(user.ref_url)}</div>
                                {showFullRef &&
                                    <div className="wrap_text">{user.ref_url}</div>
                                }
                            </td>
                        </tr>
                        <tr>
                            <td valign="top">Landing URL:</td>
                            <td colSpan="3">
                                <div className="table-links" onClick={() => {setShowFullLanding(!showFullLanding)}}>{shortUrl(user.landing_page)}</div>
                                {showFullLanding &&
                                    <div className="wrap_text">{user.landing_page}</div>
                                }
                            </td>
                        </tr>

                        <tr>
                            <td>Unread messages:</td>
                            <td colSpan="3" height="18" className="user_unread_messages"><b>{unreadCount}</b></td>
                        </tr>
                        {payments.length > 0 &&
                        <>
                            <tr className="paymentsBlock">
                                <td>
                                    <i className="fa fa-money" aria-hidden="true" />
                                    <div className="table-links inline" onClick={() => {setShowPayments(!showPayments)}}>Show payments</div>
                                </td>
                                <td colSpan="3" height="18"></td>
                            </tr>

                            {showPayments && payments.map((item, i) => {
                                let currency = (item.currency === 'USD') ? '$' : '€';

                                return (
                                    <tr key={i}><td colSpan={6} className="paymentsHistory">
                                        <span style={{width:"50px"}}>{item.provider}</span>
                                        <span style={{width:"125px"}}>{aggDateFromTimestamp(item.createdAt)}</span>
                                        <span style={{width:"70px"}} className={(item.type === 'payment') ? 'green-text' : 'red'}>{item.type}&nbsp;</span>
                                        <span style={{width:"230px"}}>{item.subId}&nbsp;{item.ableToCanceled ? (<span className="link table-links" onClick={() => cancelSubscriptionHandler(item.subId)}>Cancel</span>) : ''}</span>
                                        <span style={{width:"195px"}}>{item.tranId}&nbsp;</span>
                                        <span>{(item.type !== 'cancel') ? (item.amount + ' ' + currency) : ''}</span>
                                    </td></tr>
                                )
                            })}

                        </>
                        }

                    </tbody></table>
                </div>
                }
            </div>
        </Fragment>
    );
}

const PrivilegesWindow = ({user}) => {
    const [days, setDays] = useState(3);
    const [isLoading, setLoading] = useState(false);
    const action = Number(user.level) > 0 ? 'extend' : 'add';

    const onUpdate = (action, days = 1) => {
        setLoading(true);
        adminApi.post('users/set_membership/'+user.ID, {action: action, days: days}).then((r) => {
            window.location.reload();
        });
    }

    return (
        <div className={Styles.PrivilegesModal}>
            {isLoading && <div className={Styles.LoaderOpacity}><div className={"loader"}/></div>}
            <div className={Styles.ActionsArea}>
                <p><b>Privileges</b></p>
                <button className={Styles.ButtonLink} onClick={() => onUpdate(action, 7)}>{action} 1 week</button>
                <button className={Styles.ButtonLink} onClick={() => onUpdate(action, 31)}>{action} 1 month</button>
                <button className={Styles.ButtonLink} onClick={() => onUpdate(action, 3650)}>{action} All time</button>
                <p>
                    <input type={"text"} value={days} onChange={(e) => setDays(e.target.value)} style={{width: '50px', marginRight: '10px'}} />
                    <button onClick={() => onUpdate(action, days)} style={{textTransform: 'capitalize'}}>{action} premium</button>
                </p>
            </div>

            {user.level !== '0' && (
                <div>
                    <p>or</p>
                    <button onClick={() => onUpdate('remove')}>Remove premium status</button>
                </div>
                )}
        </div>
    );
}

const EditEmailPassword = ({user, onUpdate}) => {
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState(user.email);
    const [passwordFormVisible, setPasswordFormVisible] = useState(false);

    const onEmailUpdateSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        adminApi.post('users/update_email/'+user.ID, {email: email}).then((r) => {
            setLoading(false);

            if (r.data.success) {
                onUpdate({email: email});
            }

            alert(r.data.success ? 'Email changed.' : 'Error! Something went wrong. Email has not been changed.');
        });
    }

    const onPasswordUpdateSubmit = (e) => {
        e.preventDefault();

        if (e.target.password.value !== e.target.password2.value || e.target.password.value.length < 6) {
            alert('Error. Password missmatch or too short. Min 6 characters.');
            return;
        }

        setLoading(true);
        adminApi.post('users/update_password/'+user.ID, {password: e.target.password.value}).then((r) => {
            setLoading(false);
            setPasswordFormVisible(false);
            alert(r.data.success ? 'Password changed.' : 'Error! Something went wrong. Password has not been changed.');
        });
    }

    return (
        <div className={Styles.SettingsModal}>
            {isLoading && <div className={Styles.LoaderOpacity}><div className={"loader"}/></div>}
            <h3>Account settings</h3>
            <form onSubmit={onEmailUpdateSubmit} className={Styles.ActionsArea}>
                <input type={"email"} name={'email'} value={email} onChange={(e) => setEmail(e.target.value)}
                       placeholder={"User email"} />
                <button type={"submit"} disabled={user.email === email}>Update email</button>
            </form>

            <p>
                <button className={Styles.ButtonLink} onClick={() => setPasswordFormVisible(!passwordFormVisible)}>
                    Edit password?
                </button>
            </p>

            {passwordFormVisible && (
                <form onSubmit={onPasswordUpdateSubmit} className={Styles.ActionsArea}>
                    <input type={"password"} name={"password"} placeholder={"New password"} />
                    <input type={"password"} name={"password2"} placeholder={"Repeat new password"} />
                    <button type={"submit"}>Set new password</button>
                </form>
            )}
        </div>
    );
}

const UserStatusComponent = ({user, payments}) => {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        if (user.level === '2') {
            let amount = 0;

            if (payments) {
                payments.map(p => {
                    if (p.type === 'payment') {
                        amount += p.amount;
                    }
                });
            }

            setStatus((amount === 0 ? 'Trial' : 'Paid Premium') + ' ['+user.premium_expires+']');
            if (user.diamondExpiresAt && isGreaterThenNow(user.diamondExpiresAt)) {
                setStatus('VIP premium ['+user.premium_expires+']');
            }
        }
        else if (user.sex === 'male' && user.level === '1') setStatus('Free premium ['+user.premium_expires+']');
        else if (user.sex === 'male' && user.level === '0' && user.premium_expires) {
            setStatus('Registered [Premium expired: '+user.premium_expires+']');
        }
        else if (user.sex === 'male' && user.level === '0' && !user.premium_expires) setStatus('Registered');
    }, [user, payments, setStatus]);

    if (status) {
        return <div>{status}</div>
    }

    return null;
}

function isGreaterThenNow(date) {
    let givenDate = new Date(date);
    if (Date.now() > givenDate.getTime()) {
        return false;
    }
    return true;
}

export default Header;
