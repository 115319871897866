import {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";


import {aggDateFromTimestamp, aggregateText} from "../../helpers";
import adminApi from "../../api/admin";


const buildContent = ticket => {
    const appName = (ticket.app === 'tf') ? 'travelfriend.com' : 'travelgirls.com';
    return "\n\nBest regards,\n"+
        "Justina\n"+
        appName+
        "\n\n----------------------------------\n\n"+
        ticket.content;
}


const SupportTicket = () => {
    const {id} = useParams();
    const [isLoaded, setLoaded] = useState(false);
    const [ticket, setTicket] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        adminApi.get('support/get_ticket/'+id).then(r => {
            setLoaded(true);
            setTicket(r.data.ticket);
            setUser(r.data.user);
        });
    }, []);

    const supportTicketAction = (action, id) => {
        adminApi.get('support/support_ticket_action/'+action+'/'+id).then(r => {
            if (r.data.success) {
                alert('Success!');
            }
        })
    }

    const onSendReply = (e) => {
        e.preventDefault();

        const data = {
            recipient: e.target.recipient.value,
            subject: e.target.subject.value,
            content: e.target.content.value,
            isDone: e.target.done.checked
        }

        adminApi.post('support/send/mail/'+id+'/'+ticket.app, data).then(r => {
            alert('Reply sent successfully!');
        });
    }

    const onSetAsDone = () => {
        if (window.confirm("Mark this as done?")) {
            supportTicketAction('done', parseInt(ticket.id))
        }
    }

    const onSetAsDelete = () => {
        if (window.confirm("Are you sure that you want to delete this ticket?")) {
            supportTicketAction('delete', parseInt(ticket.id))
        }
    }

    return (
        <Fragment>
            {!isLoaded && <div className="loader mt-20"/>}
            {isLoaded && (
                <div className="support-ticket">
                    <a href={process.env.REACT_APP_MP+'/support'} className="fr table-links">Back to list</a>
                    <h1>{user ? user.name : ticket.name} ({ticket.country})</h1>
                    Asked: {aggDateFromTimestamp(ticket.createdAt)}<br/>
                    User IP: {ticket.userIP}

                    <h3 className="mt-20">Question</h3>
                    <div className="support-ticket-question" dangerouslySetInnerHTML={{__html: aggregateText(ticket.content)}}/>
                    <div className="fr">
                        {user &&
                            <a href={`${process.env.REACT_APP_MP}/conversations/${user.id}`} className="table-links">
                                Show conversation with this user
                            </a>}
                    </div>

                    <form onSubmit={onSendReply}>
                        <div className="support-ticket-form mt-20">
                            <div className={user ? 'hidden' : ''}>
                                <div className="support-ticket-form-group">
                                    <label>Recipient :</label>
                                    <input name="recipient" defaultValue={user ? user.email : ticket.email} />
                                </div>
                            </div>
                            <div className="support-ticket-form-group">
                                <label>Subject:</label>
                                <input name="subject" defaultValue={ticket.subject} />
                            </div>
                            <textarea name="content" defaultValue={buildContent(ticket)} />
                        </div>

                        <div className="support-ticket-buttons">
                            <div className="fl">
                                <label>Done: </label>
                                <input type="checkbox" name="done" defaultChecked={ticket.done === '1'} />
                                <button type={"submit"} className="green-button">Send Reply</button>
                            </div>

                            <div className="fr">
                                <button type="button" className="green-button" onClick={onSetAsDone}>Set as Done</button>
                                <button type="button" className="green-button" onClick={onSetAsDelete}>Delete</button>
                            </div>
                        </div>
                    </form>
                </div>)
            }
        </Fragment>
    );
}

export default SupportTicket;
