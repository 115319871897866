import { useEffect, useState } from 'react';
import apiAdmin from "../../api/admin";

const Finance = () => {
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const getDimensionsData = (data = {}) => {
        setIsLoading(true);     

        apiAdmin.get('finance/dimensions', {params: data}).then((r) => {
            setList(current => [...current, ...r.data]);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getDimensionsData();
    }, []);

    const onSubmit = (e) => {
        setList([]);
        e.preventDefault();
        const data = {
            period: e.target.period.value
        };
        getDimensionsData(data);
    }

    const loadMoreHandler = () => {
        getDimensionsData({from: list.length});
    }
    
    return (
        <div>
            <h1>Finances</h1>

            <form onSubmit={onSubmit} className="filters">
                <select name="period">
                    <option value="day">Days</option>
                    <option value="week">Week</option>
                    <option value="month">Month</option>
                    <option value="year">Year</option>
                </select>
                <button type={"submit"}>Filter</button>
            </form>

            <table className="mt-10 dimensionsTable">
                <thead>
                    <tr>
                        <th colSpan={6}>Real finances</th>
                        <th colSpan={3}>Fact finances</th>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <th>Cost</th>
                        <th>Payments</th>
                        <th>Income</th>
                        <th>Profit</th>
                        <th>ROI</th>
                        <th>Income</th>
                        <th>Profit</th>
                        <th>ROI</th>
                    </tr>
                </thead>
                <tbody>
                {list && 
                list.map((item, i) => {
                    let profitClass = (item.income - item.cost > 0) ? 'green' : 'red';
                    let profitRealClass = (item.incomeReal - item.cost > 0) ? 'green' : 'red';
                    return (
                        <tr key={i}>
                            <td>{item.dateFormated}</td>
                            <td>{parseFloat(item.cost).toFixed(2)} €</td>
                            <td title={`Purchase: ${item.purchase} Cancels:${item.cancel}`}>{item.purchase} ({item.purchase - item.cancel}) <span  className="dark-grey-text">{item.got_landing}</span></td>
                            <td>{item.incomeReal}.00 €</td>
                            <td className={profitRealClass}>{parseFloat(item.incomeReal - item.cost).toFixed(2)} €</td>
                            <td>{(item.roiReal) ? <span className={profitRealClass}>{item.roiReal}%</span> : '~'}</td>
                            <td>{item.income}.00 €</td>
                            <td className={profitClass}>{parseFloat(item.income - item.cost).toFixed(2)} €</td>
                            <td>{(item.roi) ? <span className={profitClass}>{item.roi}%</span> : '~'}</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            <div className="table-links" onClick={loadMoreHandler}><b>Show more</b></div>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default Finance;
