import thumbFemale from "../assets/thumbs/female.svg";
import thumbMale from "../assets/thumbs/male.svg";

export const textHighlighter = (text, search) => {
    if (typeof search === "object") {
        for (let i = 1; i < search.length; i++) {
            text = text.replace(new RegExp(search[i].word, "gi"), (match) => `<mark>${match}</mark>`);
        }
        return text;
    } else {
        return text.replace(new RegExp(search, "gi"), (match) => `<mark>${match}</mark>`);
    }
};

export const aggPhoto = (photoUrl, type = "", user) => {
    if (!photoUrl) {
        return (user.sex === 'female') ? thumbFemale : thumbMale;
    }
    if (photoUrl === '/static/img/placeholders/no-photo-female-{type}.jpg') {
        return thumbFemale;
    }
    if (photoUrl === '/static/img/placeholders/no-photo-male-{type}.jpg') {
        return thumbMale;
    }
    return (photoUrl) ? photoUrl.replace('{type}', type) : null
};

export const aggDateFromTimestamp = (timestamp) => {
    let date = new Date(timestamp * 1000);
    let day = ('0' + date.getDate()).slice(-2);
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let year = date.getFullYear();

    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);
    let seconds = ('0' + date.getSeconds()).slice(-2);

    return (year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds);
};

export const aggEmoticons = text => {
    let list = {
        ':)': '🙂',
        ';)': '😉',
        '(blush)': '😊',
        ':D': '😀',
        '(cool)': '😎',
        '(laugh)': '😀',
        '(kiss)': '😘',
        '(love)': '😍',
        '(rolleyes)': '🙄',
        ':p': '😛',
        '(angel)': '😇',
        '(amazed)': '😨',
        '(angry)': '😡',
        ':(': '🙁',
        '(dazed)': '🤪',
        '(wave)': '👋',
        '(clap)': '🤗',
        '(chuckle)': '😊',
        '(rofl)': '🤣'
    };
    return text.replaceArray(list);
};

/* aggregateText Start */
export const aggregateText = text => {
    text = escapeExpression(text);
    // Make links
    text = (text || "").replace(
        /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
        function(match, space, url){
            var hyperlink = url;
            if (!hyperlink.match('^https?:')) {
                hyperlink = 'http://' + hyperlink;
            }

            return space + '<a href="'+hyperlink+'" target="_blank" class="message-link">'+url+'</a>';
        }
    );

    // Replace new lines to br
    text = text.replace(/(?:\r\n|\r|\n)/g, '<br />');
    // Aggregate emojis
    text = aggEmoticons(text);

    return text;
};

// Handlebars escapeExpression function and constants
const escape = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;'
};

const badChars = /[&<>"'`=]/g;
const possible = /[&<>"'`=]/;

function escapeChar(chr) {
    return escape[chr];
}
function escapeExpression(string) {
    if (typeof string !== 'string') {
        if (string && string.toHTML) {
            return string.toHTML();
        } else if (string == null) {
            return '';
        } else if (!string) {
            return string + '';
        }
        string = '' + string;
    }

    if (!possible.test(string)) { return string; }
    return string.replace(badChars, escapeChar);
}

/* aggregateText Finish */

export const objectToArray = obj => {
    let emptyArray = [];
    Object.entries(obj).map(([key, value]) => (
        emptyArray[key] = value
    ));
    return emptyArray;
};

//eslint-disable-next-line no-extend-native
String.prototype.replaceArray = function(rr) {
    var replaceString = this;
    for(var key in rr) {
        replaceString = replaceString.split(key).join(rr[key]);
    }
    return replaceString;
};
