import {Fragment, useEffect, useState} from "react";


import adminApi from "../../api/admin";
import FaqForm from "./FaqForm";


const Faq = () => {
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        getListFromApi();
    }, []);

    const getListFromApi = () => {
        setLoading(true);
        setList([]);

        adminApi.get('faq').then(r => {
            setLoading(false);
            setList(r.data);
        });
    }

    const onMove = (id, level, action) => {
        adminApi.post('faq/move/'+action, {id: parseInt(id), level: parseInt(level)}).then(getListFromApi);
    };

    const addFaq = (e) => {
        e.preventDefault();

        const data = {
            intro: e.target.visibleIntro.checked ? 4 : 0,
            male: e.target.visibleMale.checked ? 1 : 0,
            female: e.target.visibleFemale.checked ? 2 : 0,
            question: e.target.question.value,
            answer: e.target.answer.value,
            category: e.target.category.value,
            lang: e.target.language.value,
            keywords: e.target.keywords.value
        };
        adminApi.post('faq/add/'+data.lang, data).then(getListFromApi);
    }

    return (
        <Fragment>
            <h1>FAQ Updates</h1>
            <FaqForm onSubmit={addFaq} />
            <hr/>

            <h1>All FAQ's</h1>
            <table>
                <tbody>
                <ListItems list={list} onMove={onMove} />
                </tbody>
            </table>

            {isLoading && <div className="loader mt-10"/>}
        </Fragment>
    );
}

const ListItems = ({list, onMove}) => {
    return list.map((f, i) => {
        const visibleMale = (f.visible === "1" || f.visible === "3" || f.visible === "5" || f.visible === "0");
        const visibleFemale = (f.visible === "2" || f.visible === "3" || f.visible === "6" || f.visible === "0");
        const visibleIntro = (f.visible === "0" || f.visible === "4" || f.visible === "5" || f.visible === "6");

        return (
            <tr key={i} className="faq-list">
                <td>
                    <b>{f.category}</b><br/>
                    {(visibleMale) ? <span className="male"> M </span> : ''}
                    {(visibleFemale) ? <span className="female"> F </span> : ''}
                    {(visibleIntro) ? <span className="tf"> I </span> : ''}
                </td>
                <td>[{f.lang}] {f.question}</td>
                <td><a href={`${process.env.REACT_APP_MP}/faq/${f.ID}/${f.lang}`} className="table-links">Edit</a></td>
                <td><i className="fa fa-angle-double-up" onClick={() => onMove(f.ID, f.level, 'move_up')} /></td>
                <td><i className="fa fa-angle-double-down" onClick={() => onMove(f.ID, f.level, 'move_down')} /></td>
            </tr>
        );
    });
}

export default Faq;
