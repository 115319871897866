import {Fragment, useState} from "react";
import FsLightbox from 'fslightbox-react';


import { aggPhoto } from "../../../../helpers";
import apiAdmin from "../../../../api/admin";


const getActionColor = (action) => {
    let actionColor = 'red-text';
    if (action === 'upload' || action === 'cancel') actionColor = 'grey-text';
    if (action === 'verified') actionColor = 'green-text';
    return actionColor;
}

const VerificationHistory = ({list, userId, onRemove}) => {
    const [lightboxController, setLightboxController] = useState({toggler: false, slide: 1});
    const imgList = list.map(i => aggPhoto(i.photo));

    const verifyWithOldPhotoHandler = (verificationId) => {
        apiAdmin.get('verification/verify_user/'+userId+'/' + verificationId).then(r => {
            onRemove();
        });
    }
    const openLightbox = (id) => setLightboxController({toggler: !lightboxController.toggler, slide: id+1});

    return (
        <Fragment>
        <table className="pending-verification">
            <tbody>
            {list.map((h, i) => {
                    let actionColor = getActionColor(h.action);
                    return (
                        <tr key={i}>
                            <td/>
                            <td/>
                            <td className="pending-verification-photo" colSpan="3">
                                <img alt="user" src={aggPhoto(h.photo, 'thumb')} onClick={() => openLightbox(i)} />
                            </td>
                            <td className="pending-verification-reason">
                                <b>{h.date}</b>
                                <h3 className={`${actionColor} capitalize block-reason`}>{h.action}</h3>
                                By admin: <a href={`/member/${h.adminId}`} className="table-links">
                                {h.admin}
                            </a><br/>
                                <span
                                    className="table-links"
                                    onClick={() => {
                                        if (window.confirm("Are you sure, that you want to verify with this photo?")) {
                                            verifyWithOldPhotoHandler(h.id)
                                        }
                                    }}
                                >Verify with this photo</span>
                            </td>
                        </tr>
                    )
                })
            }
            </tbody>
        </table>

        <FsLightbox
            toggler={lightboxController.toggler}
            sources={imgList}
            type="image"
            slide={lightboxController.slide}
        />
        </Fragment>
    )
}

export default VerificationHistory;
