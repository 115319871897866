import { aggPhoto } from "../../../../helpers";


const VerificationDuplicates = (props) => {
    const getActionColor = (action) => {
        let actionColor = 'red-text';
        if (action === 'upload' || action === 'canceled') actionColor = 'grey-text';
        if (action === 'verified') actionColor = 'green-text';
        return actionColor;
    }

    return (
        props.list.map((d, i) => {
            let actionColor = getActionColor(d.action);
            return (
                <tr style={{background: '#eee'}} key={i}>
                    <td className="pending-verification-photo">
                        <img
                            alt="user"
                            src={aggPhoto(d.user.photoUrl, 'thumb')}
                        />
                        <br/>
                    </td>
                    <td className="pending-verification-photo">
                        <img
                            alt="verification"
                            src={aggPhoto(d.verification.photo, 'thumb')}
                        />
                    </td>
                    <td>
                        <div>
                            {(d.user.isBlocked) ? <span className="red"><b>Blocked</b></span> : ''}
                            {(d.autoBlock) ? <span><br/>{d.autoBlock}</span> : ''}
                            <br/>
                            {(d.user.block !== null && d.user.block.adminId) ?
                                <span>
                                    By Admin: <a href={`/member/${d.user.block.adminId}`} className="table-links">
                                        {d.user.block.adminName}
                                    </a>
                                </span>
                                : ''}
                        </div>
                        <div className="mt-10">
                            User: <a href={`/member/${d.user.id}`} className="table-links">{d.user.name}</a>
                            {(d.user.app === 'tf') ? <span className="tf"> (TF) </span> : ''}
                        </div>
                        <br/>
                        {(d.hasMaleDuplicates) ? <span className="red"><b>Duplicate with men!</b></span> : ''}
                    </td>
                    <td colSpan="2" />
                    <td className="pending-verification-reason">
                        <span className="green-text block-reason">{d.reason}</span><br/>
                        {(d.user.block !== null) ?
                            <span>
                                <a href={`/member/${d.user.block.adminId}`} className="table-links">
                                    {d.user.block.adminName}
                                </a> - {d.user.block.comment}
                            </span>
                        : ''}
                        <br/>
                        <br/>
                        <span>{d.verification.date}</span><br/>
                        <span className={`${actionColor} block-reason capitalize`}>{d.verification.action}</span><br/>
                            By admin - <a href={`/member/${d.verification.adminId}`} className="table-links">
                            {d.verification.adminName}
                        </a>
                    </td>
                </tr>
            )
        })
    )
}

export default VerificationDuplicates;