import { useState } from "react";
import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";

import apiAdmin from "../../api/admin";

const UserProfileUpdate = () => {
    const {sex} = useParams();
    const nav = useNavigate();
    const [data, setData] = useState(null);
    
    const getUpdatingUser = () => {
        apiAdmin.get('users/get_updating_member/'+sex+'/true').then((r) => {
            if (r.data.userId) {
                nav("/user/"+r.data.userId);
            } else {
                setData('No more updates');
            }
            
        });
    }

    useEffect(() => {
        getUpdatingUser();
    }, []);

    return (
        <>
            {!data  && <div className="loader" />}
            {data && <div>{data}</div>}
        </>
    )

}

export default UserProfileUpdate;