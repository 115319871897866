import { useState } from "react";
import { useEffect } from "react";

import adminApi from "../../api/admin";
import { aggPhoto } from "../../helpers";
import { countryNameByCode } from "../../helpers/lang";
import { aggProfileUrl } from "../../helpers/profile";

const MessagingGirls = () => {
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);

    useEffect(() => {
        getGalleryUsers();
    }, [])

    const getGalleryUsers = (data) => {
        setLoading(true);
        setList([]);

        adminApi.post('users/messaging_girls', data).then(r => {
            setLoading(false);
            setList(Object.values(r.data.members));         
        });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const postData = {
            lowPriority: e.target.lowPriority.checked,
            paid: e.target.paid.checked,
            period: e.target.period.value
        };
        getGalleryUsers(postData);
    }

    return (
        <div className="gallery">
            <h1>Girls starting many conversations</h1>
            <form onSubmit={onSubmit} className="filters">
                <select name="period">
                    <option value="1">This day</option>
                    <option value="7">Last week</option>
                    <option value="31">Last month</option>
                </select>&nbsp;&nbsp;
                <label>Low priority<input type="checkbox" name="lowPriority" /></label>&nbsp;
                <label>Paid<input type="checkbox" name="paid" /></label>
                <button type={"submit"}>Filter</button>
            </form>
            <ul>
                {list &&
                    list.map((user, i) => {
                        return (
                            
                            <li key={user.id}>
                                <a className="photo action-open-profile" href={aggProfileUrl(user.id)}>
                                    <img src={aggPhoto(user.photoUrl, 'thumb')} alt="users face" />
                                    {user.isOnline && <span className="online">On</span>}
                                </a>

                                <div className="details">
                                    <h2>{user.name} <span className="age">{user.age}</span></h2>
                                    <div className="country">{countryNameByCode(user.countryCode)}</div>
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
            
            {isLoading && <div className="loader mt-20"/>}
        </div>
    );
}

export default MessagingGirls;