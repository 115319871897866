import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import adminApi from "../../../api/admin";
import Classes from "./MemberOnline.module.scss";
import Avatar from "../../../common/Avatar/Avatar";

const MemberOnline = () => {
    const [isLoading, setLoading] = useState(true);
    const [list, setList] = useState([]);
    const {countryList} = useSelector(s => s.auth.config);
    const getList = () => {
        adminApi.get('greetings/member_online').then(r => {
            setLoading(false);
            setList(r.data.list);
        });
    }
    useEffect( getList, []);

    return (
        <div>
            <h2>Member online</h2>
            {isLoading && <div className="loader mt-50"/>}
            {list.length > 0 && <List list={list} />}
        </div>
    );
}

const List = ({list}) => (
    <table className={Classes.Table}>
        <thead>
        <tr>
            <th>user</th>
            <th></th>
        </tr>
        </thead>
        <tbody>{list.map((l, i) => <Item item={l} key={i} />)}</tbody>
    </table>
);

const Item = ({item}) => (
    <tr>
        <td><Avatar user={item.user} /></td>
        <td style={{width: '100%', padding: 0}}>
            <table className={Classes.InfoTable}>
                <tbody>
                <tr>
                    <td>
                        <input type={'checkbox'} defaultChecked={item.autoRead} /> Auto read <br />
                        <input type={'checkbox'} defaultChecked={item.autoAccept} /> Auto accept <br />
                        <input type={'checkbox'} defaultChecked={item.goOffline} /> Go offline after message<br />
                        <input type={'text'} defaultValue={item.timeInterval} style={{width: '15px'}} /> Time Interval
                    </td>
                    <td>
                        <input type={'text'} value={item.dateFrom} className={Classes.Date} /> From<br />
                        <input type={'text'} value={item.dateTo} className={Classes.Date} /> To<br />
                        <select className={Classes.Select} defaultValue={item.galleryShow}>
                            <option value={0}>Show for all</option>
                            <option value={1}>Just for registered men</option>
                        </select> Show in Gallery
                    </td>
                </tr>
                <tr className={Classes.InfoLine}>
                    <td colSpan={2}>
                        <b>Type:</b>&nbsp;
                        <select className={Classes.Select} defaultValue={item.visitingMember}>
                            <option value="0">None</option>
                            <option value="1">Visiting profiles</option>
                            <option value="2">Visiting just profile visitors</option>
                        </select>

                        <input type={'text'} defaultValue={item.visitingTimes} style={{width: '15px'}} /> Times to visit
                        <div title={'Intervals between visits'}>
                            <input type={'text'} defaultValue={item.visitingInterval} style={{width: '15px'}} /> Intervals
                        </div>

                        <select defaultValue={item.excludeProfileVisits} title={'Visit profiles'}>
                            <option value="photo">With photo</option>
                            <option value="no_photo">Without photo</option>
                            <option value="all">all</option>
                        </select>
                    </td>
                </tr>
                </tbody>
            </table>
        </td>
    </tr>
);

export default MemberOnline;
