import axios from 'axios';
import {getToken, unsetAuthData} from "../helpers/authentication";
import {API_BASE_PATH, API_BASE_URL} from "../config/config";

const instance = (process.env.NODE_ENV === 'development') ? axios.create({
    baseURL: API_BASE_PATH,
    withCredentials: true,
    mode: 'no-cors'
}) : axios.create({
    baseURL: API_BASE_URL,
    withCredentials: true
    //mode: 'no-cors'
});

instance.interceptors.request.use( (config) => {
    const token = getToken();
    if (token) {
        config.headers.Authorization =  token;
    }

    return config;
});

instance.interceptors.response.use((r) => {
    return r;
}, (error) => {
    const statusCode = error.response.status ? error.response.status : null;
    if (statusCode === 401) {
        unsetAuthData();
        window.location = process.env.REACT_APP_MP+'?expired=true';
    }
    else {
        return Promise.reject(error);
    }
});

export default instance;
