import { aggPhoto } from "../../../../helpers";
import { useState } from "react";
import apiAdmin from "../../../../api/admin";
import VerificationItemPhotos from "./VerificationItemPhotos";
import VerificationItemInfo from "./VerificationItemInfo";
import VerificationItemActions from "./VerificationItemActions";
import FsLightbox from "fslightbox-react";
import VerificationHistory from "./VerificationHistory";
import VerificationDuplicates from "./VerificationDuplicates";

const VerificationItem = (props) => {
    const v = props.user;
    const [showHistory, setShowHistory] = useState(false);
    const [historyList, setHistoryList] = useState(null);
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [duplicatesList, setDuplicatesList] = useState(null);
    const [selectedSelfiePhoto, setSelectedSelfiePhoto] = useState(false);

    const [lightboxController, setLightboxController] = useState({toggler: false, slide: 1});
    const photoList = [aggPhoto(v.user.photoUrl), aggPhoto(v.photo)];
    
    if (v.selfiePhoto) {
        photoList.push(aggPhoto(v.selfiePhoto));
    }

    const openModal = (id) => {
        setLightboxController({toggler: !lightboxController.toggler, slide: id});
    }

    const showVerificationHistory = () => {
        if (!historyList) {
            apiAdmin.get('verification/get_verification_history/' + v.user.id).then((r) => {
                setHistoryList(r.data);
                setShowHistory(true);
            })
        }else {
            setShowHistory(!showHistory);
        }
    }

    const showVerificationDuplicates = () => {
        if (!duplicatesList) {
            apiAdmin.get('verification/get_md5_duplicates/' +v.user.id + '/' + v.md5).then((r) => {
                setDuplicatesList(r.data);
                setShowDuplicates(true);
            })
        }else {
            setShowDuplicates(!showDuplicates);
        }
    }

    const removeItemHanddler = () => {
        props.onRemove(v.user.id);
    }

    const verifyOldPhoto = () => {
        props.onRemove(v.user.id)
    }
   
    return (
        <>
            <tbody className="pending-verification">
                <tr>
                    <VerificationItemPhotos 
                        item={v} 
                        onOpenModal={openModal} 
                        onShowHistory={showVerificationHistory} 
                        onShowDuplicates={showVerificationDuplicates}
                        selfieSelected={selectedSelfiePhoto}
                        onSelfieSelect={() => setSelectedSelfiePhoto(!selectedSelfiePhoto)}
                        onReload={props.onReload}
                    />
                    <VerificationItemInfo item={v} onReload={props.onReload} />
                    <VerificationItemActions item={v} onReload={props.onReload} onRemove={removeItemHanddler} onVerify={props.onCrop} selfieSelected={selectedSelfiePhoto} />
                </tr>
                {showHistory && 
                    <VerificationHistory list={historyList} userId={v.user.id} onRemove={verifyOldPhoto}/>
                }
                {showDuplicates && 
                    <VerificationDuplicates list={duplicatesList} />
                }
                <FsLightbox
                    toggler={lightboxController.toggler}
                    type="image"
                    sources={photoList}
                    slide={lightboxController.slide}
                />
            </tbody>
        </>
    )
}

export default VerificationItem;