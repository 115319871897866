import {createSlice} from "@reduxjs/toolkit";
import adminApi from "../api/admin";

const initialState = {
    isInitiated: false,
    isLoading: true,
    list: [],
    hasMore: false,
    inboxType: 'inbox',
    name: ''
}

const conversationsSlice = createSlice({
    name: 'conversations',
    initialState: initialState,
    reducers: {
        reset(state, action) {
            return {
                ...initialState,
                inboxType: action.payload.inboxType,
                name: action.payload.name
            }
        },
        startFetch(state) {
            state.isLoading = true;
        },
        fetch(state, action) {
            let newList = [...state.list, ...action.payload.list];
            state.isInitiated = true;
            state.isLoading = false;
            state.hasMore = action.payload.hasMore;
            state.list = newList;
        },
        // setAsRead(state, action) {
        //     state.list.map(c => {
        //         if (c.id === action.payload.id) {
        //             c.isUnread = false;
        //         }
        //         return c;
        //     });
        // },
        toggleFavorite(state, action) {
            state.list.map(c => {
                if (c.id === action.payload.userId) {
                    c.isFavorited = action.payload.isFavorited;
                    c.user.isFavorited = action.payload.isFavorited;
                }
                return c;
            });
        },
    }
});

export const resetConversations = (inboxType = null, name = '') => {
    return async (dispatch, getState) => {
        dispatch(conversationsActions.reset({name: name, inboxType: inboxType}));

        const currentState = getState().conversations;
        console.log(currentState);
        const data = await apiGetConversations(aggParams(currentState));
        dispatch(conversationsActions.fetch(data));
    }
}

export const getConversations = () => {
    return async (dispatch, getState) => {
        dispatch(conversationsActions.startFetch());
        const c = getState().conversations;
        const data = await apiGetConversations(aggParams(c));
        dispatch(conversationsActions.fetch(data));
    }
}

const apiGetConversations = async (params) => {
    const searchQueryString = new URLSearchParams(params).toString();
    const response = await adminApi.get('/conversations/supportConversations?'+searchQueryString);
    return response.data;
};

const aggParams = (currentState) => {
    const param = {};

    if (currentState.name) {
        param['name'] = currentState.name;
    }

    if (currentState.inboxType) {
        param['type'] = currentState.inboxType;
    }

    param['from'] = currentState.list.length;
    return param;
}

export const conversationsActions = conversationsSlice.actions;
export default conversationsSlice.reducer;
