import {createSlice} from "@reduxjs/toolkit";
import adminApi from "../api/admin";

const initialState = {
    isChecked: false,
    isLogged: false,
    user: null,
    view: null,
    config: null,
    supportConversations: null
}

const authSlice = createSlice({
    name: 'admin',
    initialState: initialState,
    reducers: {
        set(state, action) {
            state.isChecked = true;
            state.isLogged = true;
            state.user = action.payload.user;
            state.view = action.payload.view;
            state.config = action.payload.config;
            state.supportConversations = action.payload.supportConversations;
        },
        setOnAuthError(state) {
            state.isChecked = true;
            state.isLogged = false;
        }
    }
});

export const getAdmin = () => {
    return async (dispatch) => {
        try {
            const userInfo = await adminApi.get('admin/get/');
            dispatch(authActions.set({
                user: userInfo.data.user,
                view: userInfo.data._view,
                config: userInfo.data._config,
                supportConversations: userInfo.data.supportConversations
            }));
        }
        catch (error) {
            dispatch(authActions.setOnAuthError());
        }
    }
}

export const authActions = authSlice.actions;
export default authSlice.reducer;
