import { useEffect, useState } from 'react';
import apiAdmin from "../../api/admin";

const Suspicious = () => {
    const [list, setList] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [wordValue, setWordValue] = useState("");

    const getList = (loadMore = false) => {
        setIsLoading(true);     

        apiAdmin.get('suspicious').then((r) => {
            setList(r.data);
            setIsLoading(false);
        })
    }

    useEffect(() => {
        getList();
    }, []);

    const onCheckboxChange = (word, event) => {
        let index = list.findIndex(el => el.word === word.word);
        let updatingWord = list[index];
        let place = event.target.name;
        updatingWord[place] = !updatingWord[place];
        // if both false return value to what it was before
        if (!updatingWord['useForFemale'] && !updatingWord['useForMale']) {
            updatingWord[place] = !updatingWord[place];
            alert("One of the genders must be selected!")
        } else {
            apiAdmin.post('suspicious/update_suspicious_word', {data: word}).then((r) => {
                setList([...list, updatingWord]);
            });
        }
    };

    const addSuspiciousWord = () => {
        apiAdmin.post('suspicious/add_suspicious_word', {word: wordValue}).then((r) => {
            getList();
            setWordValue( "");
        });
    }

    const removeWordFromList = (word) => {
        if (window.confirm("Are you sure you want to delete this word?")) {
            apiAdmin.post('suspicious/delete_suspicious_word', {word: word.word}).then((r) => {
                let newList = list.filter(el => {
                    return el.word !== word.word;
                });
                setList(newList);
            });
        }
    }
    
    return (
        <div>
            <h1>Suspicious words</h1>

            <input
                value={wordValue}
                onChange={(e) => {
                    setWordValue(e.target.value)
                }}
            />
            <button className="ml-5" onClick={addSuspiciousWord}>Add</button>

            <table className="mt-10">
                <thead>
                    <tr>
                        <th style={{width: '15%'}}>Word</th>
                        <th>Male</th>
                        <th>Female</th>
                        <th>About Me</th>
                        <th>Trips</th>
                        <th>Messages</th>
                        <th>Auto Block</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {list && 
                list.map((word, i) => {
                    return (
                        <tr key={i}>
                            <td>{word.word}</td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="useForMale"
                                    onChange={(e) => onCheckboxChange(word, e)}
                                    checked={word.useForMale}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="useForFemale"
                                    onChange={(e) => onCheckboxChange(word, e)}
                                    checked={word.useForFemale}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="useForAboutMe"
                                    onChange={(e) => onCheckboxChange(word, e)}
                                    checked={word.useForAboutMe}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="useForTrips"
                                    onChange={(e) => onCheckboxChange(word, e)}
                                    checked={word.useForTrips}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="useForMessages"
                                    onChange={(e) => onCheckboxChange(word, e)}
                                    checked={word.useForMessages}
                                />
                            </td>
                            <td>
                                <input
                                    type="checkbox"
                                    name="useForMessagesAutoBlock"
                                    onChange={(e) => onCheckboxChange(word, e)}
                                    checked={word.useForMessagesAutoBlock}
                                />
                            </td>
                            <td>
                                <span className="red-text" onClick={() => { removeWordFromList(word)}}>Delete</span>
                            </td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
            {isLoading && <div className="loader" />}
        </div>
    )
}

export default Suspicious;
